import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Vertical = ({ p, m, px, py, mx, my, children }) => (
    <Box
        display="flex"
        height="100%"
        width="100%"
        flexDirection="column"
        p={p}
        px={px}
        py={py}
        m={m}
        mx={mx}
        my={my}
    >
        {children}
    </Box>
);

Vertical.propTypes = {
    children: PropTypes.node,
    p: PropTypes.node,
    px: PropTypes.node,
    py: PropTypes.node,
    m: PropTypes.node,
    mx: PropTypes.node,
    my: PropTypes.node
};

export default Vertical;
