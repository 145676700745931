import { QueryClient, QueryClientProvider, useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getUserId } from './session';

let _queryClient = null;
export const getQueryClient = () => {
    if (!_queryClient) {
        _queryClient = new QueryClient();
    }
    return _queryClient;
};

const queryClient = getQueryClient();
export const ApiProvider = (props) => {
    return QueryClientProvider({ ...props, client: queryClient });
};

const invalidateKey = (key) => {
    const userId = getUserId();
    queryClient.invalidateQueries([userId, ...key]);
};

export { useQuery, useMutation, useInfiniteQuery, ReactQueryDevtools, invalidateKey };
