import { useState } from 'react';

const useControlledArray = (binding = []) => {
    const [lastBinding, setLastBinding] = useState([]);
    const [changed, setChanged] = useState(false);
    const [value, setValue] = useState(binding);

    if (binding !== lastBinding) {
        setLastBinding(binding);
        setValue(binding);
    }

    const reset = () => {
        setValue(lastBinding);
        setLastBinding(binding);
        setChanged(false);
    };

    const onChange = value => {
        setValue(value);
        setChanged(JSON.stringify(value.sort()) !== JSON.stringify(lastBinding.sort));
    };
    return { value, setValue, reset, changed, onChange };
};

export default useControlledArray;
