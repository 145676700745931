import PropTypes from 'prop-types';

import { lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography, Tooltip, Toolbar, IconButton, Checkbox, FormControlLabel } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Archive as ArchiveIcon, Replay as UnarchiveIcon, Delete as DeleteIcon, DeleteForever as DeleteForeverIcon, Cancel as CancelIcon } from '@material-ui/icons';

import ColumnSelect from './ColumnSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.84),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    label: {
        width: '100%'
    }
}));

const Tools = ({ selection, title, onDelete, confirmDelete, onCancelDelete, actions, onAdd, onEdit, onArchive, onUnarchive, archived, setArchived, columns, onHideColumn }) => {
    const classes = useStyles();

    const selected = selection.length > 0;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: selected,
            })}
        >
            {selected
                ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {selection.length} selected
                    </Typography>
                )
                : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>
                )
            }
            {!selected && !!onArchive
                ? (
                    <FormControlLabel className={classes.label}
                        control={
                            <Checkbox
                                checked={archived}
                                onChange={() => {
                                    setArchived(!archived);
                                }}
                                name="showArchived"
                                color="primary"
                            />
                        }
                        label="Show Archived"
                        labelPlacement="start"
                    />
                )
                : ''
            }
            {selected
                ? (
                    <>
                        {actions.map((action, i) => (
                            <Tooltip key={i} title={action.title}>
                                <IconButton aria-label={action.title} onClick={() => action.action(selection)}>
                                    {action.icon}
                                </IconButton>
                            </Tooltip>
                        ))}
                        {onDelete &&
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" onClick={onDelete}>
                                    {
                                        confirmDelete
                                            ? <DeleteForeverIcon />
                                            : <DeleteIcon />
                                    }
                                </IconButton>
                            </Tooltip>
                        }
                        {onDelete && onCancelDelete && confirmDelete &&
                            <Tooltip title="Cancel Delete">
                                <IconButton aria-label="delete" onClick={onCancelDelete}>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {onArchive &&
                            <Tooltip title="Archive">
                                <IconButton aria-label="archive" onClick={onArchive}>
                                    <ArchiveIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {onUnarchive &&
                            <Tooltip title="Unarchive">
                                <IconButton aria-label="archive" onClick={onUnarchive}>
                                    <UnarchiveIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {onEdit &&
                            <Tooltip title="Edit">
                                <IconButton aria-label="delete" onClick={onEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </>)
                : (
                    onAdd &&
                    <Tooltip title="Add" >
                        <IconButton aria-label="add contact" onClick={onAdd}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                )
            }
            {onHideColumn && <ColumnSelect label="Fields" columns={columns} onHideColumn={onHideColumn} />}
        </Toolbar >
    );
};

Tools.propTypes = {
    // numSelected: PropTypes.number.isRequired,
    title: PropTypes.string,
    archived: PropTypes.bool,
    setArchived: PropTypes.func,
    actions: PropTypes.array,
    onDelete: PropTypes.func,
    onCancelDelete: PropTypes.func,
    confirmDelete: PropTypes.bool,
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onArchive: PropTypes.func,
    onUnarchive: PropTypes.func,
    onShowArchivedChanged: PropTypes.func,
    columns: PropTypes.array,
    selection: PropTypes.array,
    onHideColumn: PropTypes.func
};

Tools.defaultProps = {
    actions: []
};

export default Tools;
