import PropTypes from 'prop-types';

import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { useCheckbox } from '@domatic/hooks';

const LabelSwitch = ({ checked, label, onChange, disabled }) => {

    const state = useCheckbox(checked, '');

    return (
        <Box mx={2} flexShrink={0}>
            <FormControlLabel
                aria-label={label}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}

                control={<Switch
                    disabled={disabled}
                    checked={state.checked}
                    onChange={onChange}
                    onFocus={(event) => event.stopPropagation()}
                />}
                label={label}
            />
        </Box>
    );
};

LabelSwitch.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default LabelSwitch;
