import { useState } from 'react';
import { useHistory, } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useRefresh, useSession } from '@domatic/query';
import moment from 'moment';

export default function SessionAlert() {
    const history = useHistory();

    const [open, setOpen] = useState(false);

    const session = useSession();
    const { mutate: refresh } = useRefresh();

    if (session?.warning && !open) {
        setOpen(true);
    }

    if (!session?.warning && open) {
        setOpen(false);
    }

    const handleLogout = () => {
        history.replace('/logout');
        setOpen(false);
    };

    const handleRefresh = () => {
        refresh();
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Your Session is About to Expire</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your session expires {moment(session?.expiresAt).fromNow()}.  <br />
                    What say you?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLogout} color="primary">
                    Log Out
                </Button>
                <Button onClick={handleRefresh} color="primary" autoFocus>
                    Stay Logged In
                </Button>
            </DialogActions>
        </Dialog>
    );
}
