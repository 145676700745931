import { imageToZ64 } from 'zpl-image';
import pako from 'pako';
window.pako = pako;

const _config = (width, height, dpi) => {
    return `^XA^PW${width * dpi}^LL${height * dpi}^XZ`;
};

export const getZPL = (canvas, { width, height, dpi, quantity }) => {
    const config = _config(width, height, dpi ?? 300);
    const res = imageToZ64(canvas, { notrim: true });
    return `${config}^XA^GFA,${res.length},${res.length},${res.rowlen},${res.z64}^PQ${quantity}^XZ`;
};
