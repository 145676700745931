import io from 'socket.io-client';
import { useQuery, getQueryClient } from './queryClient';
import { updateObjectCache, updateArrayCache, updateInfiniteCache, updateMapCache } from './utils';

import axios from 'axios';

import { sessionEvents } from './session';

let _started = false;

let _socket;
let _socketId = null;

export const useSocketId = () => {
    const { data: socketId } = useQuery(['socketId'], async () => _socketId);
    return socketId;
};

const _setSocketId = (id) => {
    _socketId = id;
    const queryClient = getQueryClient();
    queryClient.invalidateQueries(['socketId']);
    queryClient.invalidateQueries(['info']);
};

export const start = () => {
    if (_started) {
        console.log('notify already started');
        return;
    }
    _started = true;
    const url = axios.defaults.baseURL; // FIXME This is apparently null in the browser now
    console.log('starting notify', { url });

    _socket = io(url, { path: '/f-socket.io', transports: ['websocket'] });

    sessionEvents.on('open', () => {
        _socket.connect();
    });

    sessionEvents.on('close', () => {
        _socket.disconnect();
    });

    _socket.on('connect', () => {
        console.log('notification socket connected', _socket.id);
        _setSocketId(_socket.id);
    });

    _socket.on('disconnect', () => {
        console.log('notification socket disconnected');
        _setSocketId(null);
    });

    _socket.on('action', ({ type, key, command, item }) => {
        switch (type) {
            case 'object':
                updateObjectCache(command, key, item);
                break;
            case 'array':
                updateArrayCache(command, key, item);
                break;
            case 'infinite':
                updateInfiniteCache(command, key, item);
                break;
            case 'map':
                updateMapCache(command, key, item);
                break;
            default:
                console.warn('unknown action:', type, key, command, item);
        }
    });
};

export const send = (event, msg) => {
    _socket.emit(event, msg);
};

export default {
    start,
    isStarted: () => _started,
    send
};
