// eslint-disable-next-line no-unused-vars
import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Typography, Box } from '@material-ui/core';
import Loading from '../components/Loading';

import { Field, ErrorList } from '../components';
import { useInput } from '@domatic/hooks';
import { useAddOrganization, useSetCurrentOrganizationId } from '@domatic/query';

const AddOrganization = ({ onClose }) => {

    const name = useInput('', 'notEmpty', 'Name');

    const { mutate: addOrganization, isLoading: adding, error } = useAddOrganization();
    const { mutate: setCurrentOrganizationId } = useSetCurrentOrganizationId();

    const addError = (error?.response?.status === 303) ? new Error('Error: Organization already exists.') : error;

    const doAdd = async () => {
        const organization = await addOrganization({ name: name.value });
        console.log('new org:', organization?.id);
        setCurrentOrganizationId(organization?.id);
        onClose();
    };

    const formValid = name.valid;

    return (
        <Loading active={adding} spinner text="Loading...">
            <Box p={2}>
                <Typography>Add a New Organization</Typography><br />
                <Field field={name} autoFocus required fullWidth />
                <Button onClick={doAdd} color="primary" disabled={!formValid}>Add</Button>
                &nbsp;
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <br />
                <br />
                <ErrorList errors={[addError]} />
            </Box>
        </Loading>
    );
};

AddOrganization.propTypes = {
    onClose: PropTypes.func
};

AddOrganization.defaultProps = {};

export default AddOrganization;
