import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Loading from '../components/Loading';
import Grid from '../components/Grid';
import { useCircuits } from '@domatic/query';

import { Box } from '@material-ui/core';
import moment from 'moment';

const Circuits = ({ hub }) => {

    const { data: rows } = useCircuits(hub.id);

    // const booleanFormatter = params => params.value ? '✓' : '';
    const dateFormatter = useCallback(params => moment(params.value).calendar(), []);
    const stateFormatter = useCallback(params => `${params.on ? 'on' : 'off'}(${params.onLevel} current:${params.level})`, []);
    const columns = useMemo(() => ([
        { field: 'created', type: 'date', headerName: 'Timestamp', flex: 2, valueFormatter: dateFormatter },
        { field: 'updated', headerName: 'Modified', flex: 2, valueFormatter: dateFormatter },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 3 },
        { field: 'mode', headerName: 'Mode', flex: 1 },
        { field: 'timeout', headerName: 'Timeout', flex: 1 },
        { field: 'offTimeout', headerName: 'Off Timeout', flex: 1 },
        { field: 'state', headerName: 'State', flex: 0.5, valueFormatter: stateFormatter }
    ]), [dateFormatter, stateFormatter]);

    const initialSortModel = [
        { field: 'name', sort: 'asc' }
    ];

    const active = false;
    const text = 'Loading...';

    return (
        <Box p={2} height="100%">
            <Loading active={active} spinner text={text}>
                <Grid
                    title={'Circuits'}
                    rows={rows}
                    columns={columns}
                    initialSortModel={initialSortModel}
                >
                </Grid>
            </Loading>
        </Box>
    );
};

Circuits.propTypes = {
    building: PropTypes.object,
    hub: PropTypes.object
};

Circuits.defaultProps = {};

export default Circuits;
