import PropTypes from 'prop-types';
import { useBuildingHubs } from '@domatic/query';
import StatusCard from '../../components/StatusCard';

const KnownHubs = ({ building }) => {
    const { data: hubs } = useBuildingHubs(building?.id);
    const info = {
        title: 'Known Hubs',
        severity: 'info',
        message: [`${hubs.length} ${hubs.length === 1 ? 'hub' : 'hubs'} found.`]
    };

    return (
        <StatusCard info={info} />
    );
};

KnownHubs.propTypes = {
    building: PropTypes.object
};

export default KnownHubs;
