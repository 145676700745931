import PropTypes from 'prop-types';
import { useBuildingHubs } from '@domatic/query';
import StatusCard from '../../components/StatusCard';

const OfflineHubs = ({ building }) => {
    const { data: hubs } = useBuildingHubs(building?.id);
    const offlineHubs = hubs?.filter(hub => !hub.active);

    const info = {
        title: 'Offline Hubs',
        severity: offlineHubs.length > 0 ? 'warning' : 'info',
        message: [`${offlineHubs.length} ${offlineHubs.length === 1 ? 'hub is' : 'hubs are'} offline.`]
    };

    return (
        <StatusCard info={info} />
    );
};

OfflineHubs.propTypes = {
    building: PropTypes.object
};

export default OfflineHubs;
