import PropTypes from 'prop-types';

import { useInput } from '@domatic/hooks';
import { useHubSettings, useUpdateSetting } from '@domatic/query';

import CustomSelect from '../components/CustomSelect';
import { Box, Button, Typography } from '@material-ui/core';

const HubLogLevels = ({ hub }) => {
    const { data: settings, isLoading: loading } = useHubSettings(hub.id);
    const { mutate: updateSetting, isLoading: saving } = useUpdateSetting(hub.id);

    const levels = ['fatal', 'error', 'warn', 'info', 'verbose'];
    const uploadLevel = useInput(settings?.Logging?.upload, '', 'Upload', hub.id);
    const recordLevel = useInput(settings?.Logging?.record, '', 'Record', hub.id);

    const modules = settings?.Logging?.modules;

    const doSave = () => {
        updateSetting({
            type: 'Logging',
            upload: uploadLevel.value,
            record: recordLevel.value
        });
    };

    return (
        <Box p={2}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography>Log Levels: </Typography>
                &nbsp;&nbsp;

                <CustomSelect field={uploadLevel} data={levels.map(name => ({
                    id: name,
                    name: name
                }))} required />
                &nbsp;&nbsp;

                <CustomSelect field={recordLevel} data={[...levels, 'debug'].map(name => ({
                    id: name,
                    name: name
                }))} required fullWidth />
                &nbsp;&nbsp;

                <Button onClick={doSave} color='primary' disabled={loading || saving}>Apply</Button>
            </Box >
            <br />
            <Typography>Modules: {JSON.stringify(modules, null, 4)}</Typography>
        </Box>
    );
};

HubLogLevels.propTypes = {
    hub: PropTypes.object
};

export default HubLogLevels;
