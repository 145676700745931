import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Box, Typography, IconButton } from '@material-ui/core';
import Access from './Access';
import EmergencyState from './EmergencyState';
import { PortControlSummary } from './PortControl';
import moment from 'moment';
import { useHubSettings } from '@domatic/query';
import ZeusState from './ZeusState';
import ZeusStation from './ZeusStation';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    info: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    }
}));

const lastSeen = hub => `${hub.active ? 'Connected since' : 'Last seen'} ${moment(hub.timestamp).calendar(false)}`;

const Summary = ({ hub, onClick }) => {

    const { data: settings } = useHubSettings(hub?.id);

    const classes = useStyles();

    return (
        <Box flexGrow={1} display="flex" flexDirection="row" alignItems="center">
            {hub.active ? (<CheckCircleIcon color="primary" />) : (<ErrorIcon />)}
            <Box mx={2} flexShrink={0} onClick={onClick}>
                <Typography className={classes.heading}>{hub.name} ({hub?.hostname})</Typography>
                <Typography className={classes.info}>{lastSeen(hub)}</Typography>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">
                <Access hub={hub} />
                <ZeusState state={settings?.ZeusControl?.running} />
                <ZeusStation station={hub?.station} />
                <EmergencyState hub={hub} statusOnly />
                <PortControlSummary hub={hub} />
                {onClick &&
                    <IconButton onClick={onClick} >
                        <ChevronRightIcon />
                    </IconButton>
                }
            </Box>
        </Box>
    );
};

Summary.propTypes = {
    hub: PropTypes.object,
    onClick: PropTypes.func
};

Summary.defaultProps = {};

export default Summary;
