import PropTypes from 'prop-types';
import TabNav from '../components/TabNav';

import OrganizationDetail from './OrganizationDetail';
import OrganizationMembers from './OrganizationMembers';
import OrganizationInvites from './OrganizationInvites';
import Buildings from '../building/Buildings';

const Organization = ({ organization, onClose }) => {
    return (
        <TabNav tabs={[
            { label: 'Details', route: 'detail', component: <OrganizationDetail organization={organization} onClose={onClose} /> },
            { label: 'Members', route: 'members', component: <OrganizationMembers organization={organization} /> },
            { label: 'Invitations', route: 'invites', component: <OrganizationInvites organization={organization} /> },
            { label: 'Buildings', route: 'buildings', component: <Buildings /> }
        ]} />
    );
};

Organization.propTypes = {
    organization: PropTypes.object,
    onClose: PropTypes.func
};

export default Organization;
