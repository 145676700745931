import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';

// import { XGrid } from '@material-ui/x-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@material-ui/core';

import Tools from './Tools';

const Grid = ({
    columns,
    rows,
    sortingMode,
    // eslint-disable-next-line no-unused-vars
    rowCount,
    getRowClassName,
    initialSortModel,
    onSortModelChange,
    archived,
    setArchived,
    title,
    fetchMoreRows,
    canFetchMore,
    multiple,
    actions,
    onArchive,
    onUnarchive,
    onDelete,
    onEdit,
    onAdd,
    onRowClick,
    hideColumnControl,
    onHideColumn,
    disableColumnMenu,
    disableSelectionOnClick,
    checkboxSelection
}) => {

    const [selection, setSelection] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [sortModel, setSortModel] = useState(initialSortModel ?? []);

    const _onSortModelChange = useCallback(model => {
        if (deepEqual(model, sortModel)) return;
        setSortModel(model);
        onSortModelChange?.(model);
    }, [onSortModelChange, sortModel]);

    const onSelectionModelChange = model => {
        // console.log('onSelectionModelChange:', model);
        setConfirmDelete(false);
        if (!multiple) {
            const newRows = model.filter(row => selection.indexOf(row) < 0);
            setSelection(newRows);
        }
        else {
            setSelection(model);
        }
    };

    const handleArchive = onArchive ? () => {
        onArchive(selection);
        setSelection([]);
    } : null;

    const handleUnarchive = onUnarchive ? () => {
        onUnarchive(selection);
        setSelection([]);
    } : null;

    const handleDelete = onDelete ? () => {
        if (!confirmDelete) {
            setConfirmDelete(true);
        }
        else {
            onDelete(selection);
            setSelection([]);
            setConfirmDelete(false);
        }
    } : null;

    const handleCancelDelete = () => {
        setConfirmDelete(false);
    };

    const handleEdit = onEdit ? () => {
        onEdit(selection);
    } : null;

    const handleAdd = onAdd ? () => {
        onAdd(selection);
    } : null;

    const handleRowsScrollEnd = () => {
        if (canFetchMore) {
            fetchMoreRows();
        }
    };

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Tools
                title={title}
                selection={selection}
                onSelectionChange={onSelectionModelChange}
                actions={actions}
                onDelete={handleDelete}
                onCancelDelete={handleCancelDelete}
                confirmDelete={confirmDelete}
                onAdd={handleAdd}
                onArchive={handleArchive}
                onUnarchive={handleUnarchive}
                archived={archived}
                setArchived={setArchived}
                onEdit={handleEdit}
                hideColumnControl={hideColumnControl}
                onHideColumn={onHideColumn}
                columns={columns} />
            <DataGridPro
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                sortingMode={sortingMode ?? 'client'}
                sortModel={sortModel}
                onSortModelChange={_onSortModelChange}
                getRowClassName={getRowClassName}
                disableColumnMenu={disableColumnMenu}
                disableColumnSelector
                hideFooterPagination
                disableSelectionOnClick={disableSelectionOnClick}
                checkboxSelection={checkboxSelection}
                disableMultipleSelection={!multiple}
                onRowsScrollEnd={handleRowsScrollEnd}
                onSelectionModelChange={onSelectionModelChange}
                onRowClick={onRowClick}
                selectionModel={selection}
                canFetchMore={canFetchMore}
                components={{
                }}
                isCellEditable={() => false}
            />
        </Box >
    );
};

Grid.propTypes = {
    rows: PropTypes.array,
    columns: PropTypes.array,
    rowCount: PropTypes.number,
    getRowClassName: PropTypes.func,
    sortingMode: PropTypes.string,
    initialSortModel: PropTypes.array,
    onSortModelChange: PropTypes.func,
    title: PropTypes.string,
    fetchMoreRows: PropTypes.func,
    canFetchMore: PropTypes.bool,
    archived: PropTypes.bool,
    setArchived: PropTypes.func,
    multiple: PropTypes.bool,
    actions: PropTypes.array,
    onDelete: PropTypes.func,
    onArchive: PropTypes.func,
    onUnarchive: PropTypes.func,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    onRowClick: PropTypes.func,
    hideColumnControl: PropTypes.bool,
    onHideColumn: PropTypes.func,
    disableColumnMenu: PropTypes.bool,
    disableSelectionOnClick: PropTypes.bool,
    checkboxSelection: PropTypes.bool
};

export default Grid;
