import { Box } from '@material-ui/core';
import { useCurrentBuilding } from '@domatic/query';

import KnownHubs from './KnownHubs';
import OfflineHubs from './OfflineHubs';
import FaultCount from './FaultCount';
// import HubSlots from './HubSlots';
// import FilledHubSlots from './FilledHubSlots';
// import FilledDeviceSlots from './FilledDeviceSlots';
// import CircuitSlots from './CircuitSlots';

const Dashboard = () => {

    const selectedBuilding = useCurrentBuilding();

    return (
        <Box p={2} overflow="auto">
            {/* <CustomSelect field={selection} data={buildings} required fullWidth /> */}

            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start">
                <KnownHubs building={selectedBuilding} />
                <OfflineHubs building={selectedBuilding} />
                <FaultCount building={selectedBuilding} />
                {/* <HubSlots building={selectedBuilding} />
                <FilledHubSlots building={selectedBuilding} />
                <FilledDeviceSlots building={selectedBuilding} />
                <CircuitSlots building={selectedBuilding} /> */}
            </Box>
        </Box>
    );
};

export default Dashboard;
