import { useState } from 'react';
// import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import Loading from '../components/Loading';

import Grid from '../components/Grid';
import { useUsers, useUpdateUser } from '@domatic/query';

import UserDetail from './UserDetail';

import { Box } from '@material-ui/core';
import moment from 'moment';

const Users = () => {
    const { path, url } = useRouteMatch();
    const history = useHistory();

    const [selected, setSelected] = useState({});
    const initialSortModel = [
        { field: 'name', sort: 'asc' }
    ];
    const [sortModel, setSortModel] = useState(initialSortModel);

    const onSortModelChange = model => {
        setSortModel(model);
    };

    const { data: rows, isFetching: loading, hasNextPage, fetchNextPage } = useUsers({ sortModel });
    const { mutate: updateUser, isLoading: updating } = useUpdateUser();

    const dateFormatter = params => moment(params.value).calendar();
    const booleanFormatter = params => params.value ? '✓' : '';

    const [columns, setColumns] = useState([
        { field: 'created', headerName: 'Created', flex: 1, valueFormatter: dateFormatter },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'username', headerName: 'User Name', flex: 1 },
        { field: 'enabled', headerName: 'Enabled', width: 130, valueFormatter: booleanFormatter },
        { field: 'validated', headerName: 'Validated', width: 130, valueFormatter: booleanFormatter },
        { field: 'isAdmin', headerName: 'Admin', width: 130, valueFormatter: booleanFormatter },
        { field: 'roles', headerName: 'Roles', flex: 1 }
    ]);

    const onHideColumn = (field, val) => {
        setColumns(columns.map(e => {
            if (e.field === field) {
                e.hide = val;
            }
            return e;
        }));
    };

    const onEdit = toEdit => {
        const selectedContact = rows.find(contact => contact.id === toEdit[0]);
        console.log('editing:', toEdit, selectedContact, rows);
        setSelected(selectedContact);
        history.replace(`${url}/${selectedContact.id}/edit`);
    };

    const onClose = () => {
        history.replace(`${url}`);
    };

    const handleUpdate = toUpdate => {
        updateUser(toUpdate);
        onClose();
    };

    const active = loading || updating;
    const text = updating ? 'Updating...' : 'Loading...';

    return (
        <Box height="100%">
            <Loading active={active} spinner text={text}>
                <Switch>
                    <Route exact path={`${path}`}>
                        <Grid
                            title={'Users'}
                            rows={rows}
                            columns={columns}
                            sortingMode={'server'}
                            initialSortModel={initialSortModel}
                            onSortModelChange={onSortModelChange}
                            canFetchMore={hasNextPage}
                            fetchMoreRows={() => fetchNextPage()}
                            checkBoxSelection={true}
                            multiple={false}
                            // onDelete={onDelete}
                            // onAdd={onAdd}
                            onEdit={onEdit}
                            onHideColumn={onHideColumn}
                        >
                        </Grid>
                    </Route>
                    <Route path={`${path}/${selected?.id}/edit`}>
                        <UserDetail title="Edit User" user={selected} onCancel={onClose} onSubmit={handleUpdate} />
                    </Route>
                </Switch>
            </Loading>
        </Box>
    );
};

Users.propTypes = {
};

Users.defaultProps = {};

export default Users;
