// eslint-disable-next-line no-unused-vars
import { Redirect } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';

import { useAuthenticated } from '@domatic/query';
import Image from '../img/domatic-logo.png';

const styles = {
    paperContainer: {
        height: 600,
        width: 1000,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${Image})`,
        backgroundColor: '#fafafa'
    },
    boxContainer: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
};

const Public = () => {

    const authenticated = useAuthenticated();

    if (authenticated) {
        return <Redirect to='/home' />;
    }

    return (
        <Box style={styles.boxContainer}>
            <Paper elevation={0} style={styles.paperContainer}>
            </Paper>
        </Box>
    );
};

export default Public;
