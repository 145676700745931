// eslint-disable-next-line no-unused-vars
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Avatar, Typography } from '@material-ui/core';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import JsGravatar from 'js-gravatar';
import Loading from '../components/Loading';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorList } from '../components';

import { useMembers, useDeleteMember } from '@domatic/query';

const OrganizationMembers = ({ organization }) => {

    const { data: members, isLoading: loading, error: loadError } = useMembers(organization?.id);

    const compare = (a, b) => {
        if (organization?.owner === a.id) return -1;
        if (organization?.owner === b.id) return 1;
        if (a?.name === b?.name) return 0;
        if (a?.name < b?.name) return -1;
        return 1;
    };

    const sortedMembers = members?.sort(compare);

    const { mutate: deleteMember, isLoading: deleting, error: deleteError } = useDeleteMember(organization?.id);

    const deleteMemberClick = id => {
        console.log('deleting member', id);
        return deleteMember(id);
    };
    return (
        <Loading active={loading} spinner text="Loading...">
            <Loading active={deleting} spinner text="Deleting...">
                <Table>
                    <TableBody>
                        {sortedMembers?.map(({ id, name, email }, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <Avatar
                                        style={{
                                            width: 40,
                                            height: 40,
                                            transition: '0.3s',
                                        }}
                                        src={JsGravatar({ email: email, defaultImage: 'identicon' })}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {name}
                                </TableCell>
                                <TableCell>{email}</TableCell>
                                <TableCell>
                                    {organization?.owner === id
                                        ? (<Typography>Owner</Typography>)
                                        : (
                                            <IconButton
                                                onClick={() => deleteMemberClick(id)}
                                                aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        )
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <br />
                <ErrorList errors={[deleteError, loadError]} />
            </Loading >
        </Loading >
    );
};

OrganizationMembers.propTypes = {
    organization: PropTypes.object
};

OrganizationMembers.defaultProps = {};

export default OrganizationMembers;
