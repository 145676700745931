import { useAdmin, useOrganizations, useCurrentOrganization, useSetCurrentOrganizationId } from '@domatic/query';
import { useHistory } from 'react-router-dom';

import { Menu, Button, MenuItem, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        display: 'flex',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    // position: 'fixed',
    appBar: {
        position: 'static'
    }
}));

const OrganizationMenu = () => {
    const classes = useStyles();
    const history = useHistory();
    const isAdmin = useAdmin();

    const { data: organizations } = useOrganizations();
    const currentOrganization = useCurrentOrganization();
    const { mutate: setCurrentOrganizationId } = useSetCurrentOrganizationId();

    return (
        <>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <>
                        <Button
                            variant="text"
                            color="inherit"
                            size="medium"
                            startIcon={<PeopleIcon />}
                            {...bindTrigger(popupState)}>
                            <Typography noWrap className={classes.title}>{currentOrganization?.name}</Typography>
                        </Button>
                        <Menu
                            {...bindMenu(popupState)}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        >
                            {organizations?.map(
                                building => (
                                    <MenuItem
                                        key={building.id}
                                        selected={building.id === (currentOrganization?.id)}
                                        onClick={() => {
                                            popupState.close();
                                            setCurrentOrganizationId(building.id);
                                        }}
                                    >
                                        {building.name}
                                    </MenuItem>)
                            )}

                            <Divider />
                            <MenuItem
                                key="logout"
                                onClick={() => {
                                    popupState.close();
                                    history.push('/logout');
                                }}
                            >
                                <LogoutIcon /> &nbsp;Logout
                            </MenuItem>

                            {isAdmin && <Divider />}
                            {isAdmin && <MenuItem
                                key="add"
                                onClick={() => { popupState.close(); history.push('/home/newOrganization'); }}
                            >
                                <AddIcon /> &nbsp;New Organization
                            </MenuItem>}
                        </Menu>
                    </>
                )}
            </PopupState >
        </>
    );
};

OrganizationMenu.propTypes = {
};

export default OrganizationMenu;
