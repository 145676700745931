import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useHub } from '@domatic/query';

const HubOffline = ({ fault }) => {

    const hubId = fault?.hubId;
    const { data: hub } = useHub(hubId);

    return (
        <>
            <Typography>Name: {hub?.name}</Typography>
            <Typography>ID: {hubId ?? 'Unknown'}</Typography>
            <Typography>Hostname: {hub?.hostname}</Typography>
            <Typography>IP: {hub?.ip}</Typography>
            <Typography>MAC: {hub?.mac}</Typography>
            <Typography>TemplateId: {hub?.templateId}</Typography>
            <Typography>TemplateComment: {hub?.templateComment}</Typography>
            <Typography>Status: {hub?.activeStatus}</Typography>
            <Typography>Branch: {hub?.branch}</Typography>
            <Typography>Version: {hub?.version}</Typography>
            <Typography>Group: {hub?.group}</Typography>
        </>
    );
};

HubOffline.propTypes = {
    fault: PropTypes.object
};

HubOffline.defaultProps = {};

export default HubOffline;
