import { useState, useMemo, useCallback } from 'react';

import Grid from '../../components/Grid';
import { Horizontal, Vertical } from '../../components/Layout';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import WaterMeterDetail from './WaterMeterDetail';

import { useCurrentBuilding, useWaterMeters } from '@domatic/query';

const dateFormatter = params => new Date(params.value).toLocaleString('en-us', { dateStyle: 'short', timeStyle: 'short' });
const LPS2GPH = 951.019;
const roundRate = rate => rate === -1 ? -1 : Math.round(rate * LPS2GPH * 100) / 100;

const WaterMeters = () => {

    const building = useCurrentBuilding();
    const { data: meters } = useWaterMeters(building?.id);

    const rows = useMemo(() => meters.map((meter, index) => ({ ...meter, id: index, rate: roundRate(meter.rate) })), [meters]);

    const [hidden, setHidden] = useState([]);

    const columns = [
        { field: 'name', headerName: 'Hub', width: 200 },
        { field: 'medium', headerName: 'Medium', width: 130 },
        { field: 'volume', headerName: 'Volume (G)', width: 160 },
        { field: 'rate', headerName: 'Rate (G/h)', width: 160 },
        { field: 'ts', headerName: 'Updated', width: 175, valueFormatter: dateFormatter }
    ].map((column, index) => ({ ...column, hide: hidden[index] ?? false }));

    const initialSortModel = [
        { field: 'name', sort: 'asc' },
        { field: 'medium', sort: 'asc' }
    ];

    const onHideColumn = useCallback((field, val) => {
        setHidden(columns.map((column, index) => {
            if (column.field === field) {
                return val;
            }
            return hidden[index] ?? false;
        }));
    }, [columns, hidden]);

    const { url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const onRowClick = (params) => {
        console.log('row click:', params);
        history.push(`${url}/${params?.row?.hubId}/${params?.row?.medium}`);
    };

    const onClose = () => {
        history.push(`${url}`);
    };

    return (
        <Horizontal>
            <Vertical>
                <Grid
                    title={'Water Meters'}
                    columns={columns}
                    rows={rows}
                    initialSortModel={initialSortModel}
                    hideColumnControl={true}
                    onHideColumn={onHideColumn}
                    disableColumnMenu={false}
                    disableSelectionOnClick={true}
                    checkBoxSelection={false}
                    onRowClick={onRowClick}
                />
            </Vertical>
            {url !== location.pathname &&
                <Vertical>
                    <Switch>
                        <Route path={`${url}/:hubId/:medium`}>
                            <WaterMeterDetail onClose={onClose} />
                        </Route>
                    </Switch>
                </Vertical>
            }
        </Horizontal>
    );
};

export default WaterMeters;
