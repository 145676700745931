import TabNav from '../components/TabNav';

import Fixtures from './Fixtures';
import Drivers from './Drivers';
import Products from './Products';

const Catalog = () => {
    return (
        <TabNav tabs={[
            { route: 'fixtures', label: 'Fixtures', component: <Fixtures /> },
            { route: 'drivers', label: 'Drivers', component: <Drivers /> },
            { route: 'products', label: 'Products', component: <Products /> }
        ]} />
    );
};

export default Catalog;
