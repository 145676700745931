import { useState, useMemo } from 'react';

import Grid from '../../components/Grid';
import { Horizontal, Vertical } from '../../components/Layout';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useCurrentBuilding, useButtonPresses } from '@domatic/query';
import Loading from '../../components/Loading';
import Typography from '@material-ui/core/Typography';

import { DateTime } from 'luxon';
import prettyMs from 'pretty-ms';

const dateFormatter = params => DateTime.fromMillis(params.value).toRelative();
const durationFormatter = params => prettyMs(params.value);

const ButtonPresses = () => {
    const building = useCurrentBuilding();
    const initialSortModel = [
        { field: 'ts', sort: 'desc' }
    ];
    const [sortModel, setSortModel] = useState(initialSortModel);
    const onSortModelChange = model => {
        setSortModel(model);
    };

    const { data: presses, isFetching: loading, hasNextPage, fetchNextPage, isError, error } = useButtonPresses(building?.id, { sortModel });

    const rows = useMemo(() => presses.map((a, index) => {
        const ts = a.ts;
        const id = index;
        const hub = a.hub ?? '-';
        const deviceId = a.deviceId;
        const action = a.action;
        const duration = a.duration;
        return { ts, id, hub, deviceId, action, duration };
    }), [presses]);

    const [columns, setColumns] = useState([
        { field: 'ts', headerName: 'Timestamp', flex: 1, valueFormatter: dateFormatter },
        { field: 'hub', headerName: 'Hub', flex: 1, sortable: false },
        { field: 'deviceId', headerName: 'Device ID', flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1 },
        { field: 'duration', headerName: 'Duration', flex: 1, valueFormatter: durationFormatter }
    ]);

    const onHideColumn = (field, val) => {
        setColumns(columns.map(e => {
            if (e.field === field) {
                e.hide = val;
            }
            return e;
        }));
    };

    const { url } = useRouteMatch();
    const history = useHistory();

    const onRowClick = (params) => {
        console.log('row click:', params);
        history.push(`${url}/${params?.row?.hubId}/hub`);
    };

    // const onClose = () => {
    //     history.push(`${url}`);
    // };

    return (
        <Horizontal>
            <Vertical>
                <Loading active={loading} spinner text={'Loading...'}>
                    {isError
                        ? <Typography variant="body">{error}</Typography>
                        : <Grid
                            title={'Activity'}
                            columns={columns}
                            rows={rows}
                            sortingMode={'server'}
                            initialSortModel={initialSortModel}
                            onSortModelChange={onSortModelChange}
                            canFetchMore={hasNextPage}
                            fetchMoreRows={() => fetchNextPage()}
                            onHideColumn={onHideColumn}
                            disableColumnMenu={false}
                            disableSelectionOnClick={true}
                            checkBoxSelection={false}
                            onRowClick={onRowClick}
                            multiple={false}
                        />
                    }
                </Loading>
            </Vertical>
        </Horizontal>
    );
};

export default ButtonPresses;
