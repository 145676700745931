import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, CardActions } from '@material-ui/core';
import Loading from '../components/Loading';
import { useCardStyles } from '../hooks';
import { useInput } from '@domatic/hooks';
import { Center, Field, ErrorList, PasswordField } from '../components';
import { useAuthenticated, useSignup, checkUsername } from '@domatic/query';

const Signup = () => {
    const classes = useCardStyles();

    const authenticated = useAuthenticated();

    const name = useInput('', 'notEmpty', 'Name');
    const username = useInput('', ['username', checkUsername], 'Username');
    const email = useInput('', 'email', 'Email');
    const password = useInput('', 'password', 'Password');

    const [loginRedirect, goLogin] = useState(false);
    const [validatingRedirect, goValidating] = useState(false);

    const { mutate: signup, isLoading: loading, error: signupError } = useSignup();

    const signupClick = () => signup(
        {
            name: name.value,
            username: username.value,
            email: email.value,
            password: password.value
        },
        { onSuccess: () => goValidating(true) }
    );

    const formValid = name.valid && username.valid && password.valid;

    if (authenticated) {
        return <Redirect to='/' />;
    }

    if (validatingRedirect) {
        return <Redirect to='/validating' />;
    }

    if (loginRedirect) {
        return <Redirect to='/login' />;
    }

    return (
        <Center>
            <Card className={classes.card}>
                <Loading active={loading} spinner text="Creating Account...">
                    <CardHeader title="Create an Account" />
                    <CardContent>
                        <Field field={name} autoFocus required fullWidth />
                        <Field field={username} required fullWidth />
                        <Field field={email} required fullWidth />
                        <PasswordField field={password} password required fullWidth onEnter={signupClick} />
                        <ErrorList errors={[signupError]} />
                        <br />
                        <Button color="primary" onClick={signupClick} disabled={!formValid}>Sign Up</Button>
                    </CardContent>
                    <CardActions>
                        <Button variant="text" onClick={() => goLogin(true)}>Log In</Button>
                    </CardActions>
                </Loading>
            </Card>
        </Center>
    );
};

export default Signup;
