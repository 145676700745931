import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Typography, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        width: 275,
        margin: '10px',
        color: 'success'
    },
    title: {
        fontSize: 14,
    },
    message: {
        marginBottom: 12,
    },
});

const StatusCard = ({ info }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={7} >
            <CardHeader
                avatar={
                    info?.severity === 'error'
                        ? < ErrorIcon color="error" />
                        : info?.severity === 'warning'
                            ? <WarningIcon color="action" />
                            : info?.severity === 'success'
                                ? <CheckBoxIcon color="secondary" />
                                : <InfoIcon color="primary" />
                }
                title={<Typography variant="h5" component="h2" color="textSecondary" gutterBottom>{info?.title} </Typography>}
            />
            <CardContent>
                {info?.message?.map((line, index) => (
                    <Typography key={index} component="p">{line}</Typography>)
                )}
            </CardContent>
        </Card>
    );
};

StatusCard.propTypes = {
    info: PropTypes.object
};

export default StatusCard;
