import PropTypes from 'prop-types';

import Chip from '../components/StatusChip';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';

import { useHubSettings } from '@domatic/query';

const Emergency = ({ hub }) => {

    const { data: settings } = useHubSettings(hub.id);

    const state = settings?.Emergency?.state;

    return (
        <>
            {state ? (
                <Chip
                    icon={<ErrorIcon />}
                    label="Power Fail"
                    color="secondary"
                />
            ) : (
                <Chip
                    icon={<CheckIcon />}
                    label="Power OK"
                    color="primary"
                />
            )}
        </>
    );
};

Emergency.propTypes = {
    hub: PropTypes.object
};

export default Emergency;
