import PropTypes from 'prop-types';
import { Button, Typography, Box } from '@material-ui/core';
import { Field } from '../components';
import { useInput } from '@domatic/hooks';
import { Driver } from '@domatic/query';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';

const DriverDetail = ({ title, driver, onClose }) => {

    const id = driver?.id;

    const name = useInput(driver?.name, 'notEmpty', 'Name');
    const identifier = useInput(driver?.identifier, 'notEmpty', 'Identifier');
    const version = useInput(driver?.version, 'notEmpty', 'Version');
    const revision = useInput(driver?.revision, 'notEmpty', 'Revision');
    const code = useInput(driver?.code, 'notEmpty', 'Code');

    const fields = [name, identifier, revision, version, code];
    const formValid = fields.every(f => f.valid);

    const { mutate: updateDriver, error: updateError } = Driver.useUpdate();
    const { mutate: createDriver, error: addError } = Driver.useCreate();

    const handleUpdate = () => {
        updateDriver({
            id,
            name: name.value,
            identifier: identifier.value,
            version: version.value,
            revision: revision.value,
            code: code.value
        }, {
            onSuccess: onClose
        });
    };

    const handleCreate = () => {
        createDriver({
            name: name.value,
            identifier: identifier.value,
            version: version.value,
            revision: revision.value,
            code: code.value
        }, {
            onSuccess: onClose
        });
    };

    return (
        <Box p={2}>
            <Typography>{title}</Typography><br />
            <Field field={name} required fullWidth />
            <Field field={identifier} required fullWidth />
            <Field field={version} required fullWidth />
            <Field field={revision} required fullWidth />
            <Field field={code} required fullWidth />
            <br />
            <Button color="primary" onClick={id ? handleUpdate : handleCreate} disabled={!formValid} startIcon={id ? <EditIcon /> : <AddIcon />}>{id ? 'Save' : 'Add'}</Button>
            &nbsp;&nbsp;
            <Button color="secondary" onClick={onClose} startIcon={<UndoIcon />}>Cancel</Button>
            <Typography>{updateError || addError}</Typography><br />

        </Box >
    );
};

DriverDetail.propTypes = {
    title: PropTypes.string,
    driver: PropTypes.object,
    onClose: PropTypes.func
};

DriverDetail.defaultProps = {};

export default DriverDetail;
