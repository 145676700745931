import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';

import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Vertical, Body } from './Layout';

const SideBarMenu = ({ items }) => {
    const location = useLocation();
    return (
        <Vertical>
            <Body>
                <List disablePadding={true}>
                    {items.map(({ primaryText, icon, path, divider, button, onClick }, i) => (
                        divider ? (
                            <Divider style={{ margin: '12px 0' }} key={i} />
                        )
                            : button ? (
                                <ListItem
                                    key={i}
                                    button
                                    onClick={onClick}
                                >
                                    <Icon>{icon}</Icon>
                                </ListItem>
                            )
                                : (
                                    <ListItem
                                        key={i}
                                        selected={path === location.pathname}
                                        button
                                        component={Link}
                                        to={path}
                                    >
                                        <ListItemIcon>
                                            <Icon>{icon}</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={primaryText}
                                            primaryTypographyProps={{ noWrap: true }}
                                        />
                                    </ListItem>
                                )
                    ))}
                </List >
            </Body>
        </Vertical>
    );
};

SideBarMenu.propTypes = {
    items: PropTypes.array,
};

SideBarMenu.defaultProps = {};

export default SideBarMenu;
