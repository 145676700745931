import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import LabelSwitch from '../components/LabelSwitch';
import CustomSelect from '../components/CustomSelect';

import { useHubSettings, useUpdateSetting, useUpdateHub, useCurrentBuilding } from '@domatic/query';
import { useInput } from '@domatic/hooks';

import moment from 'moment';

const Zeus = ({ hub }) => {
    const building = useCurrentBuilding();
    const { mutate: updateSetting, isLoading: saving } = useUpdateSetting(hub.id);
    const { data: settings } = useHubSettings(hub?.id);
    const zeusControl = settings?.ZeusControl;

    const onClick = (field, event) => {
        event.stopPropagation();
        updateSetting({
            __t: 'ZeusControl',
            [field]: event?.target?.checked
        });
    };

    const running = zeusControl?.running;
    const state = zeusControl?.state;
    const message = zeusControl?.message;
    const started = zeusControl?.started;

    const station = useInput(hub.station, '', 'Station');
    const stationList = ['new', 'incomplete', 'whitelist_now', 'primed', 'manual', 'done'].map(station => ({
        name: station,
        id: station
    }));

    const { mutate: updateHub, isLoading: savingStation } = useUpdateHub();

    const doUpdate = () => {
        updateHub({ id: hub.id, station: station.value }, {
            onSuccess: () => {
                station.reset();
            }
        });
    };

    return (
        <Box>
            {building?.whitelisting ?
                <Typography color="error">Whitelisting is enabled for this building.  Use station control to trigger a scan.</Typography>
                :
                <>
                    <LabelSwitch checked={zeusControl?.prudentMode} label={'Whitelist Enabled'} onChange={event => onClick('prudentMode', event)} disabled={saving} />
                    <LabelSwitch checked={zeusControl?.requestPortDetect} label={'Request Port Detection'} onChange={event => onClick('requestPortDetect', event)} disabled={saving} />
                    <LabelSwitch checked={zeusControl?.requestWhitelist} label={'Request Whitelist Update'} onChange={event => onClick('requestWhitelist', event)} disabled={saving} />
                </>
            }
            <Box p={2} display="flex" alignItems="center" justifyContent="flex-start">
                <CustomSelect field={station} data={stationList} required fullWidth />
                &nbsp;&nbsp;
                <Button onClick={() => doUpdate()} color='primary' disabled={!station.changed || savingStation}>Set Station</Button>
            </Box>
            <br />
            <Typography>{running}: {state} - {message}</Typography>
            <br />
            <Typography>Started: {moment(started?.expiresAt).calendar()}</Typography>
            <br />

        </Box>);
};

Zeus.propTypes = {
    hub: PropTypes.object.isRequired
};

export default Zeus;
