import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Body = ({ p, m, px, py, mx, my, children }) => (
    <Box overflow="hidden" flexGrow={1} width="100%">
        <Box
            height="100%"
            width="100%"
            overflow="auto"
            p={p}
            px={px}
            py={py}
            m={m}
            mx={mx}
            my={my}
        >
            {children}
        </Box>
    </Box>
);

Body.propTypes = {
    children: PropTypes.node,
    p: PropTypes.node,
    px: PropTypes.node,
    py: PropTypes.node,
    m: PropTypes.node,
    mx: PropTypes.node,
    my: PropTypes.node
};

export default Body;

