import { useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Loading from '../components/Loading';
import Grid from '../components/Grid';
import { Product } from '@domatic/query';
import ProductDetail from './ProductDetail';
import { Box } from '@material-ui/core';
import moment from 'moment';

const Products = () => {

    const { path, url } = useRouteMatch();
    const history = useHistory();

    const [selected, setSelected] = useState({});

    const { data: rows, count, isFetching: loading, hasNextPage, fetchNextPage } = Product.useAll({});
    const { mutate: deleteProduct, isLoading: deleting } = Product.useDelete();

    const dateFormatter = params => moment(params.value).calendar();
    const booleanFormatter = params => { params.value === false ? '✗' : params.value ? '✓' : ''; };
    // const jsonFormatter = params => JSON.stringify(params.value);

    const [columns, setColumns] = useState([
        { field: 'created', headerName: 'Created Time', flex: 1, valueFormatter: dateFormatter },
        { field: 'timestamp', headerName: 'Updated Time', flex: 1, valueFormatter: dateFormatter },
        { field: 'mac', headerName: 'MAC', flex: 1 },
        { field: 'product', headerName: 'Product', flex: 1 },
        { field: 'firmware', headerName: 'Firmware', flex: 1 },
        { field: 'boardSn', headerName: 'Board Serial', flex: 1 },
        { field: 'serial', headerName: 'Serial', flex: 1 },
        { field: 'boardStatus', headerName: 'Board Status', flex: 1, valueFormatter: booleanFormatter },
        { field: 'status', headerName: 'Status', flex: 1, valueFormatter: booleanFormatter },
    ]);

    const initialSortModel = [
        { field: 'product', sort: 'asc' }
    ];

    const onHideColumn = (field, val) => {
        setColumns(columns.map(e => {
            if (e.field === field) {
                e.hide = val;
            }
            return e;
        }));
    };

    const onAdd = () => {
        history.replace(`${url}/add`);
    };

    const onDelete = toDelete => {
        const selectedItem = rows.find(item => item.id === toDelete[0]);
        console.log('deleting:', toDelete, selectedItem, rows);
        deleteProduct(selectedItem.id);
    };

    const onEdit = toEdit => {
        const selectedItem = rows.find(item => item.id === toEdit[0]);
        console.log('editing:', toEdit, selectedItem, rows);
        setSelected(selectedItem);
        history.replace(`${url}/${selectedItem.id}/edit`);
    };

    const onClose = () => {
        history.replace(`${url}`);
    };

    const active = loading || deleting;
    const text = loading ? 'Loading...' : 'Deleting...';

    return (
        <Box height="100%">
            <Loading active={active} spinner text={text}>
                <Switch>
                    <Route path={`${path}/add`}>
                        <ProductDetail title="Add a New Product" onClose={onClose} />
                    </Route>
                    <Route path={`${path}/${selected?.id}/edit`}>
                        <ProductDetail title="Edit Products" product={selected} onClose={onClose} />
                    </Route>
                    <Route path={`${path}`}>
                        <Grid
                            title={'Products'}
                            rows={rows}
                            columns={columns}
                            rowCount={count}
                            sortingMode={'server'}
                            initialSortModel={initialSortModel}
                            canFetchMore={hasNextPage}
                            fetchMoreRows={() => fetchNextPage()}
                            multiple={false}
                            onDelete={onDelete}
                            onAdd={onAdd}
                            onEdit={onEdit}
                            onHideColumn={onHideColumn}
                        >
                        </Grid>
                    </Route>
                </Switch>
            </Loading>
        </Box>
    );
};

export default Products;
