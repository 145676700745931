import { getQueryClient, useMutation, useQuery } from './queryClient';

let _menuDrawerOpen = false;

export const useMenuDrawerOpen = () =>
    useQuery(['menuDrawerOpen'], async () => _menuDrawerOpen, { staleTime: Infinity });

const setMenuDrawerOpen = async open => {
    _menuDrawerOpen = open;
};

export const useSetMenuDrawerOpen = () => {
    const queryClient = getQueryClient();
    return useMutation(
        setMenuDrawerOpen, { onSuccess: () => queryClient.invalidateQueries(['menuDrawerOpen']) }
    );
};
