import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { Center } from '../components';
import { useCardStyles } from '../hooks';
import HomeOrLogin from './HomeOrLogin';

const Accepted = () => {
    const classes = useCardStyles();

    const { email, organization } = useParams();

    return (
        <Center>
            <Card className={classes.card}>
                <CardHeader title="Thank You" />
                <CardContent>
                    <p><strong>{email}</strong> has been added to <strong>{organization}</strong></p>
                </CardContent>
                <CardActions>
                    <HomeOrLogin />
                </CardActions>
            </Card>
        </Center>
    );
};

export default Accepted;
