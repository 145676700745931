import PropTypes from 'prop-types';

import { Button, Typography, Box } from '@material-ui/core';

import { Field, CustomSelect } from '../components';

import { useInput } from '@domatic/hooks';
import { Product, Driver } from '@domatic/query';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';

const ProductDetail = ({ title, product, onClose }) => {

    const { data: drivers } = Driver.useAll({ auto: true });
    const driverOptions = drivers.map(driver => ({ id: driver.identifier, name: driver.name }));
    const isOneOf = options => async value =>
        options.every(item => item.id !== value) ? 'make selection' : null;

    const id = product?.id;

    const mac = useInput(product?.mac, 'notEmpty', 'MAC');
    const driver = useInput(product?.driver, isOneOf(driverOptions), 'Driver Type');
    const fixtureId = useInput(product?.fixtureId, 'uuid', 'Product ID');

    const fields = [mac, driver, fixtureId,];
    const formValid = fields.every(f => f.valid);

    const { mutate: updateProduct, error: updateError } = Product.useUpdate();
    const { mutate: createProduct, error: addError } = Product.useCreate();

    const handleUpdate = () => {
        updateProduct({
            id: product?.id,
            mac: mac.value,
            driver: driver.value,
            fixtureId: fixtureId.value,
        }, {
            onSuccess: onClose
        });
    };

    const handleCreate = () => {
        createProduct({
            mac: mac.value,
            driver: driver.value,
            fixtureId: fixtureId.value,
        }, {
            onSuccess: onClose
        });
    };

    return (
        <Box p={2}>
            <Typography>{title}</Typography><br />
            <Field field={mac} required fullWidth />
            <Field field={fixtureId} required fullWidth />
            <CustomSelect field={driver} data={driverOptions} required fullWidth />
            <br />
            <br />
            <br />
            <Button color="primary" onClick={id ? handleUpdate : handleCreate} disabled={!formValid} startIcon={id ? <EditIcon /> : <AddIcon />}>{id ? 'Save' : 'Add'}</Button>
            &nbsp;&nbsp;
            <Button color="secondary" onClick={onClose} startIcon={<UndoIcon />}>Cancel</Button>
            <Typography>{updateError || addError}</Typography><br />
        </Box >
    );
};

ProductDetail.propTypes = {
    title: PropTypes.string,
    product: PropTypes.object,
    onClose: PropTypes.func
};

ProductDetail.defaultProps = {};

export default ProductDetail;
