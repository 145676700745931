import PropTypes from 'prop-types';
import { useFaultStatistics } from '@domatic/query';
import StatusCard from '../../components/StatusCard';

const summary = counts => Object.keys(counts).map(key => `${key}: ${counts[key]}`) ?? ['No faults'];

// eslint-disable-next-line no-unused-vars
const FaultCount = ({ building }) => {
    const { data: counts } = useFaultStatistics(building?.id);

    const info = {
        title: 'Faults',
        severity: 'warning',
        message: summary(counts)
    };

    return (
        <StatusCard info={info} />
    );
};

FaultCount.propTypes = {
    building: PropTypes.object
};

export default FaultCount;
