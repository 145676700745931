import PropTypes from 'prop-types';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { red, green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ProgressButton = ({ children, color, variant, disabled, loading, error, success, onClick, startIcon }) => {
    const classes = useStyles();

    const buttonClassname = error
        ? clsx({ [classes.buttonError]: error, })
        : clsx({ [classes.buttonSuccess]: success, });

    return (
        <Button
            variant={variant}
            color={color}
            className={buttonClassname}
            disabled={disabled || loading}
            onClick={onClick}
            startIcon={startIcon}
        >
            {loading ? <CircularProgress size={24} className={classes.buttonProgress} />
                : error || children}
        </Button>
    );
};

ProgressButton.propTypes = {
    children: PropTypes.node.isRequired,
    startIcon: PropTypes.node,
    variant: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    success: PropTypes.bool
};

ProgressButton.defaultProps = {
    success: false,
    variant: 'contained'
};

export default ProgressButton;
