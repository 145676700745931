import { Route, Link as RouterLink } from 'react-router-dom';
import { Typography, Breadcrumbs, Box, Link } from '@material-ui/core';

import useRoutes from '../hooks/useRoutes';

const parsePath = (path, routes) => {

    const name = path.shift();

    let match = routes.find(route => route.path === name);
    if (!match) {
        match = routes.find(route => route.path === 'default');
    }
    if (!match)
        return [];

    let value = match.name;
    if (typeof value === 'function') {
        value = value(name);
    }

    if (path.length > 0 && match.children) {
        return [{ name, value }, ...parsePath(path, match.children)];
    }
    else {
        return [{ name, value }];
    }
};

const BreadcrumbBar = () => {

    const routes = useRoutes();

    return (
        <Box p={2}>
            <Route>
                {({ location }) => {
                    const nodes = parsePath(location.pathname.split('/').filter(x => x), routes);
                    return (
                        <Breadcrumbs>
                            {nodes.map((node, index) => {
                                const last = index === nodes.length - 1;
                                const to = `/${nodes.slice(0, index + 1).map(p => p.name).join('/')}`;

                                return last
                                    ? (
                                        <Typography key={to}>
                                            {node.value}
                                        </Typography>
                                    ) : (
                                        <Link component={RouterLink} to={to} key={to}>
                                            {node.value}
                                        </Link>
                                    );
                            })}
                        </Breadcrumbs>
                    );
                }}
            </Route>
        </Box>
    );
};

export default BreadcrumbBar;

