const PropTypes = require('prop-types');

import { Typography } from '@material-ui/core';

import { useHubSettings } from '@domatic/query';
import ProgressBar from '../components/ProgressBar';
import Horizontal from '../components/Layout/Horizontal';

const UpdateStatus = ({ hubId }) => {

    const { data: settings } = useHubSettings(hubId);

    const status = settings?.UpdateStatus;
    const state = status?.state ?? 'idle';
    const version = status?.version;
    const message = status?.message;

    const installing = state !== 'idle';
    const progress = (state === 'idle' || state === 'success') ? 0 : (status?.progress ?? 50);

    let label = '';
    if (state === 'error') {
        label = `Error installing build ${version}: ${message}`;
    } else if (state === 'idle') {
        label = `Installation status: Idle (version ${version})`;
    } else {
        label = `Installing ${version}: ${message}`;
    }

    return (
        <>
            <Typography variant="body">{label}</Typography>
            <br />
            {installing &&
                <>
                    <Horizontal>
                        <ProgressBar progress={progress} />
                    </Horizontal>
                </>
            }
            <br />
        </>
    );
};

UpdateStatus.propTypes = {
    hubId: PropTypes.string.isRequired
};

export default UpdateStatus;
