import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Field } from '.';

const Visibility = ({ field, required, fullWidth, onEnter, current }) => {
    const [visible, setVisible] = useState(false);

    const onClick = () => setVisible(!visible);

    return (
        <Box display="flex" flexDirection="row">
            <Field field={field}
                password={!visible}
                current={current}
                required={required}
                fullWidth={fullWidth}
                onEnter={onEnter} />
            <Box display="flex" flexDirection="column">
                <IconButton aria-label="visible" color="primary" onClick={onClick}>
                    {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
            </Box>
        </Box>
    );
};

Visibility.propTypes = {
    field: PropTypes.object,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onEnter: PropTypes.func,
    current: PropTypes.bool
};

export default Visibility;

