import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '../../components/Grid';

import { useBuildingFaults, useBuildingHubs } from '@domatic/query';
// import moment from 'moment';

const camelCaseToTitleCase = (str) => str.replace(/([A-Z])/g, ' $1');
// const dateFormatter = params => moment(params.value).calendar();
const dateFormatter = params => new Date(params.value).toLocaleString('en-us', { dateStyle: 'short', timeStyle: 'short' });
const typeFormatter = params => {
    const type = params.value;
    const title = camelCaseToTitleCase(type);
    return title;
};

const FaultList = ({ buildingId, onRowClick }) => {

    const lastMidnight = useMemo(() => {
        const now = new Date();
        const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return midnight.getTime();
    }, []);

    const { data: hubs } = useBuildingHubs(buildingId);
    const { data: faults } = useBuildingFaults(buildingId, lastMidnight);

    const rows = useMemo(() => faults?.map(fault => {
        const hub = hubs.find(hub => hub.id === fault.hubId);
        fault.hub = hub?.name ?? '-';
        return fault;
    }), [faults, hubs]);

    const [hidden, setHidden] = useState([]);

    const booleanFormatterInverse = params => params.value ? '' : '✓';

    const columns = [
        { field: 'hub', headerName: 'Hub', flex: 1 },
        { field: '__t', headerName: 'Type', width: 150, valueFormatter: typeFormatter },
        { field: 'summary', headerName: 'Summary', flex: 4 },
        { field: 'resolved', headerName: 'Open', width: 120, valueFormatter: booleanFormatterInverse },
        { field: 'timestamp', headerName: 'Modified', width: 175, valueFormatter: dateFormatter }
    ].map((column, index) => ({ ...column, hide: hidden[index] ?? false }));

    const initialSortModel = [
        { field: 'resolved', sort: 'asc' },
        { field: 'hub', sort: 'asc' },
        { field: '__t', sort: 'asc' }
    ];

    const onHideColumn = useCallback((field, val) => {
        setHidden(columns.map((column, index) => {
            if (column.field === field) {
                return val;
            }
            return hidden[index] ?? false;
        }));
    }, [columns, hidden]);

    return (
        <Grid
            title={'Faults'}
            columns={columns}
            rows={rows}
            initialSortModel={initialSortModel}
            hideColumnControl={true}
            onHideColumn={onHideColumn}
            onRowClick={onRowClick}
            disableColumnMenu={false}
            disableSelectionOnClick={true}
            checkBoxSelection={false}
        />
    );
};

FaultList.propTypes = {
    buildingId: PropTypes.string,
    onRowClick: PropTypes.func
};

export default FaultList;
