import { useState } from 'react';

const useCheckbox = (bindingInput = false, label) => {
    const binding = Boolean(bindingInput);
    const [lastBinding, setLastBinding] = useState(false);
    const [changed, setChanged] = useState(false);
    const [checked, setChecked] = useState(binding);
    const [helperText, setHelperText] = useState(' ');

    if (binding !== lastBinding) {
        setLastBinding(binding);
        setChecked(binding);
    }

    label = label || 'Name Me';
    const name = label.toLowerCase().replace(/\s/g, '');

    const reset = () => {
        setChecked(lastBinding);
        setLastBinding(binding);
        setChanged(false);
    };

    const onChange = event => {
        setChecked(Boolean(event.target.checked));
        setChanged(event.target.checked !== lastBinding);
        setHelperText(' ');
    };
    return { checked: checked, setChecked: setChecked, reset, changed, onChange, helperText, setHelperText, label, name };
};

export default useCheckbox;
