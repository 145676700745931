import { Box, Button, Typography, Checkbox, FormControlLabel } from '@material-ui/core';

import Loading from '../components/Loading';

import { useCheckbox } from '@domatic/hooks';
import { ErrorList } from '../components';

import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';

import { useCurrentBuilding, useUpdateBuilding, useBuildingCommissionRunner } from '@domatic/query';

const BuildingSetup = () => {

    const building = useCurrentBuilding();
    const whitelisting = useCheckbox(building?.whitelisting, 'Enable Whitelisting');
    const commission = useCheckbox(building?.commission, 'Enable Smart Commissioning');
    const { data: commissioner } = useBuildingCommissionRunner(building?.id);

    const { mutate: updateBuilding, isLoading: saving, error: updateError } = useUpdateBuilding();

    const doSave = () => updateBuilding({
        id: building.id,
        whitelisting: whitelisting.checked,
        commission: commission.checked
    });

    const doReset = () => {
        whitelisting.reset();
        commission.reset();
    };

    const changes = whitelisting.changed || commission.changed;
    const formValid = changes;

    return (
        <Box p={2}>
            <Loading active={saving} spinner text="Saving...">
                <Typography variant="h6">Building Setup for {building?.name}</Typography>
                <br />
                <FormControlLabel
                    control={<Checkbox checked={whitelisting.checked} onChange={whitelisting.onChange} />}
                    label="Enable Whitelisting"
                />
                <br />
                <FormControlLabel
                    control={<Checkbox checked={commission.checked} onChange={commission.onChange} />}
                    label="Enable Commissioning"
                />
                <ErrorList errors={[updateError]} />
                <br />
                <Typography>Commissioning Status: {commissioner?.status}</Typography>
                <br />
                <Button color="primary" onClick={doSave} disabled={!formValid} startIcon={<SaveIcon />}>Save</Button>
                &nbsp;
                <Button color="secondary" onClick={doReset} disabled={!changes} startIcon={<UndoIcon />}>Reset</Button>
            </Loading>
        </Box >
    );
};

export default BuildingSetup;
