import axios from 'axios';

import { getQueryClient, useQuery, useMutation } from './queryClient';

import {
    startSession, endSession, useUserId, errorCheck,
} from './session';

class LoginError extends Error {
    constructor(message, code, statusCode = 500) {
        super(message);
        this.name = this.constructor.name;
        this.code = code || 'ENONE';
        this.statusCode = statusCode;
    }
}

const login = async credentials => {
    try {
        const { data } = await axios.post('/user/login', credentials);
        return data;
    }
    catch (error) {
        console.error(error);
        if (error?.response?.status === 401) {
            throw new LoginError('Invalid username or password.', 'EAUTH', 401);
        }
        else if (error?.response?.status === 403) {
            const err = new LoginError('User not validated', 'EINVALID', 403);
            throw err;
        }
        else {
            throw error;
        }
    }
};

export const useLogin =
    () => useMutation(login, {
        onSuccess: session => {
            startSession(session);
        },
        onError: error => {
            console.error('login error:', error.message);
        }
    });

const refresh = () =>
    axios.post('/user/refresh')
        .then(response => response.data);

export const useRefresh =
    () => useMutation(refresh, {
        onSuccess: session => startSession(session),
        onError: error => endSession(error)
    });

const logout = async () => {
    return axios.post('/user/logout');
};

export const useLogout =
    () => useMutation(logout, {
        onSuccess: () => endSession(),
        onError: error => endSession(error)
    });

const signup = credentials => axios.post('/user/signup', credentials);

export const useSignup = () => useMutation(signup, { throwOnError: true });

const getProfile = userId => async () => {
    if (!userId) {
        // console.log('[getProfile] no userId');
        return null;
    }
    try {
        // console.log('[getProfile] userId', userId);
        const response = await axios.get('/user/profile');
        return response.data;
    }
    catch (error) {
        console.log('getProfile error');
        errorCheck(error);
    }
};

export const useProfile = () => {
    const userId = useUserId();
    return useQuery([userId, 'session', 'profile'], getProfile(userId));
};

const updateProfile = updates =>
    axios.put('/user/profile', updates)
        .then(response => response.data)
        .catch(errorCheck);

export const useUpdateProfile = () => {
    const userId = useUserId();
    const queryClient = getQueryClient();
    return useMutation(updateProfile, {
        onSuccess: profile => queryClient.setQueryData([userId, 'session', 'profile'], profile)
    });
};

const changePassword = data =>
    axios.post('/user/password', data)
        .then(response => response.data);

export const useChangePassword =
    () => useMutation(changePassword, { throwOnError: true });

const resetPassword = username =>
    axios.post('/user/reset', username)
        .then(response => response.data);

export const useResetPassword =
    () => useMutation(resetPassword, { throwOnError: true });

export const checkUsername = username => {
    const queryClient = getQueryClient();
    const cachedUsername = queryClient.getQueryData(['username', username]);
    if (cachedUsername !== undefined) return Promise.resolve(cachedUsername);
    return axios.get(`/user/check/${username}`)
        .catch(error => {
            console.error(error);
            return { data: false };
        })
        .then(response => response.data ? null : `username ${username} exists`)
        .then(data => {
            queryClient.setQueryData(['username', username], data, { staleTime: Infinity });
            return data;
        });
};

const forgot = username =>
    axios.post('/user/forgot', username);

export const useForgot =
    () => useMutation(forgot);

const resend = username =>
    axios.post('/user/resend', username);

export const useResend =
    () => useMutation(resend);
