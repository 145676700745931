// eslint-disable-next-line no-unused-vars
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';

import Loading from '../components/Loading';

import { Field, ErrorList } from '../components';
import { useInput } from '@domatic/hooks';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';

import { useMembers, useDeleteOrganizationById, useUpdateOrganization } from '@domatic/query';

const OrganizationDetail = ({ organization, onClose }) => {

    const name = useInput(organization?.name, 'notEmpty', 'Name');

    const { data: members, isLoading: loading } = useMembers(organization?.id);
    const owner = members?.find(member => member.id === organization?.owner);

    const { mutate: updateOrganization, isLoading: saving, error: fetchError } = useUpdateOrganization();
    const { mutate: deleteOrganizationById, isLoading: deleting, error: deleteError, isSuccess: deleteSuccess } = useDeleteOrganizationById();

    const changes = name.changed;
    const formValid = changes && name.valid;

    const doSave = () => {
        if (formValid) {
            updateOrganization({
                id: organization?.id,
                name: name.value
            });
            name.reset();
        }
    };

    const doReset = () => {
        name.reset();
    };

    const doDelete = async () => {
        deleteOrganizationById(organization?.id);
    };

    if (deleteSuccess) {
        onClose();
    }

    return (
        <Loading active={loading} spinner text="Loading...">
            <Loading active={saving} spinner text="Saving...">
                <Loading active={deleting} spinner text="Deleting...">
                    <Box p={2}>
                        <Field field={name} required fullWidth onEnter={doSave} />
                        <Typography>Owner: {owner?.name}</Typography>
                        <br />
                        <Button color="primary" onClick={doSave} disabled={!formValid} startIcon={<SaveIcon />}>Save</Button>
                        &nbsp;&nbsp;
                        <Button color="secondary" onClick={doReset} disabled={!changes} startIcon={<UndoIcon />}>Reset</Button>
                        <br />
                        <br />
                        <Button onClick={doDelete} startIcon={<DeleteIcon />}>Delete</Button>
                        <br />
                        <br />
                        <ErrorList errors={[deleteError, fetchError]} />
                    </Box>
                </Loading >
            </Loading >
        </Loading >
    );
};

OrganizationDetail.propTypes = {
    organization: PropTypes.object,
    onClose: PropTypes.func
};

export default OrganizationDetail;
