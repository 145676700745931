import { useRetrieveArray, createUseCrud, useRetrieveObject, usePost } from './utils';

import { getQueryClient } from './queryClient';

// exports in a file
export const TemplateDevice = {
    useByBuilding: buildingId => useRetrieveArray(['template', 'devices', 'building', buildingId], `/template/devices/${buildingId}`),
    ...createUseCrud(['template', 'devices'], '/template/device'),
    useAssociate: entryId => usePost('/template/associate', { entryId }),
    useUnassociate: entryId => usePost('/template/unassociate', { entryId })
};

export const TemplateCircuit = {
    useByBuilding: buildingId => useRetrieveArray(['template', 'circuits', 'building', buildingId], `/template/circuits/${buildingId}`),
    ...createUseCrud(['template', 'circuits'], '/template/circuit')
};

export const TemplateHub = {
    useByBuilding: buildingId => useRetrieveArray(['template', 'hubs', 'building', buildingId], `/template/hubs/${buildingId}`),
    ...createUseCrud(['template', 'hubs'], '/template/hub')
};

export const useTemplateEntryStatus = entryId => useRetrieveObject(['template', 'entry', 'status', entryId], `/template/entry/status/${entryId}`);

export const useImportTemplate = buildingId => {
    return usePost('/template/import', { buildingId });
};

export const useCaptureHubSerialToTemplate = buildingId => { // needs to be called with {serial, entryId}
    return usePost('/template/capture', { buildingId });
};

// each needs to be called with {entryId}
export const useIdentifyLightStart = () => usePost('/template/identify', { method: 'light' });
export const useIdentifySwitchStart = () => usePost('/template/identify', { method: 'switch' });
export const useIdentifyStop = () => usePost('/template/identify', { action: 'cancel' });

export const useIdentifyFeedback = () => { // needs to be called with { entryId, feedback }
    return usePost('/template/identify/feedback');
};

export const useIdentifyStatus = (entryId) => {
    return useRetrieveObject(['template', 'identify', entryId], `/template/identify/status/${entryId}`);
};

export const useSlotStatus = (entryId) => {
    return useRetrieveObject(['template', 'slot', entryId], `/template/entry/status/${entryId}`);
};

export const invalidateDeviceSlots = () => {
    console.log('invalidating device slots');
    const queryClient = getQueryClient();
    queryClient.invalidateQueries({
        predicate: query => query?.queryKey[1] === 'template' && query?.queryKey[2] === 'identify'
    });
};

export const useTemplateDiagnostics = (buildingId) => {
    return useRetrieveArray(['template', 'diagnostics', buildingId], `/template/diagnostics/${buildingId}`);
};
