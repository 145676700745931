import PropTypes from 'prop-types';
import { LinearProgress, Typography, Box } from '@material-ui/core';

const ProgressBar = ({ progress }) => {
    return (
        <Box display="flex" alignItems="center" width="100%">
            <Box flexGrow={1} mr={1}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box flexGrow={0} minWidth={30}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
            </Box>
        </Box>
    );
};

ProgressBar.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    progress: PropTypes.number.isRequired,
};

export default ProgressBar;
