import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Checkbox, Select, ListItemText, FormControl, FormHelperText, MenuItem, InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ListPicker = ({ items, selection, onChange, label }) => {

    const classes = useStyles();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        onChange(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label" for="demo-mutiple-checkbox">{label}</InputLabel>
            <Select
                displayEmpty
                labelid="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selection}
                onChange={handleChange}
                renderValue={selected => selected.join(', ')}
            >
                {items.map(item => (
                    <MenuItem key={item} value={item}>
                        <Checkbox checked={selection.indexOf(item) > -1} />
                        <ListItemText primary={item} />
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText />
        </FormControl>
    );
};

ListPicker.propTypes = {
    items: PropTypes.array,
    selection: PropTypes.array,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    helperText: PropTypes.string
};

export default ListPicker;
