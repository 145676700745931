import { useCreateGroup, useGroups } from '@domatic/query';
import GroupEdit from './GroupEdit';
import { Button, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Groups = () => {

    const { data: groups } = useGroups();
    const { mutate: createGroup } = useCreateGroup();

    const addGroup = () => {
        createGroup({
            name: 'New Group',
            branch: '',
            version: ''
        }, {
            onSuccess: () => {
            }
        });
    };

    return (
        <Box p={2} width="100%">
            {groups.map((group, i) => (
                <GroupEdit key={i} group={group} />
            ))}
            <Button color="primary" onClick={addGroup} startIcon={<AddIcon />}>{'Add'}</Button>
        </Box >

    );
};

export default Groups;
