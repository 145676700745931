import storage from '@domatic/local-storage';
import { useQuery, getQueryClient } from './queryClient';

let _settings = {};
const _getSettings = async () => {
    try {
        const value = await storage.getItem('settings');
        _settings = JSON.parse(value) ?? {};
    }
    catch (error) {
        _settings = {};
        storage.setItem('settings', JSON.stringify(_settings));
    }
    return _settings;
};

export const useSettings = () => {
    const query = useQuery(
        ['settings'],
        _getSettings,
        {
            staleTime: Infinity
        });
    return query;
};

export const updateSettings = updates => {
    const queryClient = getQueryClient();
    _settings = { ..._settings, ...updates };
    storage.setItem('settings', JSON.stringify(_settings));
    queryClient.setQueryData(['settings'], _settings);
};
