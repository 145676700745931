// eslint-disable-next-line no-unused-vars
import { useState } from 'react';

import { useHistory, useRouteMatch, Switch, Route, Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import Loading from '../components/Loading';
import moment from 'moment';

import { Field } from '../components';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useInput, useCheckbox } from '@domatic/hooks';
import { ErrorList } from '../components';

import { useSession, useProfile, useUpdateProfile, checkUsername, useActualAdmin, useAdmin, useLurk } from '@domatic/query';
import ChangePassword from '../user/ChangePassword';

const Settings = () => {

    const isActualAdmin = useActualAdmin();
    const isAdmin = useAdmin();
    const setLurkMode = useLurk();

    const { url } = useRouteMatch();
    const history = useHistory();

    const close = () => {
        history.replace(url);
    };

    const { data: profile } = useProfile();
    // eslint-disable-next-line no-unused-vars
    const { mutate: updateProfile, isLoading: saving, error: updateError } = useUpdateProfile();

    const session = useSession();

    const remoteUsernameCheck = async username => {
        const result = await checkUsername(username);
        if (profile.username !== username) {
            return result;
        }
        return null;
    };

    const name = useInput(profile?.name, '', 'Name');
    const username = useInput(profile?.username, ['username', remoteUsernameCheck], 'Username');
    const email = useInput(profile?.email, 'email', 'Email');
    const darkMode = useCheckbox(profile?.darkMode, 'Dark Mode');

    const onLurkChange = event => {
        setLurkMode(event.target.checked);
    };

    const saveClick = () => updateProfile({
        name: name.value,
        darkMode: darkMode.checked
    }, {
        onSuccess: doReset
    });

    const doReset = () => {
        name.reset();
        darkMode.reset();
    };

    const changes = name.changed || darkMode.changed; // || username.changed || email.changed;
    const formValid = changes && name.valid;// && username.valid && email.valid;

    return (
        <Loading active={saving} spinner text="saving...">
            <Switch>
                <Route path={`${url}/password`}>
                    <ChangePassword onClose={close} />
                </Route>
                <Route path={url}>
                    <Box p={2}>
                        <Field field={name} autoFocus required fullWidth />
                        <Field field={username} required fullWidth disabled />
                        <Field field={email} required fullWidth disabled />
                        <FormControlLabel
                            control={<Checkbox checked={darkMode.checked} onChange={darkMode.onChange} />}
                            label="Dark Mode"
                        />
                        <Button color="primary" onClick={saveClick} disabled={!formValid}>Save</Button>
                        &nbsp;
                        &nbsp;
                        <Button color="secondary" onClick={doReset} disabled={!changes}>Reset</Button>
                        <br />
                        <ErrorList errors={[updateError]} />
                        <br />
                        <Button component={Link} to={`${url}/password`}>Change Password</Button>
                        <br />
                        <br />
                        <Typography>Session time remaining: {moment(session?.expiresAt).fromNow(true)}</Typography>
                        <br />
                        <Button component={Link} to="/logout">Log Out</Button>
                        <br />
                        <br />
                        {isActualAdmin && (<FormControlLabel
                            control={<Checkbox checked={!isAdmin} onChange={onLurkChange} />}
                            label="Admin Lurk Mode"
                        />)}
                    </Box >
                </Route>
            </Switch >
        </Loading >
    );
};

Settings.propTypes = {
    user: PropTypes.object
};

Settings.defaultProps = {};

export default Settings;

