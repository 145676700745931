import PropTypes from 'prop-types';
import LoadingOverlay from './LoadingOverlay/LoadingOverlay';

const Loading = ({ active, text, children }) => (
    <LoadingOverlay
        active={active}
        spinner text={text}
        styles={{
            wrapper: {
                display: 'inherit',
                flexDirection: 'inherit',
                flexGrow: 'inherit',
                height: '100%',
                width: '100%'
            }
        }}>
        {children}
    </LoadingOverlay >
);

Loading.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.string,
    children: PropTypes.node
};

export default Loading;
