import PropTypes from 'prop-types';
import { Box, Chip } from '@material-ui/core';

const StatusChip = ({ label, icon, color }) => (
    <Box p={1}>
        <Chip
            icon={icon}
            label={label}
            style={{ backgroundColor: color }}
        />
    </Box>
);

StatusChip.propTypes = {
    icon: PropTypes.node,
    label: PropTypes.string,
    color: PropTypes.string
};

export default StatusChip;

