// eslint-disable-next-line no-unused-vars
import { Redirect, useRouteMatch, useLocation, useHistory, Switch, Route } from 'react-router-dom';
import { Horizontal, Vertical, Header, Body } from '../components/Layout';

import Admin from '../admin';
import Settings from './Settings';
import Hubs from '../hub/Hubs';
import Manage from '../manage';
import Building from '../building/Building';
import Organization from '../organization/Organization';
import AddOrganization from '../organization/AddOrganization';
import Dashboard from './dashboard';
import Catalog from '../catalog/Catalog';
import MenuDrawer from '../components/MenuDrawer';
import BreadcrumbBar from '../components/BreadcrumbBar';

import Offline from '../components/Offline';

import Apartment from '@material-ui/icons/Apartment';
import DeviceHub from '@material-ui/icons/DeviceHub';
import TuneIcon from '@material-ui/icons/Tune';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBox from '@material-ui/icons/AccountBox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ManageIcon from '@material-ui/icons/Assessment';
import ToysIcon from '@material-ui/icons/Toys';

import { useSessionPending, useAuthenticated, useAdmin, useCurrentOrganization } from '@domatic/query';
import SessionAlert from '../components/SessionAlert';

const Home = () => {

    const sessionPending = useSessionPending();
    const authenticated = useAuthenticated();
    const organization = useCurrentOrganization();
    const isAdmin = useAdmin();

    const items = [
        {
            primaryText: 'Dashboard',
            icon: <DashboardIcon />,
            path: '/home/dashboard',
            admin: false
        },
        {
            primaryText: 'Admin',
            icon: <TuneIcon />,
            path: '/home/admin',
            admin: true
        },
        {
            primaryText: 'Catalog',
            icon: <ToysIcon />,
            path: '/home/catalog',
            admin: true
        },
        {
            primaryText: 'Organization',
            icon: <AccountBox />,
            path: '/home/organization',
            admin: true
        },
        {
            primaryText: 'Building',
            icon: <Apartment />,
            path: '/home/building'
        },
        {
            primaryText: 'Hubs',
            icon: <DeviceHub />,
            path: '/home/hubs',
            admin: true
        },
        {
            primaryText: 'Manage',
            icon: <ManageIcon />,
            path: '/home/manage',
            admin: false
        },
        {
            divider: true
        },
        {
            primaryText: 'Settings',
            icon: <SettingsIcon />,
            path: '/home/settings'
        }
    ].filter(item => (!item.admin || isAdmin));

    const { url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    if (!sessionPending && !authenticated) {
        console.log('not authenticated. going to login');
        return (<Redirect to={{
            pathname: '/login',
            state: { from: location }
        }} />);
    }

    if (location.pathname === url) {
        return (<Redirect to={`${url}/dashboard`} />);
    }

    return (
        <>
            <SessionAlert />
            <Offline />
            <Horizontal>
                <Header>
                    <MenuDrawer items={items} />
                </Header>
                <Body>
                    <Vertical>
                        <Header>
                            <BreadcrumbBar />
                        </Header>
                        <Body>
                            <Switch>
                                <Route path={`${url}/dashboard`}>
                                    <Dashboard />
                                </Route>
                                <Route path={`${url}/building`}>
                                    <Building />
                                </Route>
                                <Route path={`${url}/manage`}>
                                    <Manage />
                                </Route>
                                <Route path={`${url}/settings`}>
                                    <Settings />
                                </Route>
                                {isAdmin && <>
                                    <Route path={`${url}/organization`}>
                                        <Organization organization={organization} onClose={() => history.replace(url)} />
                                    </Route>
                                    <Route path={`${url}/newOrganization`}>
                                        <AddOrganization onClose={() => history.replace(url)} />
                                    </Route>
                                    <Route path={`${url}/catalog`}>
                                        <Catalog />
                                    </Route>
                                    <Route path={`${url}/admin`}>
                                        <Admin />
                                    </Route>
                                    <Route path={`${url}/hubs`}>
                                        <Hubs />
                                    </Route>
                                </>
                                }
                            </Switch>
                        </Body>
                    </Vertical>
                </Body>
            </Horizontal>
        </>
    );
};

Home.propTypes = {
};

export default Home;
