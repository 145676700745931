import { useState } from 'react';
import Grid from '../components/Grid';
import { useJobs } from '@domatic/query';
import { Vertical, Body } from '../components/Layout';
import Loading from '../components/Loading';

import moment from 'moment';
const dateFormatter = params => moment(params.value).calendar();

export const JobManager = () => {

    const [sortModel, setSortModel] = useState([
        { field: 'completed', sort: 'desc' }
    ]);

    const onSortModelChange = model => {
        setSortModel(model);
    };

    const { data: rows, hasNextPage, fetchNextPage, isFetching } = useJobs({ sortModel });

    const initialSortModel = [
        { field: 'completed', sort: 'desc' }
    ];

    return (
        <Vertical>
            <Body>
                <Loading active={isFetching} spinner text={'Fetching...'}>
                    <Grid
                        title={'Job Manager'}
                        rows={rows}
                        columns={[
                            { field: 'id', headerName: 'ID', flex: 1 },
                            { field: 'workerId', headerName: 'Worker ID', flex: 1 },
                            { field: 'created', headerName: 'Created', flex: 1, valueFormatter: dateFormatter },
                            { field: 'assigned', headerName: 'Assigned', flex: 1, valueFormatter: dateFormatter },
                            { field: 'completed', headerName: 'Completed', flex: 1, valueFormatter: dateFormatter },
                            { field: '__t', headerName: 'Type', flex: 1 },
                            { field: 'branch', headerName: 'Branch', flex: 1 }
                        ]}
                        sortingMode={'server'}
                        initialSortModel={initialSortModel}
                        onSortModelChange={onSortModelChange}
                        canFetchMore={hasNextPage}
                        fetchMoreRows={() => fetchNextPage()}

                    />
                </Loading>
            </Body>
        </Vertical>
    );
};

JobManager.propTypes = {};

export default JobManager;
