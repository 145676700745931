import PropTypes from 'prop-types';
import { useLaunchHub } from '@domatic/query';
import LaunchIcon from '@material-ui/icons/Launch';
import { Box } from '@material-ui/core';
import ProgressButton from '../components/ProgressButton';

const Access = ({ hub, onError }) => {

    const { mutate: doLaunch, isFetching: launching } = useLaunchHub(hub);

    const launchClick = event => {
        event.stopPropagation();
        doLaunch(null, {
            onSuccess: () => {
                const url = `${window.location.protocol}//${hub.hostname}.${window.location.hostname}:${window.location.port}`;
                console.log('launching hub at', url);
                window.open(url, hub.hostname);
            },
            onError
        });
    };

    return (
        <Box mx={2} flexShrink={0}>
            <ProgressButton
                aria-label="Launch"
                onClick={launchClick}
                disabled={launching || !hub?.active}
                color="primary"
                startIcon={<LaunchIcon />}
            >
                Launch
            </ProgressButton>
        </Box>
    );
};

Access.propTypes = {
    hub: PropTypes.object,
    onError: PropTypes.func
};

export default Access;
