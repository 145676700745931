import BuildingDetail from './BuildingDetail';
import BuildingContacts from './BuildingContacts';
import TabNav from '../components/TabNav';

const Building = () => {

    return (
        <TabNav tabs={[
            { route: 'detail', label: 'Details', component: <BuildingDetail /> },
            { route: 'contacts', label: 'Contacts', component: <BuildingContacts /> }
        ]} />
    );
};

export default Building;
