import { createRoot } from 'react-dom/client';
import { ApiProvider } from '@domatic/query';

import App from './App';
import * as serviceWorker from './serviceWorker';

import notify from '@domatic/query/notify';
notify.start(null);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <ApiProvider>
        <App />
    </ApiProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
