import { useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import Loading from '../components/Loading';

import Grid from '../components/Grid';
import ContactDetail from './ContactDetail';
import { Contact, useContacts, useCurrentBuilding } from '@domatic/query';

import { Box } from '@material-ui/core';

const BuildingContacts = () => {

    const { path, url } = useRouteMatch();
    const history = useHistory();

    const building = useCurrentBuilding();

    const [selected, setSelected] = useState({});

    const { mutate: createContact, isLoading: adding } = Contact.useCreate(building?.id);
    const { mutate: updateContact, isLoading: updating } = Contact.useUpdate(building?.id);
    const { mutate: deleteContacts, isLoading: deleting } = Contact.useDelete(building?.id);

    const initialSortModel = [
        { field: 'unit', sort: 'asc' },
        { field: 'name', sort: 'asc' }
    ];

    const [sortModel, setSortModel] = useState(initialSortModel);

    const onSortModelChange = model => {
        setSortModel(model);
    };
    const { data: rows, isFetching: loading, hasNextPage, fetchNextPage } = useContacts(building?.id, { sortModel });

    const booleanFormatter = params => params.value ? '✓' : '';

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'unit', headerName: 'Unit', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'phone', headerName: 'Phone', flex: 1 },
        { field: 'card', headerName: 'Card ID', flex: 1 },
        { field: 'code', headerName: 'Code', flex: 1 },
        { field: 'enableIntercom', headerName: 'Intercom', width: 135, valueFormatter: booleanFormatter },
        { field: 'enableVisitor', headerName: 'Visitor', width: 135, valueFormatter: booleanFormatter }
    ];

    const onDelete = toDelete => {
        deleteContacts(toDelete);
    };

    const onAdd = () => {
        history.replace(`${url}/add`);
    };

    const onEdit = toEdit => {
        const selectedContact = rows.find(contact => contact.id === toEdit[0]);
        console.log('editing:', toEdit, selectedContact, rows);
        setSelected(selectedContact);
        history.replace(`${url}/edit`);
    };

    const onClose = () => {
        history.replace(`${url}`);
    };

    const handleUpdate = toUpdate => {
        if (!toUpdate.id) {
            createContact({ ...toUpdate, building: building.id });
        }
        else {
            updateContact(toUpdate);
        }
        onClose();
    };

    const active = adding || updating || loading || deleting;
    const text = adding
        ? 'Adding...'
        : updating ? 'Updating...'
            : deleting ? 'Deleting...'
                : 'Loading...';

    return (
        <>
            <Box height="100%">
                <Loading active={active} spinner text={text}>
                    <Switch>
                        <Route exact path={`${path}`}>
                            <Grid
                                title={'Contacts'}
                                rows={rows}
                                columns={columns}
                                sortingMode={'server'}
                                initialSortModel={initialSortModel}
                                onSortModelChange={onSortModelChange}
                                canFetchMore={hasNextPage}
                                fetchMoreRows={fetchNextPage}
                                multiple={false}
                                onDelete={onDelete}
                                onAdd={onAdd}
                                onEdit={onEdit}>
                            </Grid>
                        </Route>
                        <Route path={`${path}/add`}>
                            <ContactDetail title="Add a New Contact" onCancel={onClose} onSubmit={handleUpdate} />
                        </Route>
                        <Route path={`${path}/edit`}>
                            <ContactDetail title="Edit Contact" contact={selected} onCancel={onClose} onSubmit={handleUpdate} />
                        </Route>
                    </Switch>
                </Loading>
            </Box>

        </>
    );
};

export default BuildingContacts;
