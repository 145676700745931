// eslint-disable-next-line no-unused-vars
import { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';
import Loading from '../components/Loading';

import { PasswordField, ErrorList } from '../components';
import { useInput } from '@domatic/hooks';

import { useChangePassword } from '@domatic/query';

const ChangePassword = ({ onClose }) => {

    const { mutate: changePassword, isLoading: saving, error: updateError } = useChangePassword();

    const current = useInput('', '', 'Current Password');
    const password = useInput('', 'password', 'New Password');
    const passwordCheck = useInput(
        '',
        async value => value !== password.value ? 'passwords must match' : null,
        'Repeat New Password'
    );

    const saveClick = () => changePassword(
        {
            current: current.value,
            password: password.value
        },
        {
            onSuccess: () => onClose(),
            onError: error => console.error(error)
        });

    const changes = current.changed || password.changed || passwordCheck.changed;
    const formValid = changes && current.valid && password.valid && password.value === passwordCheck.value;

    return (
        <Box p={2}>
            <Loading active={saving} spinner text="saving...">
                <PasswordField field={current} autofocus required fullWidth />
                <PasswordField field={password} required fullWidth />
                <PasswordField field={passwordCheck} required fullWidth />

                <Button onClick={saveClick} disabled={!formValid}>Change</Button>
                &nbsp;
                &nbsp;
                <Button onClick={onClose}>Cancel</Button>
                <br />
                <br />
                <ErrorList errors={[updateError]} />
            </Loading >
        </Box>
    );
};

ChangePassword.propTypes = {
    onClose: PropTypes.func
};

ChangePassword.defaultProps = {};

export default ChangePassword;
