import PropTypes from 'prop-types';

import { Button, Typography, Box } from '@material-ui/core';
import ListPicker from './ListPicker';

import { Field } from '../components';
import { useInput, useControlledArray } from '@domatic/hooks';
import { useRoles } from '@domatic/query';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';

const UserDetail = ({ title, user, onSubmit, onCancel }) => {

    const { data: serviceRoles } = useRoles();
    // in case a bogus role is in the record, we'll add it to the selection so we can remove it
    const allRoles = [...new Set(serviceRoles?.concat(user?.roles))];

    const name = useInput(user?.name, 'notEmpty', 'Name');
    const username = useInput(user?.username, 'username', 'User Name');
    const email = useInput(user?.email, 'email', 'Email');
    const roles = useControlledArray(user?.roles || []);

    const formValid = name.valid && username.valid && email.valid;

    const handleSubmit = () => {
        onSubmit({
            id: user?.id,
            name: name.value,
            username: username.value,
            email: email.value,
            roles: roles.value
        });
    };

    return (
        <Box p={2}>
            <Typography>{title}</Typography><br />
            <Field field={name} required fullWidth />
            <Field field={email} required fullWidth />
            <Field field={username} required fullWidth />
            <ListPicker label={'Roles'} items={allRoles} selection={roles.value} onChange={value => roles.onChange(value)} fullWidth />
            <Button color="primary" onClick={handleSubmit} disabled={!formValid} startIcon={user?.id ? <EditIcon /> : <AddIcon />}>{user?.id ? 'Save' : 'Add'}</Button>
            &nbsp;
            &nbsp;
            <Button color="secondary" onClick={onCancel} startIcon={<UndoIcon />}>Cancel</Button>
        </Box>
    );
};

UserDetail.propTypes = {
    user: PropTypes.object,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
};

UserDetail.defaultProps = {};

export default UserDetail;
