import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { green, red, orange, grey, blue } from '@material-ui/core/colors';

const lightPalette = {
    type: 'light',
    primary: green,
    secondary: {
        main: '#ce93d8',
    },
    error: red,
    warning: orange,
    info: grey,
    success: green,
    hotWater: red[900],
    coldWater: blue[900],
    phase0: green[900],
    phase1: blue[900]
};

const darkPalette = {
    type: 'dark',
    primary: green,
    secondary: {
        main: '#ce93d8',
    },
    error: red,
    warning: orange,
    info: grey,
    success: green,
    hotWater: red[200],
    coldWater: blue[200],
    phase0: green[200],
    phase1: blue[200]
};

const overrides = {
    MuiTextField: {
        root: {
            marginBottom: '15px'
        }
    },
    MuiButton: {
        root: {
            color: 'inherit'
        }
    },
    MuiIconButton: {
        root: {
            color: 'inherit'
        }
    },
    MuiTab: {
        wrapper: {
            alignItems: 'self-start',
            justifyContent: 'flex-start'
        }
    }
};

const props = {
    MuiTextField: {
        variant: 'outlined',
    },
    MuiButtonBase: {
        disableRipple: true
    },
    MuiButton: {
        variant: 'contained',
    }
};

const themeName = 'Domatic';

const lightTheme = responsiveFontSizes(createTheme({ palette: lightPalette, overrides, props, themeName }));
const darkTheme = responsiveFontSizes(createTheme({ palette: darkPalette, overrides, props, themeName }));

export { lightTheme, darkTheme }; //, config };
