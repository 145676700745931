import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Grid from '../components/Grid';

import UndoIcon from '@material-ui/icons/Undo';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';

import { useDevice, useHub, useSetDevice } from '@domatic/query';
import { useBuildingCircuits, useCurrentBuilding } from '@domatic/query';

const CircuitDisplay = ({ circuitId }) => {
    const building = useCurrentBuilding();
    const { data: circuits } = useBuildingCircuits(building.id);
    const circuit = useMemo(() => (circuits.find(circuit => circuit.documentId === circuitId) ?? {}), [circuitId, circuits]);

    const { data: hub } = useHub(circuit?.hubId);

    return (<div style={{ margin: 5 }}>
        <div>Circuit name: {circuit?.name}</div>
        <div>Circuit description: {circuit?.description}</div>
        <div>Circuit ID: {circuitId}</div>
        <div>HubId: {circuit.hubId}</div>
        <div>Hub name: {hub?.name}</div>
    </div>);
};

CircuitDisplay.propTypes = {
    circuitId: PropTypes.string.isRequired
};

const HubNameFromId = ({ hubId }) => {
    const { data: hub } = useHub(hubId);
    return (<>{hub?.name} ({hub?.hostname})</>);
};

HubNameFromId.propTypes = {
    hubId: PropTypes.string.isRequired
};

const CircuitNameFromId = ({ circuitId }) => {
    const building = useCurrentBuilding();
    const { data: circuits } = useBuildingCircuits(building.id);
    const circuit = useMemo(() => (circuits.find(circuit => circuit.documentId === circuitId) ?? {}), [circuitId, circuits]);
    return (<>{circuit?.name}</>);
};

CircuitNameFromId.propTypes = {
    circuitId: PropTypes.string.isRequired
};

const CircuitDescriptionFromId = ({ circuitId }) => {
    const building = useCurrentBuilding();
    const { data: circuits } = useBuildingCircuits(building.id);
    const circuit = useMemo(() => (circuits.find(circuit => circuit.documentId === circuitId) ?? {}), [circuitId, circuits]);
    return (<>{circuit?.description}</>);
};

CircuitDescriptionFromId.propTypes = {
    circuitId: PropTypes.string.isRequired
};

const HubNameFromCircuitId = ({ circuitId }) => {
    const building = useCurrentBuilding();
    const { data: circuits } = useBuildingCircuits(building.id);
    const circuit = useMemo(() => (circuits.find(circuit => circuit.documentId === circuitId) ?? {}), [circuitId, circuits]);
    const { data: hub } = useHub(circuit?.hubId);
    return (<>{hub?.name} ({hub?.hostname})</>);
};

HubNameFromCircuitId.propTypes = {
    circuitId: PropTypes.string.isRequired
};

const CircuitSelector = ({ onSelect, exclude }) => {
    const building = useCurrentBuilding();
    const { data: allCircuits } = useBuildingCircuits(building.id);

    const circuits = allCircuits.map(e => ({ ...e, id: e.documentId })).filter(circuit => !exclude?.includes(circuit.documentId));

    const columns = useMemo(() => ([
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 3 },
        { field: 'hubName', headerName: 'Hub Name', flex: 3, renderCell: params => <HubNameFromId hubId={params.row.hubId} /> },
        { field: 'id', headerName: 'ID', flex: 3 }
    ]), []);

    return (<div style={{ height: '50%' }}>
        <Grid
            title={'Circuit Select (from all hubs)'}
            rows={circuits}
            columns={columns}
            onEdit={(toSelect) => onSelect(toSelect[0])}
        ></Grid>
        <div>Select a circuit, then press the &apos;Edit&apos; button to add it to the top of the device&apos;s circuit list</div>
    </div>);
};

CircuitSelector.propTypes = {
    onSelect: PropTypes.func.isRequired,
    exclude: PropTypes.array
};

const DeviceCircuitControls = ({ id, onUp, onDown }) => {
    return (<>
        <Button color="primary" startIcon={<UpIcon />} onClick={() => onUp(id)}></Button>
        <Button color="primary" startIcon={<DownIcon />} onClick={() => onDown(id)}></Button>
    </>);
};

DeviceCircuitControls.propTypes = {
    id: PropTypes.string.isRequired,
    onUp: PropTypes.func.isRequired,
    onDown: PropTypes.func.isRequired
};

const DeviceDetail = ({ onCancel }) => {
    const history = useHistory();
    const deviceId = useMemo(() => history.location.pathname.split('/').pop(), [history.location.pathname]);

    const { data: device } = useDevice(deviceId);
    const { mutate: setDevice } = useSetDevice(device?.hubId);

    const onUp = (id) => {
        const index = device.targets.indexOf(id);
        if (index > 0) {
            device.targets.splice(index, 1);
            device.targets.splice(index - 1, 0, id);
        }
        setDevice({ id: device.documentId, targets: device.targets });
    };

    const onDown = (id) => {
        const index = device.targets.indexOf(id);
        if (index < device.targets.length - 1) {
            device.targets.splice(index, 1);
            device.targets.splice(index + 1, 0, id);
        }
        setDevice({ id: device.documentId, targets: device.targets });
    };

    const onRemove = (id) => {
        const index = device.targets.indexOf(id);
        if (index >= 0) {
            device.targets.splice(index, 1);
        }
        setDevice({ id: device.documentId, targets: device.targets });
    };

    const onAdd = (id) => {
        if (!device.targets.includes(id)) {
            device.targets.unshift(id);
            setDevice({ id: device.documentId, targets: device.targets });
        }
    };

    return (<>
        <div style={{ height: '100%' }}>
            <h1>{device?.description} ({device?.name})</h1>
            <Button color="secondary" onClick={onCancel} startIcon={<UndoIcon />}>Back</Button>
            <div style={{ height: '50%' }}>
                <Grid
                    title={'Device Circuits'}
                    rows={device?.targets?.map(target => ({ ...target, id: target })) ?? []}
                    columns={[
                        { field: 'name', headerName: 'Name', flex: 1, renderCell: params => <CircuitNameFromId circuitId={params.row.id} /> },
                        { field: 'description', headerName: 'Description', flex: 3, renderCell: params => <CircuitDescriptionFromId circuitId={params.row.id} /> },
                        { field: 'hubId', headerName: 'Hub ID', flex: 3, renderCell: params => <HubNameFromCircuitId circuitId={params.row.id} /> },
                        { field: 'id', headerName: 'ID', flex: 3 },
                        { field: 'controls', headerName: 'Controls', flex: 2, renderCell: params => <DeviceCircuitControls id={params.row.id} onUp={onUp} onDown={onDown} /> }
                    ]}
                    onDelete={onRemove}
                ></Grid>
            </div>
            <CircuitSelector onSelect={onAdd} style={{ height: '100%' }} exclude={device?.targets} />
        </div>
    </>);
};

DeviceDetail.propTypes = {
    onCancel: PropTypes.func
};

export default DeviceDetail;
