import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { Center } from '../components';
import { useCardStyles } from '../hooks';
import HomeOrLogin from './HomeOrLogin';

const Validated = () => {
    const classes = useCardStyles();

    const { email } = useParams();

    return (
        <Center>
            <Card className={classes.card}>
                <CardHeader title="You&apos;re a Good Person." />
                <CardContent>
                    <p>The username <strong>{email}</strong> has been validated!</p>
                    <p>You can now proceed to log in.</p>
                </CardContent>
                <CardActions>
                    <HomeOrLogin />
                </CardActions>
            </Card>
        </Center >
    );
};

export default Validated;
