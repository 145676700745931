import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { Box, FormControlLabel, Button, Typography, Checkbox } from '@material-ui/core';
import Loading from '../components/Loading';

import { Field } from '../components';
import CustomSelect from '../components/CustomSelect';
import { useHubSettings, useUpdateSetting } from '@domatic/query';
import { useInput, useCheckbox } from '@domatic/hooks';

import Map from './Map';

const flatlong = (d, isLongitude = false) => {
    if (!d) {
        return 'Nowhere';
    }
    const a = Math.abs(d);
    const degrees = Math.floor(a);
    const minutes = Math.floor((a - degrees) * 60);
    const seconds = Math.floor((a - degrees - minutes / 60) * 3600 * 1000) / 1000;
    const direction = isLongitude ? d < 0 ? 'W' : 'E' : d < 0 ? 'S' : 'N';
    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
};

const fname = n => (n && n.name) ?? 'nowhere';

const allTimeZones = Intl.supportedValuesOf('timeZone');
const validateTimeZone = async value => !allTimeZones.includes(value);

const Location = ({ hub, onError }) => {

    const { data: settings, isLoading: loading } = useHubSettings(hub.id);
    const position = settings?.Position;

    const effective = position?.override?.enabled ? position?.override : position?.detected;

    const override = useCheckbox(!!position?.override?.enabled, 'Override GeoIP');
    const latitude = useInput(position?.override?.latitude, 'number', 'Latitude', hub.name);
    const longitude = useInput(position?.override?.longitude, 'number', 'Longitude', hub.name);
    const name = useInput(position?.override?.name, 'text', 'Place Name', hub.name);
    const timezone = useInput(position?.override?.timezone, validateTimeZone, 'Time Zone', hub.name);

    const changes = override.changed || latitude.changed || longitude.changed || timezone.changed || name.changed;
    const formValid = changes && latitude.valid && longitude.valid && name.valid && timezone.valid;

    const { mutate: updateSetting, isLoading: saving } = useUpdateSetting(hub.id);

    const tzList = useMemo(() => allTimeZones?.map(zone => {
        return {
            name: zone,
            branch: zone,
            id: zone
        };
    }), []);

    const saveClick = event => {
        event.stopPropagation();
        updateSetting({
            __t: 'Position',
            override: {
                enabled: override.checked,
                name: name.value,
                latitude: latitude.value,
                longitude: longitude.value,
                timezone: timezone.value
            }
        }, {
            onError,
            onSuccess: doReset
        });
    };

    const doReset = () => {
        override.reset();
        latitude.reset();
        longitude.reset();
        timezone.reset();
        name.reset();
    };

    return (
        <Box p={2}>
            <Loading active={loading || saving} spinner text="Loading...">
                <Typography>Detected Location (IP: {position?.detected?.ip}): </Typography>
                <Typography>{flatlong(position?.detected?.latitude, false)}, {flatlong(position?.detected?.longitude, true)}</Typography>
                <Typography>{fname(position?.detected)} ({position?.detected?.timezone})</Typography>
                <br />
                <Map latitude={effective?.latitude} longitude={effective?.longitude} />
                <FormControlLabel
                    control={<Checkbox checked={override.checked} onChange={override.onChange} />}
                    label={override.label}
                />
                <Field field={latitude} required fullWidth disabled={!override.checked} />
                <Field field={longitude} required fullWidth disabled={!override.checked} />
                <Field field={name} required fullWidth disabled={!override.checked} />
                <CustomSelect field={timezone} data={tzList} disabled={!override.checked} required fullWidth />
                <br />
                <br />
                <Button onClick={saveClick} color='primary' disabled={!formValid}>Save</Button>
                &nbsp;
                <Button onClick={doReset} color='secondary' disabled={!changes}>Reset</Button>
            </Loading>
        </Box>
    );
};

Location.propTypes = {
    hub: PropTypes.object,
    onError: PropTypes.func
};

export default Location;
