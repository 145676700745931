import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, CardActions } from '@material-ui/core';
import Loading from '../components/Loading';
import { useCardStyles } from '../hooks';
import { useInput } from '@domatic/hooks';
import { Center, Field, ErrorList } from '../components';
import { useAuthenticated, useForgot } from '@domatic/query';

const Forgot = () => {
    const classes = useCardStyles();

    const authenticated = useAuthenticated();

    const username = useInput('', ['emailOrUsername'], 'Username or Email');

    const [requestRedirect, goRequest] = useState(false);
    const [loginRedirect, goLogin] = useState(false);
    const [signupRedirect, goSignup] = useState(false);

    const formValid = username.valid;

    const { mutate: request, isLoading: loading, error: requestError } = useForgot();
    const onClickRequest = () =>
        request(
            { username: username.value },
            { onSuccess: () => goRequest(true) }
        );

    if (authenticated) {
        return <Redirect to='/' />;
    }

    if (requestRedirect) {
        return <Redirect to='/requested' />;
    }

    if (loginRedirect) {
        return <Redirect to='/login' />;
    }

    if (signupRedirect) {
        return <Redirect to='/signup' />;
    }

    return (
        <Center>
            <Card className={classes.card}>
                <Loading active={loading} spinner text="requesting...">
                    <CardHeader title="Account Recovery" />
                    <CardContent>
                        <p>Enter your username or email address to request a password reset.</p>
                        <Field field={username} autoFocus required fullWidth onEnter={onClickRequest} />
                        <ErrorList errors={[requestError]} />
                        <Button color="primary" onClick={onClickRequest} disabled={!formValid}>Request</Button>
                    </CardContent>
                    <CardActions>
                        <Button variant="text" onClick={() => goLogin(true)}>Log In</Button>
                        <Button variant="text" onClick={() => goSignup(true)}>Sign Up</Button>
                    </CardActions>
                </Loading>
            </Card>
        </Center>
    );
};

export default Forgot;
