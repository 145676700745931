import { useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Loading from '../components/Loading';
import Grid from '../components/Grid';
import { Driver } from '@domatic/query';
import DriverDetail from './DriverDetail';

import { Box } from '@material-ui/core';

const Drivers = () => {

    const { path, url } = useRouteMatch();
    const history = useHistory();

    const [selected, setSelected] = useState({});
    const { data: rows, isFetching: loading } = Driver.useAll({ auto: true });
    const { mutate: deleteDriver, isLoading: deleting } = Driver.useDelete();

    // const dateFormatter = params => moment(params.value).calendar();
    // const booleanFormatter = params => params.value ? '✓' : '';
    // const jsonFormatter = params => JSON.stringify(params.value);

    const [columns, setColumns] = useState([
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'identifier', headerName: 'Identifier', flex: 1 },
        { field: 'version', headerName: 'Version', flex: 1 },
        { field: 'revision', headerName: 'Revision', flex: 1 },
        { field: 'code', headerName: 'Code', flex: 1 },
    ]);

    const initialSortModel = [
        { field: 'name', sort: 'asc' }
    ];

    const onHideColumn = (field, val) => {
        setColumns(columns.map(e => {
            if (e.field === field) {
                e.hide = val;
            }
            return e;
        }));
    };

    const onAdd = () => {
        history.replace(`${url}/add`);
    };

    const onDelete = toDelete => {
        const selectedItem = rows.find(item => item.id === toDelete[0]);
        console.log('deleting:', toDelete, selectedItem, rows);
        deleteDriver(selectedItem.id);
    };

    const onEdit = toEdit => {
        const selectedItem = rows.find(item => item.id === toEdit[0]);
        console.log('editing:', toEdit, selectedItem, rows);
        setSelected(selectedItem);
        history.replace(`${url}/${selectedItem.id}/edit`);
    };

    const onClose = () => {
        history.replace(`${url}`);
    };

    const active = loading || deleting;
    const text = loading ? 'Loading...' : 'Deleting...';

    return (
        <Box height="100%">
            <Loading active={active} spinner text={text}>
                <Switch>
                    <Route exact path={`${path}`}>
                        <Grid
                            title={'Drivers'}
                            rows={rows}
                            columns={columns}
                            initialSortModel={initialSortModel}
                            multiple={false}
                            onDelete={onDelete}
                            onAdd={onAdd}
                            onEdit={onEdit}
                            onHideColumn={onHideColumn}
                        >
                        </Grid>
                    </Route>
                    <Route path={`${path}/add`}>
                        <DriverDetail title="Add a New Driver" onClose={onClose} />
                    </Route>
                    <Route path={`${path}/${selected?.id}/edit`}>
                        <DriverDetail title="Edit Driver" driver={selected} onClose={onClose} />
                    </Route>
                </Switch>
            </Loading>
        </Box>
    );
};

export default Drivers;
