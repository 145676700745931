import PropTypes from 'prop-types';

import { Box, FormControlLabel, Switch } from '@material-ui/core';
import Chip from '../components/StatusChip';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';

import { useHubSettings, useUpdateSetting } from '@domatic/query';
import { useCheckbox } from '@domatic/hooks';

import { Vertical, Body } from '../components/Layout';

const Emergency = ({ hub, onError }) => {

    const { data: settings, isLoading: loading } = useHubSettings(hub.id);
    const { mutate: updateSetting, isLoading: saving } = useUpdateSetting(hub.id);

    const testMode = useCheckbox(settings?.Config?.emergencyTestMode, 'Test Emergency Mode');

    const state = settings?.Emergency?.state;
    const detected = settings?.Emergency?.detected;
    const inconsistent = settings?.Emergency?.inconsistent;
    const deficient = settings?.Emergency?.deficient;

    const saveClick = event => {
        event.stopPropagation();
        updateSetting({
            type: 'Config',
            emergencyTestMode: !!event.target.checked
        }, {
            onError
        });
    };

    return (
        <Vertical p={2}>
            <Box display="flex" flexDirection="row">
                {state ? (
                    <Chip
                        icon={<ErrorIcon />}
                        label="Power Fail"
                        color="secondary"
                    />
                ) : (
                    <Chip
                        icon={<CheckIcon />}
                        label="Power OK"
                        color="primary"
                    />
                )}
                {!detected &&
                    <Chip
                        icon={<ErrorIcon />}
                        label="No Sensors Detected"
                        color="secondary"
                    />
                }
                {detected && deficient &&
                    <Chip
                        icon={<ErrorIcon />}
                        label="Some Sensors Missing"
                        color="secondary"
                    />
                }
                {inconsistent &&
                    <Chip
                        icon={<WarningIcon />}
                        label="Inconsistent Readings"
                        color="secondary"
                    />
                }
            </Box>
            <Body p={2}>
                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}

                    control={<Switch
                        disabled={saving || loading}
                        checked={testMode.checked}
                        onChange={saveClick}
                        onFocus={(event) => event.stopPropagation()}
                    />}
                    label="Test Emergency Mode"
                />
            </Body>
        </Vertical>
    );
};

Emergency.propTypes = {
    hub: PropTypes.object,
    onError: PropTypes.func
};

export default Emergency;
