// import axios from 'axios';
import { useMutation } from './queryClient';

const postPrint = host => data => {
    return fetch(`${window.location.protocol}//${host}/pstprnt`, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Length': data.length
        },
        body: data
    });
};
//     return axios.post(`http://${ip}/pstprnt`, data, {
//         headers: {
//             'Access-Control-Allow-Origin': true,
//             'Content-length': data.length
//         }
//     });
// };

export const usePrint = ip => useMutation(postPrint(ip));
