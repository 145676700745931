import { node } from 'prop-types';

const Center = props => (
    <div
        style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }}
    >
        {props.children}
    </div>
);

Center.propTypes = {
    children: node
};

export default Center;
