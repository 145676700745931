import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const Field = props => {
    const onChange = event => {
        props.field.onChange(event);
        props.onChange && props.onChange(event);
    };

    return (
        <TextField
            id={props.field.name}
            name={props.field.name}
            label={props.field.label}
            type={props.password ? 'password' : 'text'}
            autoComplete={props.password && (props.current ? 'current-password' : 'new-password')}
            autoFocus={props.autoFocus}
            required={props.required}
            fullWidth={props.fullWidth}
            value={props.field.value}
            error={props.field.error}
            helperText={props.field.helperText || props.helperText || ' '}
            onChange={onChange}
            disabled={props.disabled}
            onClick={props.onClick}
            onKeyPress={event => {
                if (props.onEnter && (event.key === 'Enter')) {
                    props.onEnter();
                    event.preventDefault();
                }
                if (props.constraints && !event.key.match(props.constraints)) {
                    event.preventDefault();
                }
            }}
            multiline={!!props.multiline}
        />
    );
};

Field.propTypes = {
    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    field: PropTypes.object,
    password: PropTypes.bool,
    current: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onEnter: PropTypes.func,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    constraints: PropTypes.string,
    multiline: PropTypes.bool
};

export default Field;
