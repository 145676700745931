import { Box, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { Center } from '../components';
import { useCardStyles } from '../hooks';
import HomeOrLogin from './HomeOrLogin';

const Requested = () => {
    const classes = useCardStyles();

    return (
        <Center>
            <Box mx="auto">
                <Card className={classes.card}>
                    <CardHeader title="Reset Requested" />
                    <CardContent>
                        <p>A link has been sent to your email address.</p>
                        <p>Go click on it.</p>
                    </CardContent>
                    <CardActions>
                        <HomeOrLogin />
                    </CardActions>
                </Card>
            </Box>
        </Center>
    );
};

export default Requested;
