const _extract = (o, field, options) => {
    if (options && options.caseInsensitive)
        return o[field].toLowerCase();
    return o[field];
};

/**
 * Compare two fields of objects.
 * @param {String} field - The field to compare.
 * @param {Object} options - Options for comparison.
 * @param {Boolean} options.reverse - True to sort in reverse.
 * @param {Boolean} options.caseInsensitive - True to compare case-insensitively.
 * @returns {Number} - The comparison result.
 */
module.exports.compareFields = (field, options) => (a, b) => {
    const ret = options && options.reverse ? -1 : 1;
    const aValue = _extract(a, field, options);
    const bValue = _extract(b, field, options);
    if (aValue < bValue) return -ret;
    if (aValue > bValue) return ret;
    return 0;
};

const fieldComparator = (a, b, sortBy, reverse) => {
    if (b[sortBy] < a[sortBy]) {
        return reverse ? 1 : -1;
    }
    if (b[sortBy] > a[sortBy]) {
        return reverse ? -1 : 1;
    }
    return 0;
};

/**
 * Sort an array of objects by a field; don't change the order of equal elements.
 * @param {Array} array - The array to sort.
 * @param {String} sortBy - The field to sort by.
 * @param {Boolean} reverse - True to sort in reverse.
 * @returns {Array} - The sorted array.
 */
module.exports.stableSort = (array, sortBy, reverse = false) => {
    const comparator = (a, b) => fieldComparator(a, b, sortBy, reverse);
    const withIndexes = array.map((el, index) => [el, index]);
    withIndexes.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return withIndexes.map((el) => el[0]);
};

const _compare = (a, b, sort) => {
    let result = { compare: 0, hit: false };
    for (const [key, direction] of Object.entries(sort)) {
        if (a[key] < b[key]) {
            result.compare = -direction;
            break;
        }
        if (a[key] > b[key]) {
            result.compare = direction;
            break;
        }
    }
    if (a.id === b.id) {
        result.compare = 0;
        result.hit = true;
    }
    return result;
};

const _findOnPage = (data, document, sort) => {
    let a = 0;
    let b = data.length - 1;
    while (a <= b) {
        const c = Math.floor((a + b) / 2);
        const result = _compare(document, data[c], sort);
        if (result.hit) {
            return { index: c, hit: true };
        }
        if (result.compare > 0) {
            a = c + 1;
        }
        else {
            b = c - 1;
        }
    }
    return { index: a, hit: false };
};
module.exports.findInArray = _findOnPage;
/**
 * Find the index of a document in a sorted, paged array of documents.
 * @param {Array} pages - The array of pages.
 * @param {Object} document - The document to find.
 * @param {Object} sort - The sort order (mongo syntax)
 * @returns {Object} - The page, index, and hit status.
 */
module.exports.mongoFind = (pages, document, sort) => {
    let a = 0;
    let b = pages.length - 1;
    while (a <= b) {
        const c = Math.floor((a + b) / 2);
        const page = pages[c];
        if (page.data.length === 0) {
            b = c - 1;
            continue;
        }
        const first = _compare(document, page.data[0], sort);
        if (first.compare < 0) {
            b = c - 1;
            continue;
        }
        if (first.hit) {
            return { page: c, index: 0, hit: true };
        }
        const last = _compare(document, page.data[page.data.length - 1], sort);
        if (last.compare > 0) {
            a = c + 1;
            continue;
        }
        if (last.hit) {
            return { page: c, index: page.data.length - 1, hit: true };
        }
        a = c;
        break;
    }
    if (a >= pages.length) {
        return { page: -1, index: -1, hit: false };
    }
    if (a === 0 && pages[a].data.length === 0) {
        return { page: 0, index: 0, hit: false };
    }
    const { index, hit } = _findOnPage(pages[a].data, document, sort);
    return { page: a, index, hit };
};
