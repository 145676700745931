// eslint-disable-next-line no-unused-vars
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Typography, IconButton, Avatar } from '@material-ui/core';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import Loading from '../components/Loading';
import JsGravatar from 'js-gravatar';

import { Field } from '../components';
import { useInput } from '@domatic/hooks';
import { ErrorList } from '../components';

import DeleteIcon from '@material-ui/icons/Delete';
import MailIcon from '@material-ui/icons/Mail';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { useInvitations, useSendInvitation, useResendInvitation, useDeleteInvitation } from '@domatic/query';

import moment from 'moment';

const OrganizationInvites = ({ organization }) => {

    const email = useInput('', 'email', 'Enter Email');

    const { data: invitations } = useInvitations(organization?.id);

    const { mutate: sendInvitation, isLoading: sending, error: invitationError } = useSendInvitation(organization?.id);
    const { mutate: resendInvitation, isLoading: resending, error: resendError } = useResendInvitation(organization?.id);
    const { mutate: deleteInvitation, isLoading: deleting, error: deleteError } = useDeleteInvitation(organization?.id);

    const [resends, setResends] = useState([]);

    const sendInvitationClick = async () => {
        console.log('sending invitation to', email.value);
        sendInvitation({
            email: email.value,
            organization: organization?.id
        }, {
            onSuccess: () => email.reset()
        });
    };

    const resendInvitationClick = id => {
        console.log('resending invitation', id);
        resendInvitation(
            { id },
            { onSuccess: () => setResends([...resends, id]) }
        );
    };

    const deleteInvitationClick = id => {
        console.log('deleting invitation', id);
        return deleteInvitation(id);
    };

    const inviteValid = email.changed && email.valid;

    return (
        <Loading active={deleting} spinner text="Removing...">
            <Loading active={sending || resending} spinner text="Sending...">
                <Box p={2}>
                    <Typography>Invite a team member</Typography><br />
                    <Field field={email} required fullWidth />
                    <Button color="primary" onClick={sendInvitationClick} disabled={!inviteValid} startIcon={<PersonAddIcon />}>Invite</Button>
                    <Table>
                        <TableBody>
                            {invitations?.map(({ id, invited, email }, i) => (
                                <TableRow key={i}>
                                    <TableCell>
                                        <Avatar
                                            style={{
                                                width: 40,
                                                height: 40,
                                                transition: '0.3s',
                                            }}
                                            src={JsGravatar({ email: email, defaultImage: 'identicon' })}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {email}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Invited {moment(invited).calendar()}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => deleteInvitationClick(id)}
                                            aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => resendInvitationClick(id)}
                                            aria-label="resend">
                                            <MailIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {resends.indexOf(id) >= 0 && 'Invitation re-sent'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <ErrorList errors={[invitationError, resendError, deleteError]} />
                </Box>
            </Loading >
        </Loading >
    );
};

OrganizationInvites.propTypes = {
    organization: PropTypes.object,
};

export default OrganizationInvites;
