import { useCallback } from 'react';
import { useUncapturedHubs, useCurrentBuilding, useUpdateHub } from '@domatic/query';
// import moment from 'moment';
import Grid from '../components/Grid';
import { Box } from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';

const StrayHubs = () => {

    const currentBuilding = useCurrentBuilding();
    const { data: rows } = useUncapturedHubs();

    const booleanFormatter = params => params.value ? '✓' : '';
    // const dateFormatter = params => moment(params.value).calendar();
    const dateFormatter = params => new Date(params.value).toLocaleString('en-us', { dateStyle: 'short', timeStyle: 'short' });
    const { mutate: updateHub } = useUpdateHub();

    const columns = [
        { field: 'active', headerName: 'Active', width: 130, valueFormatter: booleanFormatter },
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'hostname', headerName: 'Hostname', flex: 2 },
        { field: 'serial', headerName: 'Serial', width: 130 },
        { field: 'created', type: 'date', headerName: 'Created', width: 175, valueFormatter: dateFormatter }
    ];

    const initialSortModel = [
        { field: 'active', sort: 'asc' },
        { field: 'hostname', sort: 'asc' }
    ];

    const capture = useCallback(selection => {
        const id = selection[0];
        console.log('capture:', id);
        updateHub({
            id,
            building: currentBuilding?.id
        });
    }, [currentBuilding?.id, updateHub]);

    const actions = [{
        title: `Capture to building: ${currentBuilding?.name}`,
        icon: <ApartmentIcon />,
        action: capture
    }];

    return (
        <Box height="100%">
            <Grid
                title={'Uncaptured Hubs'}
                rows={rows}
                columns={columns}
                actions={actions}
                initialSortModel={initialSortModel}
                multiple={false}
            >
            </Grid>
        </Box>
    );
};

StrayHubs.propTypes = {};

StrayHubs.defaultProps = {};

export default StrayHubs;
