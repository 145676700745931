import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import Loading from '../components/Loading';

import { useInput, useCheckbox } from '@domatic/hooks';
import { useGroups, useBuilds, useBranches, useUpdateHub } from '@domatic/query';
import UpdateStatus from './UpdateStatus.js';
import CustomSelect from '../components/CustomSelect';
import { ErrorList } from '../components';

const Update = ({ hub }) => {

    const group = useInput(hub.group, '', 'Group');
    const manual = useCheckbox(hub.manual, 'Manual Update');
    const branch = useInput(hub.branch, '', 'Branch');
    const build = useInput(hub.version, '', 'Version');
    const backup = useInput('', '', 'Backup');

    const { data: groups } = useGroups();
    const { data: branches } = useBranches();
    const { data: builds } = useBuilds(branch.value);
    const { mutate: updateHub, isLoading: saving, error: updateError } = useUpdateHub();

    const groupList = useMemo(() => groups?.map(group => ({
        name: group.name,
        id: group.name
    })), [groups]);

    const branchList = useMemo(() => branches?.map(branch => {
        return {
            name: branch.name,
            branch: branch.name,
            id: branch.name
        };
    }), [branches]);

    const buildList = useMemo(() => builds?.map(build => {
        const branch = build.branch;
        const id = String(build.version);
        const name = String(build.version);
        const version = String(build.version);
        return { id, name, version, branch };
    }), [builds]);

    const changed = (manual.changed || branch.changed || build.changed || backup.changed);
    const valid = (branch.valid && build.valid && backup.valid);
    const enableSubmit = changed && valid;

    const doUpdate = useCallback((hub) => {
        updateHub(hub, {
            onSuccess: () => {
                group.reset();
                build.reset();
            }
        });
    }, [updateHub, group, build]);

    return (
        <Box p={2} width="100%">
            <Loading active={saving} spinner text="Saving...">
                <UpdateStatus hubId={hub.id} />
                <Typography variant="body">Current version: {hub.version} ({hub.branch})</Typography>
                <br />
                <br />
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <CustomSelect field={group} data={groupList} required fullWidth />
                    &nbsp;&nbsp;
                    <Button onClick={() => doUpdate({ id: hub.id, group: group.value })} color='primary' disabled={!group.changed}>Set Group</Button>
                </Box>
                <br />
                <FormControlLabel
                    control={<Checkbox checked={manual.checked} onChange={manual.onChange} />}
                    label="Manual Override"
                />
                <br />
                <br />
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <CustomSelect field={branch} data={branchList} disabled={!manual.checked} required fullWidth />
                </Box>
                <br />
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <CustomSelect field={build} data={buildList} disabled={!manual.checked} required fullWidth />
                </Box>
                <br />
                <Button onClick={() => doUpdate({
                    id: hub.id,
                    branch: branch.value,
                    version: build.value,
                    restore: backup.value?.slug,
                    manual: manual.checked,
                })} color='primary' disabled={!enableSubmit}>Save</Button>
                <br />
                <br />
                <ErrorList errors={[updateError]} />
            </Loading>
        </Box>
    );
};

Update.propTypes = {
    hub: PropTypes.object
};

export default Update;
