import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useCircuits } from '@domatic/query';

const UnattachedCircuit = ({ fault }) => {

    const hubId = fault?.hubId;
    const { data: circuits } = useCircuits(hubId);
    const circuitId = fault?.circuitId;
    const circuit = circuits?.find(circuit => circuit.id === circuitId);

    return (
        <>
            <Typography>{circuit?.__t}: {circuit?.name} ({circuit?.description})</Typography>
            <Typography>ID: {circuitId ?? 'Unknown'}</Typography>
        </>
    );
};

UnattachedCircuit.propTypes = {
    fault: PropTypes.object
};

UnattachedCircuit.defaultProps = {};

export default UnattachedCircuit;
