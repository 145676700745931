import { Box, Button, Typography } from '@material-ui/core';

import Loading from '../components/Loading';

import { useInput } from '@domatic/hooks';
import { Field, ErrorList } from '../components';

import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';

import { useCurrentBuilding, useUpdateBuilding } from '@domatic/query';

const { DateTime } = require('luxon');

const BuildingDetail = () => {

    const building = useCurrentBuilding();

    const name = useInput(building?.name, 'notEmpty', 'Name');
    const address = useInput(building?.address, 'notEmpty', 'Address');

    const { mutate: updateBuilding, isLoading: saving, error: updateError } = useUpdateBuilding();

    const doSave = () => updateBuilding({
        id: building.id,
        name: name.value,
        address: address.value
    });

    const doReset = () => {
        name.reset();
        address.reset();
    };

    const changes = name.changed || address.changed;
    const formValid = changes && name.valid && address.valid;

    const createdMessage = building?.created ? DateTime.fromMillis(building.created).toRelative() : 'unknown';

    return (
        <Box p={2}>
            <Loading active={saving} spinner text="Saving...">
                <Field field={name} required fullWidth />
                <Field field={address} required fullWidth />
                <ErrorList errors={[updateError]} />
                <Typography>Created: {createdMessage}</Typography><br />
                <Button color="primary" onClick={doSave} disabled={!formValid} startIcon={<SaveIcon />}>Save</Button>
                &nbsp;
                <Button color="secondary" onClick={doReset} disabled={!changes} startIcon={<UndoIcon />}>Reset</Button>
            </Loading>
        </Box >
    );
};

export default BuildingDetail;
