import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import { Button, Box } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { Field, CustomSelect } from '../components';
import { useInput } from '@domatic/hooks';
import { usePrint } from '@domatic/query';

import QRCode from 'qrcode';
import { getZPL } from './zebra';

const pageOptions = [[2.25, 0.75], [2.5, 0.75], [3, 0.75], [2, 1], [2.5, 1], [3, 1], [3.5, 1]].map(
    ([width, height], index) => ({ id: index, name: `${width}" x ${height}"`, width, height })
);

const Label = ({ uuid, label }) => {

    const page = useInput(0, 'notEmpty', 'Label Size');
    const selectedPage = pageOptions[page.value];

    const quantity = useInput(1, 'number', 'Quantity');

    const width = selectedPage?.width || 1;
    const height = selectedPage?.height || 1;

    const dpi = 300;
    const pixelWidth = width * dpi;
    const pixelHeight = height * dpi;

    const canvasRef = useRef();

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            ctx.clearRect(0, 0, pixelWidth, pixelHeight);
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, pixelWidth, pixelHeight);
            const qrsize = pixelHeight * 0.9;
            var image = new Image();
            const offset = (pixelHeight - qrsize) / 2;
            image.onload = () => {
                ctx.drawImage(image, offset, offset);
            };
            QRCode.toDataURL(uuid || 'loading', { width: qrsize, height: qrsize }).then(url => image.src = url);
            console.log('foo');

            const fontSize = pixelHeight / 8;
            const textLeft = pixelHeight * 1.0;
            const textHeight = pixelHeight * 0.7;
            const textTop = (pixelHeight - textHeight) / 2 + fontSize;
            ctx.font = `${fontSize}px Arial`;
            ctx.fillStyle = '#000000';
            const lines = label.split('\n');
            lines.forEach((line, index) => {
                ctx.fillText(line, textLeft, textTop + (index * (textHeight / lines.length)));
            });
        }
    }, [canvasRef, label, pixelHeight, pixelWidth, uuid]);

    const host = useInput(`zebra.${window.location.hostname}`, 'notEmpty', 'Printer Host/IP');

    const { mutate: print, error } = usePrint(host.value);

    const fields = [host, quantity, page];
    const formValid = fields.every(f => f.valid);

    const handlePrint = async () => {
        const zpl = getZPL(canvasRef.current, { width, height, dpi, quantity: quantity.value });
        print(zpl, {
            onSuccess: () => console.log('Printed'),
            onError: () => console.error('Print Error', error)
        });
    };

    const handlePreflight = async () => {
        window.open(`https://${host.value}`, '_blank');
    };

    return (
        <Box>
            <canvas
                ref={canvasRef}
                width={pixelWidth}
                height={pixelHeight}
                style={{ border: '2px solid white', background: 'white' }}
            />
            <br />
            <br />
            <Box display="flex" alignItems="baseline">
                <CustomSelect field={page} required data={pageOptions} />
                &nbsp;&nbsp;&nbsp;
                <Field field={host} required fullWidth />
                &nbsp;&nbsp;&nbsp;
                <Button color="secondary" onClick={handlePreflight} disabled={!host.valid} startIcon={<OpenInNewIcon />}>Pre-Flight</Button>
                &nbsp;&nbsp;&nbsp;
                <Field field={quantity} required />
                &nbsp;&nbsp;&nbsp;
                <Button color="primary" onClick={handlePrint} disabled={!formValid} startIcon={<PrintIcon />}>Print</Button>
            </Box>
        </Box >
    );
};

Label.propTypes = {
    uuid: PropTypes.string,
    label: PropTypes.string
};

Label.defaultProps = {};

export default Label;
