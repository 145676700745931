import { Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { Center } from '../components';
import { useCardStyles } from '../hooks';
import HomeOrLogin from './HomeOrLogin';

const Resetted = () => {
    const classes = useCardStyles();

    return (
        <Center>
            <Card className={classes.card}>
                <CardHeader title="Success." />
                <CardContent>
                    <p>Your password has been successfully reset.</p>
                    <p>Now, don&apos;t do it again.</p>
                </CardContent>
                <CardActions>
                    <HomeOrLogin />
                </CardActions>
            </Card>
        </Center>
    );
};

export default Resetted;
