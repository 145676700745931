import { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, CardActions } from '@material-ui/core';
import Loading from '../components/Loading';
import { useCardStyles } from '../hooks';
import { Center, ErrorList } from '../components';
import { useAuthenticated } from '@domatic/query';
import { useResend } from '@domatic/query';

const Invalid = () => {
    const classes = useCardStyles();

    const authenticated = useAuthenticated();

    const [validatingRedirect, goValidating] = useState(false);
    const [loginRedirect, goLogin] = useState(false);
    const [signupRedirect, goSignup] = useState(false);

    const { username } = useParams();

    const { mutate: resend, isLoading: loading, error: resendError } = useResend();

    const resendClick = () => resend(
        { username },
        { onSuccess: () => goValidating(true) }
    );

    if (authenticated) {
        return <Redirect to='/' />;
    }

    if (loginRedirect) {
        return <Redirect to='/login' />;
    }

    if (signupRedirect) {
        return <Redirect to='/signup' />;
    }

    if (validatingRedirect) {
        return <Redirect to='/validating' />;
    }

    return (
        <Center>
            <Card className={classes.card}>
                <Loading active={loading} spinner text="requesting...">
                    <CardHeader title="User Not Validated" />
                    <CardContent>
                        <p>User <strong>{username}</strong> has not been validated.  If you can&apos;t find the validation email we sent, click <strong>RE-SEND</strong> and we&apos;ll send another.</p>
                        <Button color="primary" onClick={resendClick}>Re-send</Button>
                        <ErrorList errors={[resendError]} />

                    </CardContent>
                    <CardActions>
                        <Button variant="text" onClick={() => goLogin(true)}>Log In</Button>
                        <Button variant="text" onClick={() => goSignup(true)}>Sign Up</Button>
                    </CardActions>
                </Loading>
            </Card>
        </Center >
    );
};

export default Invalid;
