import PropTypes from 'prop-types';

import { Button, Typography, Box } from '@material-ui/core';

import { Field, CustomSelect } from '../components';

import { useInput } from '@domatic/hooks';
import { Fixture, Driver } from '@domatic/query';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';
import BuildIcon from '@material-ui/icons/Build';
import isValidJSON from '../common/isValidJSON';

import { v4 as uuidv4 } from 'uuid';

import Label from './Label';

const FixtureDetail = ({ title, fixture, onClose }) => {

    const { data: drivers } = Driver.useAll({ auto: true });
    const driverOptions = drivers.map(driver => ({ id: driver.identifier, name: driver.name }));
    const isOneOf = options => async value =>
        options.every(item => item.id !== value) ? 'make selection' : null;

    const id = fixture?.id;

    const reference = useInput(fixture?.reference, 'notEmpty', 'Reference');
    const description = useInput(fixture?.description, 'notEmpty', 'Description');
    const driver = useInput(fixture?.driver, isOneOf(driverOptions), 'Driver Type');
    const fixtureId = useInput(fixture?.fixtureId, 'uuid', 'Fixture ID');
    const manufacturer = useInput(fixture?.manufacturer, 'notEmpty', 'Manufacturer');
    const model = useInput(fixture?.model, 'notEmpty', 'Model');
    const config = useInput(JSON.stringify(fixture?.config || {}, null, 4), 'json', 'Configuration');

    const fields = [reference, driver, fixtureId, manufacturer, model, config];
    const formValid = fields.every(f => f.valid);

    const { mutate: updateFixture, error: updateError } = Fixture.useUpdate();
    const { mutate: createFixture, error: addError } = Fixture.useCreate();

    const label = `${reference.value}\n${description.value}\n${manufacturer.value}\n${model.value}`;
    const uuid = fixtureId.value;

    const updateField = (field, setField, cfg) => {
        const value = cfg[field];
        if (value) {
            setField(value);
        }
    };

    const onChangeConfig = event => {
        if (isValidJSON(event.target.value)) {
            const cfg = JSON.parse(event.target.value);
            updateField('fixtureId', fixtureId.setValue, cfg);
            updateField('description', description.setValue, cfg);
            updateField('manufacturer', manufacturer.setValue, cfg);
            updateField('model', model.setValue, cfg);
            updateField('reference', reference.setValue, cfg);
            updateField('driver', driver.setValue, cfg);
        }
    };

    const onChangeField = (field) => event => {
        if (isValidJSON(config.value)) {
            config.setValue(JSON.stringify(
                { ...JSON.parse(config.value), [field]: event.target.value },
                null,
                4
            ));
        }
    };

    const handleUpdate = () => {
        updateFixture({
            id: fixture?.id,
            reference: reference.value,
            description: description.value,
            driver: driver.value,
            fixtureId: fixtureId.value,
            manufacturer: manufacturer.value,
            model: model.value,
            config: JSON.parse(config.value)
        }, {
            onSuccess: onClose
        });
    };

    const handleCreate = () => {
        createFixture({
            reference: reference.value,
            driver: driver.value,
            fixtureId: fixtureId.value,
            manufacturer: manufacturer.value,
            model: model.value,
            config: JSON.parse(config.value)
        }, {
            onSuccess: onClose
        });
    };

    return (
        <Box p={2}>
            <Typography>{title}</Typography><br />
            <Field field={reference} required fullWidth onChange={onChangeField('reference')} />
            <Box display="flex" alignItems="baseline">

                <Field field={fixtureId} required fullWidth onChange={onChangeField('fixtureId')} />
                &nbsp;&nbsp;&nbsp;
                <Button color="secondary" startIcon={<BuildIcon />} onClick={() => {
                    const uuid = uuidv4();
                    fixtureId.setValue(uuid);
                    onChangeField('fixtureId')({ target: { value: uuid } });
                }}
                >Generate</Button>
            </Box>

            <CustomSelect field={driver} data={driverOptions} required fullWidth onChange={onChangeField('driver')} />
            <br />
            <br />
            <br />
            <Field field={description} required fullWidth onChange={onChangeField('description')} />
            <Field field={manufacturer} required fullWidth onChange={onChangeField('manufacturer')} />
            <Field field={model} required fullWidth onChange={onChangeField('model')} />
            <Field field={config} required multiline fullWidth onChange={onChangeConfig} />
            <br />

            <Button color="primary" onClick={id ? handleUpdate : handleCreate} disabled={!formValid} startIcon={id ? <EditIcon /> : <AddIcon />}>{id ? 'Save' : 'Add'}</Button>
            &nbsp;&nbsp;
            <Button color="secondary" onClick={onClose} startIcon={<UndoIcon />}>Cancel</Button>
            <Typography>{updateError || addError}</Typography><br />

            <Label uuid={uuid} label={label} width={2} height={1} />

        </Box >
    );
};

FixtureDetail.propTypes = {
    title: PropTypes.string,
    fixture: PropTypes.object,
    onClose: PropTypes.func
};

FixtureDetail.defaultProps = {};

export default FixtureDetail;
