import { useMemo } from 'react';
import { useCurrentBuilding, useBuildingDeviceSummary } from '@domatic/query';
import { Box, Typography } from '@material-ui/core';
import { BarChart, BarSeries, Bar } from 'reaviz';

const BuildingDeviceSummary = () => {

    const building = useCurrentBuilding();
    const { data: info } = useBuildingDeviceSummary(building?.id);
    const keys = Object.keys(info ?? {});

    const datasets = useMemo(() => {
        const ds = {};
        const foo = info ?? {};
        for (const k in foo) {
            ds[k] = [];
            for (const v in info[k]) {
                ds[k].push({ key: v, data: info[k][v] });
            }
        }
        return ds;
    }, [info]);

    return (
        <Box p={2} width="100%">
            <Typography variant="h6">Building Device Statistics</Typography>
            {keys.map((k, i) => (
                <Box key={i}>
                    <Typography variant="h6">{k}</Typography>
                    <BarChart width={600} height={300} data={datasets[k]} series={<BarSeries colorScheme="cybertron" padding={0.1} bar={<Bar />} />} /><br />
                </Box>
            ))}
        </Box >

    );
};

export default BuildingDeviceSummary;
