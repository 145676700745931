import TabNav from '../components/TabNav';

import Faults from './faults';
import ButtonPresses from './activity/ButtonPresses';
import WaterMeters from './meters/WaterMeters';
import PowerMeters from './meters/PowerMeters';
import ButtonActivity from './activity/ButtonActivity';

const Management = () => {
    return (
        <TabNav tabs={[
            { label: 'Faults', route: 'faults', component: <Faults /> },
            { label: 'Buttons', route: 'buttons', component: <ButtonPresses /> },
            { label: 'Activity', route: 'activity', component: <ButtonActivity /> },
            { label: 'Water', route: 'water', component: <WaterMeters /> },
            { label: 'Power', route: 'power', component: <PowerMeters /> }
        ]} />
    );
};

export default Management;
