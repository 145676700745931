import { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Button, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { Center } from '../components';
import { useCardStyles } from '../hooks';
import { useAuthenticated } from '@domatic/query';

const UnknownUser = () => {
    const classes = useCardStyles();

    const authenticated = useAuthenticated();
    const [signup, setSignup] = useState(false);

    const { email } = useParams();

    if (authenticated) {
        return <Redirect to='/' />;
    }

    if (signup) {
        return <Redirect to='/signup' />;
    }

    return (
        <Center>
            <Card className={classes.card}>
                <CardHeader title={`Hi, ${email}`} />
                <CardContent>
                    <p>You don&apos;t have an account yet.</p>
                    <p>You need to sign up before you can accept this invitation.</p>
                </CardContent>
                <CardActions>
                    <Button color="primary" onClick={() => setSignup(true)}>Sign Up</Button>
                </CardActions>
            </Card>
        </Center>
    );
};

export default UnknownUser;
