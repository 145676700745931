import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Header = ({ p, m, px, py, mx, my, children }) => (
    <Box
        flexGrow={0}
        p={p}
        px={px}
        py={py}
        m={m}
        mx={mx}
        my={my}
    >
        {children}
    </Box>
);

Header.propTypes = {
    children: PropTypes.node,
    p: PropTypes.node,
    px: PropTypes.node,
    py: PropTypes.node,
    m: PropTypes.node,
    mx: PropTypes.node,
    my: PropTypes.node
};

export default Header;
