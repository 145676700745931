import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { css, cx } from '@emotion/css';

import Spinner from './Spinner';
import STYLES from './styles';

const LoadingOverlayWrapper = props => {

    const wrapper = useRef();
    const [overflowCSS, setOverflowCSS] = useState({});

    useEffect(() => {
        const wrapperStyle = window.getComputedStyle(wrapper.current);
        setOverflowCSS(['overflow', 'overflowX', 'overflowY'].reduce((m, i) => {
            if (wrapperStyle[i] !== 'visible') m[i] = 'hidden';
            return m;
        }, {}));
    }, []);

    const {
        children,
        className,
        onClick,
        active,
        fadeSpeed,
        spinner,
        text
    } = props;

    const anyActive = active;
    const activeText = text;
    if (anyActive && wrapper?.current) wrapper.current.scrollTop = 0;

    /**
     * Return an emotion css object for a given element key
     * If a custom style was provided via props, run it with
     * the base css obj.
     */
    const getStyles = (key, providedState) => {
        const base = STYLES[key](providedState, props);
        const custom = props.styles[key];
        if (!custom) return base;
        return typeof custom === 'function'
            ? custom(base, props)
            : custom;
    };
    /**
       * Convenience cx wrapper to add prefix classes to each of the child
       * elements for styling purposes.
       */
    const mycx = (names, ...args) => {
        const arr = Array.isArray(names) ? names : [names];
        return cx(
            ...arr.map(name => name ? `${props.classNamePrefix}${name}` : ''),
            ...args
        );
    };

    return (
        <div
            data-testid='wrapper'
            ref={wrapper}
            className={
                mycx(
                    ['wrapper', anyActive && 'wrapper--active'],
                    css(getStyles('wrapper', anyActive ? overflowCSS : {})),
                    className
                )
            }
        >
            <CSSTransition
                in={anyActive}
                classNames='_loading-overlay-transition'
                timeout={fadeSpeed}
                unmountOnExit
            >
                {state => (
                    <div
                        data-testid='overlay'
                        className={mycx('overlay', css(getStyles('overlay', state)))}
                        onClick={onClick}
                    >
                        <div className={mycx('content', css(getStyles('content')))}>
                            {spinner && (
                                typeof spinner === 'boolean'
                                    ? <Spinner cx={mycx} getStyles={getStyles} />
                                    : spinner
                            )}
                            {activeText}
                        </div>
                    </div>
                )}
            </CSSTransition>
            {children}
        </div>
    );
};

LoadingOverlayWrapper.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    fadeSpeed: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    spinner: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    text: PropTypes.node,
    styles: PropTypes.shape({
        content: PropTypes.func,
        overlay: PropTypes.func,
        spinner: PropTypes.func,
        wrapper: PropTypes.object
    })
};

LoadingOverlayWrapper.defaultProps = {
    classNamePrefix: '_loading_overlay_',
    fadeSpeed: 500,
    styles: {}
};

export default LoadingOverlayWrapper;
