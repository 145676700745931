import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useAuthenticated } from '@domatic/query';

const HomeOrLogin = () => {

    const authenticated = useAuthenticated();
    const [login, setLogin] = useState(false);
    const [home, setHome] = useState(false);

    if (home) {
        return <Redirect to='/' />;
    }

    if (login) {
        return <Redirect to='/login' />;
    }

    if (authenticated) {
        return (<Button color="primary" onClick={() => setHome(true)}>Home</Button>);
    }
    return (< Button color="primary" onClick={() => setLogin(true)}>Log In</Button>);
};

export default HomeOrLogin;
