import PropTypes from 'prop-types';
import { Box, Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import Loading from '../components/Loading';
import Field from '../components/Field';

import { useInput, useCheckbox } from '@domatic/hooks';
import { useHubSettings, useUpdateSetting } from '@domatic/query';

import CustomSelect from '../components/CustomSelect';
import { ErrorList } from '../components';

const hardwareList = [
    { name: 'GL-AR750S', id: 'ar750s' },
    { name: 'None', id: 'none' }
];

const Wifi = ({ hub }) => {

    const { data: settings, isLoading: loading } = useHubSettings(hub.id);
    const { mutate: updateSetting, isLoading: saving, error: saveError } = useUpdateSetting(hub.id);

    const provisioning = settings?.Provisioning;

    const active = useCheckbox(provisioning?.active, 'Provisioning Active');
    const ip = useInput(provisioning?.ip, 'ip', 'AP IP Address');
    const hardware = useInput(provisioning?.hardware, '', 'Hardware');
    const ussid = useInput(provisioning?.ussid, '', 'Unit SSID');
    const upass = useInput(provisioning?.upass, '', 'Unit Password');
    const pssid = useInput(provisioning?.pssid, '', 'Provisioning SSID');
    const ppass = useInput(provisioning?.ppass, '', 'Provisioning Password');

    const changed = (active.changed || ip.changed || hardware.changed || ussid.changed || upass.changed || pssid.changed || ppass.changed);
    const valid = (ip.valid && hardware.valid && ussid.valid && upass.valid && pssid.valid && ppass.valid);
    const enableSubmit = changed && valid;

    const saveClick = event => {
        event.stopPropagation();
        updateSetting({
            type: 'Provisioning',
            active: active.checked,
            ip: ip.value,
            hardware: hardware.value,
            ussid: ussid.value,
            upass: upass.value,
            pssid: pssid.value,
            ppass: ppass.value
        }, {
            onSuccess: () => {
                active.reset();
                ip.reset();
                hardware.reset();
                ussid.reset();
                upass.reset();
                pssid.reset();
                ppass.reset();
            }
        });
    };

    const resetClick = event => {
        event.stopPropagation();
        active.reset();
        ip.reset();
        hardware.reset();
        ussid.reset();
        upass.reset();
        pssid.reset();
        ppass.reset();
    };

    return (
        <Box p={2} width="100%">
            <Loading active={loading} spinner text="Loading...">
                <Loading active={saving} spinner text="Saving...">
                    <Typography>{provisioning?.available ? 'Hardware Available' : 'No Hardware'}</Typography>
                    <br />

                    <CustomSelect field={hardware} data={hardwareList} required fullWidth />
                    <br />
                    <br />
                    <FormControlLabel
                        control={<Checkbox checked={active.checked} onChange={active.onChange} />}
                        label="Active"
                    />
                    <br />
                    <br />
                    <Field field={ip} required fullWidth />
                    <Field field={ussid} required fullWidth />
                    <Field field={upass} disabled required fullWidth />
                    <Field field={pssid} disabled required fullWidth />
                    <Field field={ppass} disabled required fullWidth />
                    <br />
                    <Button onClick={saveClick} color='primary' disabled={!enableSubmit}>Save</Button>
                    &nbsp;&nbsp;
                    <Button onClick={resetClick} color='secondary' disabled={!enableSubmit}>Reset</Button>
                    <br />
                    <br />
                    <ErrorList errors={[saveError]} />
                </Loading>
            </Loading>
        </Box>
    );
};

Wifi.propTypes = {
    hub: PropTypes.object
};

export default Wifi;
