import FaultList from './FaultList';
import FaultDetail from './FaultDetail';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Horizontal, Vertical } from '../../components/Layout';
import { useInput } from '@domatic/hooks';
import { useBuildings, useCurrentBuilding } from '@domatic/query';

const Faults = () => {

    const { data: allBuildings } = useBuildings();
    const buildings = allBuildings?.map(building => ({ name: building.name, id: building.id }));

    const firstBuilding = buildings?.[0] ?? '';
    const selection = useInput(null, '', 'Building');
    if (!selection.value && buildings.length > 0) {
        selection.setValue(firstBuilding.id);
    }

    const selectedBuilding = buildings?.find(building => selection.value === building.id);
    const buildingId = selectedBuilding?.id;

    const building = useCurrentBuilding();

    const { url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const onRowClick = (params) => {
        console.log('row click:', params);
        history.push(`${url}/${params?.id}`);
    };

    const onClose = () => {
        history.push(`${url}`);
    };

    return (
        <Horizontal>
            <Vertical>
                <FaultList buildingId={building?.id} onRowClick={onRowClick} />
            </Vertical>
            {url !== location.pathname &&
                <Vertical>
                    <Switch>
                        <Route path={`${url}/:faultId`}>
                            <FaultDetail buildingId={buildingId} onClose={onClose} />
                        </Route>
                    </Switch>
                </Vertical>
            }
        </Horizontal>
    );
};

export default Faults;
