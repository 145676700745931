import { useState, useMemo, useCallback } from 'react';

import Grid from '../../components/Grid';
import { Horizontal, Vertical } from '../../components/Layout';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { useCurrentBuilding, useHubActivity } from '@domatic/query';

import { DateTime } from 'luxon';
import prettyMs from 'pretty-ms';

const dateFormatter = params => DateTime.fromMillis(params.value).toRelative();
const durationFormatter = params => prettyMs(params.value);

const ButtonActivity = () => {
    const building = useCurrentBuilding();
    const { data: activity } = useHubActivity(building?.id);

    const rows = useMemo(() => activity.map((hub, index) => ({ ...hub, id: index })), [activity]);

    const [hidden, setHidden] = useState([]);

    const columns = [
        { field: 'ts', headerName: 'Timestamp', flex: 1, valueFormatter: dateFormatter },
        { field: 'hub', headerName: 'Hub', flex: 1, sortable: false },
        { field: 'device', headerName: 'Device', flex: 1 },
        { field: 'deviceId', headerName: 'Device ID', flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1 },
        { field: 'duration', headerName: 'Duration', flex: 1, valueFormatter: durationFormatter }
    ].map((column, index) => ({ ...column, hide: hidden[index] ?? false }));

    const initialSortModel = [
        { field: 'ts', sort: 'asc' }
    ];

    const onHideColumn = useCallback((field, val) => {
        setHidden(columns.map((column, index) => {
            if (column.field === field) {
                return val;
            }
            return hidden[index] ?? false;
        }));
    }, [columns, hidden]);

    const { url } = useRouteMatch();
    const history = useHistory();

    const onRowClick = (params) => {
        console.log('row click:', params);
        history.push(`${url}/${params?.row?.hubId}/${params?.row?.phase}`);
    };

    return (
        <Horizontal>
            <Vertical>
                <Grid
                    title={'Button Activity'}
                    columns={columns}
                    rows={rows}
                    initialSortModel={initialSortModel}
                    hideColumnControl={true}
                    onHideColumn={onHideColumn}
                    disableColumnMenu={false}
                    disableSelectionOnClick={true}
                    checkBoxSelection={false}
                    onRowClick={onRowClick}
                />
            </Vertical>
        </Horizontal>
    );
};

export default ButtonActivity;
