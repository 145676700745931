import { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Menu, IconButton, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import ColumnIcon from '@material-ui/icons/ViewColumn';

const ColumnSelect = ({ columns, onHideColumn }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event, field, hide) => {
        if (onHideColumn) {
            onHideColumn(field, !hide);
        }
        setAnchorEl(null);
    };

    return (
        <Box p={1}>
            <IconButton aria-controls="column-select" aria-haspopup="true" onClick={openMenu}>
                <ColumnIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                // variant="outlined"
                labelid="checkbox-label"
                id="demo-mutiple-checkbox"
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {columns?.map(column => (
                    <MenuItem key={column.field} value={column.field} onClick={event => handleClick(event, column.field, column.hide)}>
                        <Checkbox checked={!column.hide} value={column.field} />
                        <ListItemText primary={column.headerName} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

ColumnSelect.propTypes = {
    columns: PropTypes.array,
    onHideColumn: PropTypes.func
};

export default ColumnSelect;
