import { useState, useEffect, useRef } from 'react';
import { check } from '@domatic/password-checker';

const first = async (coll, f) => {
    let firstResult = null;
  
    for (const element of coll) {
        const result = await f(element);
        if (result) {
            firstResult = result;
            break;
        }
    }
    return firstResult;
};

const reEmail = /\S+@\S+\.\S+/;
const reAt = /\S+@\S+/;
const reDot = /\S+\.\S+/;
const reUUID = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[4][0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-ff]{3}-[0-9A-Fa-f]{12}$/;
const reIP = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

// 3d69b1b4-f0d4-47b7-85e6-eb275bcc58d2
const validateEmail = value => Promise.resolve(
    (() => {
        if (!reEmail.test(String(value).toLowerCase()))
            return 'invalid email address';
        return null;
    })()
);

const validateUUID = value => Promise.resolve(
    (() => {
        if (!reUUID.test(String(value).toLowerCase()))
            return 'invalid UUID';
        return null;
    })()
);

const validateIP = value => Promise.resolve(
    (() => {
        if (!reIP.test(String(value).toLowerCase()))
            return 'invalid IP address';
        return null;
    })()
);

const reUsername = /^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;

const validateUserOrEmail = value => Promise.resolve(
    (() => {
        const validEmail = reEmail.test(String(value).toLowerCase());
        const hasAtSign = reAt.test(String(value).toLowerCase());
        const hasDot = reDot.test(String(value).toLowerCase());

        if (validEmail) return null;
        if (value.length < 3) {
            return 'username too short';
        }
        if (value.length > 20) {
            return 'username too long';
        }
        if (!reUsername.test(String(value).toLowerCase())) {
            return 'username invalid';
        }
        if (hasAtSign || hasDot) return 'email needs and @ and a .';
        return null;
    })()
);

const validateUsername = value => Promise.resolve(
    (() => {
        if (!value?.match(/^[a-z0-9]+$/i)) {
            return 'alphanumeric only';
        }
        if (value?.length < 3) {
            return 'too short';
        }
        if (value?.length > 20) {
            return 'too long';
        }
        if (!reUsername.test(String(value).toLowerCase())) {
            return 'invalid characters';
        }
        return null;
    })()
);

const validateNotEmpty = value => Promise.resolve(
    (() => {
        if (value?.length < 1) {
            return 'must not be empty';
        }
        return null;
    })()
);

const validateNumber = value => Promise.resolve(
    (() => {
        if (Number.isNaN(Number(value))) {
            return 'must be a number';
        }
        return null;
    })()
);

const validatePassword = async password => check(password);

const validateJsonStr = async str => Promise.resolve(
    (() => {
        try {
            JSON.parse(str);
            return null;
        } catch (e) {
            return e.message;
        }
    })()
);

// const validatePassword = value => Promise.resolve(
//     (() => {
//         if (value?.length < 8) {
//             return 'too short';
//         }
//         if (value?.indexOf('1234') >= 0) {
//             return 'really?';
//         }
//         return null;
//     })()
// );

const doValidate = (func, value) => {
    if (Array.isArray(func)) {
        return first(func, f => doValidate(f, value));
    }
    if (typeof func === 'function') {
        return func(value);
    }
    switch (func) {
        case 'notEmpty':
            return validateNotEmpty(value);
        case 'number':
            return validateNumber(value);
        case 'username':
            return validateUsername(value);
        case 'email':
            return validateEmail(value);
        case 'password':
            return validatePassword(value);
        case 'emailOrUsername':
            return validateUserOrEmail(value);
        case 'json':
            return validateJsonStr(value);
        case 'uuid':
            return validateUUID(value);
        case 'ip':
            return validateIP(value);
        default:
            return Promise.resolve(null);
    }
};

const useInput = (bindingInput = '', validate, label, id = null) => {
    const [lastBinding, setLastBinding] = useState(bindingInput);
    const [touched, setTouched] = useState(false);
    const [changed, setChanged] = useState(false);
    const [value, setValue] = useState(bindingInput);
    const [helperText, setHelperText] = useState(null);
    const [error, setError] = useState(false);
    const [valid, setValid] = useState(false);
    const [validating, setValidating] = useState(true);
    const active = useRef(false);

    if (bindingInput !== lastBinding) {
        setLastBinding(bindingInput);
        setValue(bindingInput);
    }

    label = label || 'Name Me';
    const name = label.toLowerCase().replace(/\s/g, '') + (id || '');

    useEffect(() => {
        active.current = true;
        // console.log('validating', label);
        doValidate(validate, value)
            .then(errorMessage => {
                // console.log('error', errorMessage);
                if (active.current) {
                    setValidating(false);
                    setValid(!errorMessage);
                    if (touched) {
                        setError(!!(errorMessage));
                        setHelperText(errorMessage);
                    }
                    else {
                        setError(false);
                        setHelperText(null);
                    }
                }
            });
    }, [label, touched, validate, value, active, validating]);

    useEffect(
        () => {
            return () => {
                active.current = false;
            };
        }, []
    );

    const reset = () => {
        // console.log('reset');
        setValue(bindingInput);
        setLastBinding(bindingInput);
        setTouched(false);
        setChanged(false);
        setError(false);
        setValid(false);
        setValidating(true);
    };

    const onChange = event => {
        // console.log('onChange');
        setValidating(true);
        setValue(event.target.value);
        setValid(false);
        setError(false);
        setHelperText(null);
        setChanged(event.target.value !== lastBinding);
        setTouched(true);
    };

    const doSetValue = (value) => {
        setValue(value);
        setChanged(true);
    };

    return { value, setValue: doSetValue, reset, changed, onChange, valid, error, helperText, setHelperText, validating, label, name };
};

export default useInput;
