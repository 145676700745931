import PropTypes from 'prop-types';
import { Box, List, ListItem, ListItemText, IconButton, Typography } from '@material-ui/core';
import { useBuildingFault, useBuildingHubs, useFaultJournal } from '@domatic/query';
import { useParams } from 'react-router-dom';
import DeviceOffline from './DeviceOffline';
import HubOffline from './HubOffline';
import UnattachedCircuit from './UnattachedCircuit';
import GenericFault from './GenericFault';
import StatusChip from '../../components/StatusChip';
import { Vertical, Header, Body } from '../../components/Layout';
import BackIcon from '@material-ui/icons/ArrowBackIos';

const colors = {
    ok: 'lightGreen',
    warning: 'yellow',
    critical: 'orange',
    error: '#ff4060'
};

const severity = s => {
    switch (s) {
        case 'fatal':
            return {
                color: colors.error,
                label: 'Fatal'
            };
        case 'warning':
            return {
                color: colors.warning,
                label: 'Warning'
            };
        default:
            return {
                color: 'lightGray',
                label: 'Unknown'
            };
    }
};

const FaultDetail = ({ buildingId, onClose }) => {

    const { faultId } = useParams();

    const { data: fault } = useBuildingFault(buildingId, faultId);
    const { data: unsorted, isLoading: journalLoading, isError: journalError } = useFaultJournal(fault?.documentId);
    const journal = unsorted?.sort((a, b) => new Date(b.ts) - new Date(a.ts));
    const { data: hubs } = useBuildingHubs(buildingId);
    const hub = hubs?.find(hub => hub.id === fault?.hubId);

    return (
        <Vertical p={2}>
            <Header>
                <Box display="flex" flexDirection="row" alignItems="end" justifyContent="flex-start">
                    <IconButton size="medium" aria-label="close" onClick={onClose}><BackIcon /></IconButton>
                    <Box fontWeight="fontWeightBold" fontSize={30}>{fault?.__t}</Box>
                    <StatusChip label={fault?.resolved ? 'Resolved' : 'Active'} color={fault?.resolved ? colors.ok : colors.error} />
                    <StatusChip label={severity(fault?.severity).label} color={severity(fault?.severity).color} />
                    <Box flexGrow="1" alignSelf="center" align="right" fontWeight="fontWeightBold">
                        {/* <Link to={`/home/buildings/${buildingId}/hubs/${hub?.id}/detail`}> */}
                        {hub?.settings?.Config?.name ?? hub?.name}
                        {/* </Link> */}
                    </Box>
                </Box>
            </Header>
            <Body>
                {((type => {
                    switch (type) {
                        case 'HubOffline':
                            return <HubOffline fault={fault} />;
                        case 'DeviceOffline':
                            return <DeviceOffline fault={fault} />;
                        case 'UnattachedCircuit':
                            return <UnattachedCircuit fault={fault} />;
                        default:
                            return <GenericFault fault={fault} />;
                    }
                })(fault?.__t))}
                <Box flexGrow="1" flexShrink="1">
                    {journalLoading && <Typography>Loading journal...</Typography>}
                    {journalError && <Typography>Error loading journal.</Typography>}
                    {!journalLoading && !journalError && journal.length === 0 && <Typography>No journal entries.</Typography>}
                    <List dense={true}>
                        {journal.map((entry, index) => (
                            <ListItem key={index} style={{
                                backgroundColor: entry.resolved ? colors.ok : colors.error
                            }}>
                                <ListItemText>
                                    {new Date(entry.ts).toLocaleString()} : {entry.summary}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Body >
        </Vertical >
    );
};

FaultDetail.propTypes = {
    buildingId: PropTypes.string,
    onClose: PropTypes.func
};

FaultDetail.defaultProps = {};

export default FaultDetail;
