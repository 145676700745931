import { getQueryClient } from './queryClient';
import { useInfinite } from './utils';
import { createUseCrud } from './utils';

export const Fixture = {
    useAll: options => useInfinite(['fixture'], '/catalog/fixtures', options),
    ...createUseCrud(['fixture'], '/catalog/fixture')
};
export const updateFixtures = data => {
    console.log('socket fixture update:', data);
    const queryClient = getQueryClient();
    queryClient.invalidateQueries('fixture');
};

export const Driver = {
    useAll: options => useInfinite(['driver'], '/catalog/drivers', options),
    ...createUseCrud(['driver'], '/catalog/driver')
};

export const updateDrivers = data => {
    console.log('socket driver update:', data);
    const queryClient = getQueryClient();
    queryClient.invalidateQueries('drivers');
};

export const Product = {
    useAll: options => useInfinite(['product'], '/catalog/products', options),
    ...createUseCrud(['product'], '/catalog/product')
};
