import { Snackbar, Slide } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useOnline, useInfo, useOldInfo } from '@domatic/query';

const TransitionUp = props => {
    return <Slide {...props} direction="up" />;
};

const Offline = () => {
    const { data: info } = useInfo();
    const { data: oldinfo } = useOldInfo();
    const updated = info && oldinfo && info?.build?.number !== oldinfo?.build?.number;

    const online = useOnline();
    const open = updated || !online;

    const severity = updated ? 'success' : 'warning';

    const message = updated ? 'New version available. Refresh to activate.' : 'Connection to Server Lost.';

    return (
        <Snackbar
            open={open}
            TransitionComponent={TransitionUp}
        >
            <Alert
                severity={severity}
                elevation={6}
                variant="filled"
            >{message}</Alert>

        </Snackbar>
    );
};

export default Offline;
