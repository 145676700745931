import { useMemo } from 'react';
import { useBuildStatistics } from '@domatic/query';
import { Box, Typography } from '@material-ui/core';
import { BarChart, BarSeries, Bar } from 'reaviz';

const Builds = () => {

    const { data: info } = useBuildStatistics();

    const versions = useMemo(() => Object.keys(info?.versions ?? {}).map(v => ({ key: v, data: info.versions[v] })), [info]);
    const branches = useMemo(() => Object.keys(info?.branches ?? {}).map(b => ({ key: b, data: info.branches[b] })), [info]);

    return (
        <Box p={2} width="100%">
            <Typography variant="h6">{'Build Statistics'}</Typography>
            <Typography variant="body">{`Oldest build in use: ${info?.oldest?.version} (${info?.oldest?.branch})`}</Typography><br />
            <Typography variant="body">Hubs on build {info?.oldest?.version}: {(info?.oldest?.hubs ?? []).join(', ')}</Typography><br />
            <Typography variant="body">Groups on build {info?.oldest?.version}: {(info?.oldest?.groups ?? []).join(', ')}</Typography><br /><br />
            <Typography variant="h6">Version Distribution</Typography>
            <BarChart width={400} height={350} data={versions} series={<BarSeries colorScheme="cybertron" padding={0.1} bar={<Bar />} />} /><br />
            <Typography variant="h6">Branch Distribution</Typography>
            <BarChart width={400} height={350} data={branches} series={<BarSeries colorScheme="cybertron" padding={0.1} bar={<Bar />} />} />
        </Box >

    );
};

export default Builds;
