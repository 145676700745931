import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const checkValue = (data, value) => (data && data.findIndex(datum => datum.id === value) >= 0) ? value : '';

const CustomSelect = ({ data, field, disabled, onChange }) => {
    const classes = useStyles();

    const handleChange = event => {
        field.onChange(event);
        onChange && onChange(event);
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{field.label}</InputLabel>
            <Select
                displayEmpty
                disabled={disabled}
                autoWidth
                defaultValue=''
                labelid={field.name}
                id={field.name}
                value={checkValue(data, field.value)}
                onChange={handleChange}
                label={field.label}
            >
                {data.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

CustomSelect.propTypes = {
    data: PropTypes.array,
    field: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

CustomSelect.defaultProps = {
    disabled: false
};

export default CustomSelect;
