import { useState, useMemo } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import Loading from '../components/Loading';

import Grid from '../components/Grid';
import { useUsers, useUserActivity } from '@domatic/query';

import UserDetail from './UserDetail';

import { Box } from '@material-ui/core';
const { DateTime } = require('luxon');

const dateFormatter = params => DateTime.fromMillis(params.value).toRelative();

const UserActivity = () => {
    const { path, url } = useRouteMatch();
    const history = useHistory();

    const [selected] = useState({});
    const initialSortModel = [
        { field: 'ts', sort: 'desc' }
    ];
    const [sortModel, setSortModel] = useState(initialSortModel);

    const onSortModelChange = model => {
        setSortModel(model);
    };

    const { data: users } = useUsers({ sortModel: [{ field: 'id', sort: 'asc' }], auto: true });
    const { data: activity, isFetching: loading, hasNextPage, fetchNextPage } = useUserActivity({ sortModel });

    const rows = useMemo(() => activity.map((a, index) => {
        const ts = a.ts;
        const id = index;
        const user = users.find(u => u.id === a.userId);
        const username = user?.username ?? '-';
        const name = user?.name ?? '-';
        const action = a.action;
        const data = a.data;
        return { ts, id, username, name, action, data };
    }), [activity, users]);

    const [columns, setColumns] = useState([
        { field: 'ts', headerName: 'Timestamp', flex: 1, valueFormatter: dateFormatter },
        { field: 'username', headerName: 'User Name', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1 },
        { field: 'data', headerName: 'Data', flex: 1 }
    ]);

    const onHideColumn = (field, val) => {
        setColumns(columns.map(e => {
            if (e.field === field) {
                e.hide = val;
            }
            return e;
        }));
    };

    const onClose = () => {
        history.replace(`${url}`);
    };

    const active = loading;

    return (
        <Box height="100%">
            <Loading active={active} spinner text={'Loading...'}>
                <Switch>
                    <Route exact path={`${path}`}>
                        <Grid
                            title={'User Activity'}
                            rows={rows}
                            columns={columns}
                            sortingMode={'server'}
                            initialSortModel={initialSortModel}
                            onSortModelChange={onSortModelChange}
                            canFetchMore={hasNextPage}
                            fetchMoreRows={() => fetchNextPage()}
                            checkBoxSelection={true}
                            multiple={false}
                            onHideColumn={onHideColumn}
                        >
                        </Grid>
                    </Route>
                    <Route path={`${path}/${selected?.id}/edit`}>
                        <UserDetail title="Edit User" user={selected} onCancel={onClose} />
                    </Route>
                </Switch>
            </Loading>
        </Box>
    );
};

UserActivity.propTypes = {
};

UserActivity.defaultProps = {};

export default UserActivity;
