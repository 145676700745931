import PropTypes from 'prop-types';

import Chip from '../components/StatusChip';
import ErrorIcon from '@material-ui/icons/Error';
import BlockIcon from '@material-ui/icons/Block';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import DoneIcon from '@material-ui/icons/Done';

const ZeusStation = ({ station }) => {

    // enum: ['new', 'incomplete', 'whitelist_now', 'primed', 'manual', 'done'],

    switch (station) {
        case 'new':
            return (
                <Chip
                    icon={<FiberNewIcon />}
                    label="New"
                    color="primary"
                />
            );
        case 'incomplete':
            return (
                <Chip
                    icon={<BrokenImageIcon />}
                    label="Incomplete"
                    color="secondary"
                />
            );
        case 'whitelist_now':
            return (
                <Chip
                    icon={<BlockIcon />}
                    label="Whitelist Now"
                    color="secondary"
                />
            );
        case 'primed':
            return (
                <Chip
                    icon={<AlarmOnIcon />}
                    label="Primed"
                    color="secondary"
                />
            );
        case 'manual':
            return (
                <Chip
                    icon={<TouchAppIcon />}
                    label="Needs Manual Commissioning"
                    color="secondary"
                />
            );
        case 'done':
            return (
                <Chip
                    icon={<DoneIcon />}
                    label="Complete"
                    color="secondary"
                />
            );
        default:
            return (
                <Chip
                    icon={<ErrorIcon />}
                    label="Loading"
                    color="default"
                />
            );
    }
};

ZeusStation.propTypes = {
    station: PropTypes.string
};

export default ZeusStation;
