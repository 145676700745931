import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useDevice } from '@domatic/query';

const GenericFault = ({ fault }) => {

    const deviceId = fault?.deviceId;
    const { data: device } = useDevice(deviceId);

    return (
        <>
            <Typography>{device?.type}: {device?.name} ({device?.description})</Typography>
            <Typography>ID: {deviceId ?? 'Unknown'}</Typography>
            <Typography>MAC: {device?.mac}, IP: {device?.ip}, Port: {device?.port ?? '---'}</Typography>
            <Typography>TemplateId: {device?.templateId}({device?.templateComment})</Typography>
            <Typography>Status: {device?.activeStatus}</Typography>
            <Typography>State: {JSON.stringify(device?.state, null, 4)}</Typography>
        </>
    );
};

GenericFault.propTypes = {
    fault: PropTypes.object
};

GenericFault.defaultProps = {};

export default GenericFault;
