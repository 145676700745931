const rules = [
    {
        message: 'Too short.  Minimum 8 characters.',
        check: password => password.length < 8
    },
    {
        message: 'At least one uppercase character.',
        check: password => !password.match(/[A-Z]/)
    },
    {
        message: 'At least one number.',
        check: password => !password.match(/[0-9]/)
    },
    {
        message: 'At least one special character.',
        check: password => !password.match(/[[\]!@#$%^&*(){}|\\_\-+=~`'";:<>,.?/]/)
    }
];

const check = password => {
    const fail = rules.find(rule => rule.check(password));
    return fail?.message || null;
};

module.exports = { check };

