import axios from 'axios';
import { useInfiniteQuery, useQuery, useMutation, getQueryClient } from './queryClient';
import { useSession, useUserId, errorCheck } from './session';
import { useSocketId, send } from './notify';
import { findInArray } from '@domatic/compare';

const getInfinite = (url, options) => async ({ queryKey, pageParam = null }) => {
    // console.log('getInfinite:', queryKey);
    const enabled = queryKey.every(element => !!element);
    if (!enabled) {
        return { items: [] };
    }
    options = options || {};
    const { sortBy = 'id', reverse = false, archived = false } = options;
    try {
        const params = new URLSearchParams();
        if (sortBy) {
            params.append('sortBy', sortBy);
        }
        if (reverse) {
            params.append('reverse', true);
        }
        if (archived) {
            params.append('archived', true);
        }
        pageParam && params.append('page', pageParam);

        const { data } = await axios.get(url, { params });
        return data;
    }
    catch (error) {
        console.error(`getInfinite(${url}, ${options}):`, error);
        errorCheck(error);
    }
};

export const useInfinite = (key, url, options = {}) => {
    const userId = useUserId();
    const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery(
        [userId, ...key, options],
        getInfinite(url, options),
        {
            getNextPageParam: lastPage => lastPage.next,
            staleTime: Infinity
        });

    const count = data?.pages[0]?.count ?? 0;

    // console.log('count:', count);

    const rows = (data?.pages || [])
        .reduce((collection, page) => {
            page.items.forEach(item => collection.push(item));
            return collection;
        }, []);

    if ((options?.auto) && !isFetching && hasNextPage) {
        fetchNextPage();
    }

    return { data: rows, count, isFetching, hasNextPage, fetchNextPage };
};

// opportunistic function to grab a data from a prior infinite query.
// this lets us grab cached data for non-essential stuff like the breadcrumb
export const getInfiniteObject = (key, id) => {
    const queryClient = getQueryClient();
    const queriesData = queryClient.getQueriesData(key);
    const data = queriesData?.flat().find(el => !!el?.data);
    if (!data) return;
    const item = data.data?.find(item => item.id === id);
    return item;
};

const retrieveObject = (url) => async ({ queryKey }) => {
    // console.log('retrieveObject:', queryKey);
    const enabled = queryKey.every(element => !!element);
    if (!enabled) {
        return {};
    }
    try {
        const { data } = await axios.get(url);
        return data;
    }
    catch (error) {
        console.error(`retrieveObject(${url}):`, error);
        errorCheck(error);
    }
};

export const useRetrieveObject = (key, url) => {
    const userId = useUserId();
    const query = useQuery(
        [userId, ...key],
        retrieveObject(url),
        {
            staleTime: Infinity
        });
    return { ...query, data: query.data || {} };
};

const retrieveArray = (url) => async ({ queryKey }) => {
    // console.log('retrieveArray:', queryKey);
    const enabled = queryKey.every(element => !!element);
    if (!enabled) {
        return [];
    }
    try {
        const { data: items } = await axios.get(url);
        return items.map(item => {
            const queryClient = getQueryClient();
            queryClient.setQueryData([...queryKey, 'item', item.id], item);
            return item;
        });
    }
    catch (error) {
        console.error(`retrieveObject(${url}):`, error);
        errorCheck(error);
    }
};

export const useRetrieveArray = (key, url) => {
    const userId = useUserId();
    const query = useQuery(
        [userId, ...key],
        retrieveArray(url),
        {
            staleTime: Infinity
        });
    return { ...query, data: query.data || [] };
};

const createObject = url => async (obj) => {
    try {
        const { data } = await axios.post(url, obj);
        return data;
    }
    catch (error) {
        console.error(`createObject(${url}):`, error);
        errorCheck(error);
    }
};

export const useCreateObject = (key, url) => {
    const userId = useUserId();
    return useMutation(createObject(url), {
        onSuccess: () => {
            const queryClient = getQueryClient();
            queryClient.invalidateQueries([userId, ...key]);
        }
    });
};

const updateObject = url => async (obj) => {
    try {
        const { data } = await axios.put(url, obj);
        return data;
    }
    catch (error) {
        console.error(`updateObject(${url}):`, error);
        errorCheck(error);
    }
};

export const useUpdateObject = (key, url) => {
    const userId = useUserId();
    return useMutation(updateObject(url), {
        onSuccess: () => {
            const queryClient = getQueryClient();
            queryClient.invalidateQueries([userId, ...key]);
        }
    });
};

const deleteObjectById = (url) => async id => {
    const fullUrl = `${url}/${id}`;
    try {
        await axios.delete(fullUrl);
        return id;
    }
    catch (error) {
        console.error(`deleteObjectById(${fullUrl}):`, error);
        errorCheck(error);
    }
};

export const useDeleteObject = (key, url) => {
    const userId = useUserId();
    return useMutation(deleteObjectById(url), {
        // update the cache for the lists that had this item
        onSuccess: () => {
            const queryClient = getQueryClient();
            queryClient.invalidateQueries([userId, ...key]);
        }
    });
};

export const createUseCrud = (key, url, mode = 'crud') => {
    const crud = {};
    if (mode.includes('r')) crud.use = id => useRetrieveObject([...key, id], `${url}/${id}`); // eslint-disable-line react-hooks/rules-of-hooks
    if (mode.includes('c')) crud.useCreate = () => useCreateObject(key, url); // eslint-disable-line react-hooks/rules-of-hooks
    if (mode.includes('u')) crud.useUpdate = () => useUpdateObject(key, url); // eslint-disable-line react-hooks/rules-of-hooks
    if (mode.includes('d')) crud.useDelete = () => useDeleteObject(key, url); // eslint-disable-line react-hooks/rules-of-hooks
    return crud;
};

export const usePost = (url, data = {}) => {
    return useMutation({
        mutationFn: body => axios.post(url, { ...body, ...data }),
    });
};

export const usePut = (url, data = {}) => {
    return useMutation({
        mutationFn: body => axios.put(url, { ...body, ...data }),
    });
};

export const useGet = (key, url) => {
    const query = useQuery(
        key,
        retrieveObject(url),
        {
            staleTime: Infinity
        });
    return { ...query, data: query.data || {} };
};

export const useSubscribeObject = (topic, ...params) => {
    const socketId = useSocketId();
    const session = useSession();
    const token = session?.token;
    const queryKey = [topic, token, socketId, ...params];
    const enabled = queryKey.every(element => !!element);

    const subscribe = async ({ signal }) => {
        if (enabled) {
            // console.log(`[subscription] ${enabled ? 'SUBSCRIBING' : 'not subscribing'} to ${topic}(${params.join(', ')})`);
            send('subscribe', queryKey);
            let done;
            const promise = new Promise((resolve) => { done = resolve; });
            const queryClient = getQueryClient();
            queryClient.setQueryData(queryKey, {});
            signal?.addEventListener('abort', () => {
                // console.log(`[subscription] ${enabled ? 'UNSUBSCRIBING' : 'not unsubscribing'} from ${topic}(${params.join(', ')})`);
                send('unsubscribe', queryKey);
                done({});
            });
            return promise;
        }
        return {};
    };

    return useQuery({
        queryKey,
        queryFn: subscribe,
        staleTime: Infinity,
        placeholderData: {}
    });
};

export const updateObjectCache = (command, queryKey, item) => {
    const queryClient = getQueryClient();
    if (command === 'init' || command === 'insert' || command === 'update') {
        queryClient.setQueryData(queryKey, item);
    }
};

export const updateMapCache = (command, queryKey, item) => {
    const queryClient = getQueryClient();
    const cache = queryClient.getQueryData(queryKey);
    if (command === 'init') {
        queryClient.setQueryData(queryKey, item);
    }
    else if (command === 'insert' || command === 'update') {
        queryClient.setQueryData(queryKey, { ...cache, ...item });
    }
    else if (command === 'delete') {
        const { [item]: _, ...rest } = cache;
        queryClient.setQueryData(queryKey, rest);
    }
};

export const useSubscribeArray = (topic, ...params) => {
    const socketId = useSocketId();
    const session = useSession();
    const token = session?.token;
    const queryKey = [topic, token, socketId, ...params];
    const enabled = queryKey.every(element => !!element);

    const subscribe = async ({ signal }) => {
        if (enabled) {
            // console.log(`[subscription] ${enabled ? 'SUBSCRIBING' : 'not subscribing'} to ${topic}(${params.join(', ')})`);
            send('subscribe', queryKey);
            let done;
            const promise = new Promise((resolve) => { done = resolve; });
            const queryClient = getQueryClient();
            queryClient.setQueryData(queryKey, []);
            signal?.addEventListener('abort', () => {
                // console.log(`[subscription] ${enabled ? 'UNSUBSCRIBING' : 'not unsubscribing'} from ${topic}(${params.join(', ')})`);
                send('unsubscribe', queryKey);
                done([]);
            });
            return promise;
        }
        return [];
    };

    return useQuery({
        queryKey,
        queryFn: subscribe,
        staleTime: Infinity,
        placeholderData: []
    });
};

export const updateArrayCache = (command, key, item) => {
    const queryClient = getQueryClient();
    if (command === 'init') {
        queryClient.setQueryData(key, item);
    }
    else {
        const items = queryClient.getQueryData(key);
        if (!items) {
            return;
        }
        const index = items.findIndex(element => element.id === item.id);
        if (command === 'delete' && index >= 0) {
            queryClient.setQueryData(key, items.filter((_, i) => i !== index));
        }
        else if (command === 'insert') {
            if (index === -1) {
                queryClient.setQueryData(key, [...items, item]);
            }
            else {
                const updated = [
                    ...items.slice(0, index),
                    item,
                    ...items.slice(index + 1)
                ];
                queryClient.setQueryData(key, updated);
            }
        }
        else if (command === 'update') {
            if (index !== -1) {
                const updated = [
                    ...items.slice(0, index),
                    item,
                    ...items.slice(index + 1)
                ];
                queryClient.setQueryData(key, updated);
            }
        }
    }
};

export const useSubscribeInfinite = (topic, ...params) => {
    const socketId = useSocketId();
    const session = useSession();
    const token = session?.token;
    const queryKey = [topic, token, socketId, ...params];
    const enabled = queryKey.every(element => !!element);

    const queryClient = getQueryClient();

    const subscribe = async ({ signal }) => {
        if (enabled) {
            // console.log(`[subscription] ${enabled ? 'SUBSCRIBING' : 'not subscribing'} to ${topic}(${params.map(p => JSON.stringify(p)).join(', ')})`);
            send('subscribe', queryKey);
            let done;
            const promise = new Promise((resolve) => { done = resolve; });
            const cache = queryClient.getQueryData(queryKey);
            queryClient.setQueryData(queryKey, { ...cache, isFetching: true });
            signal?.addEventListener('abort', () => {
                // console.log(`[subscription] ${enabled ? 'UNSUBSCRIBING' : 'not unsubscribing'} from ${topic}(${params.join(', ')})`);
                send('unsubscribe', queryKey);
                done([]);
            });
            return promise;
        }
        return [];
    };

    const query = useQuery({
        queryKey,
        queryFn: subscribe,
        staleTime: Infinity,
        placeholderData: { data: [], hasNextPage: false, count: 0, isFetching: false, isError: false }
    });

    const page = query.data?.page ?? -1;
    const nextPage = query.data?.nextPage ?? -1;
    const data = query.data?.data ?? [];
    const hasNextPage = query.data.hasNextPage ?? false;
    const count = query.data.count ?? 0;
    const isFetching = query.data?.isFetching;
    const isError = query.data?.isError;
    const error = query.data?.error;

    const fetchNextPage = () => {
        const cache = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, { ...cache, isFetching: true, isError: false });
        send('fetchNextPage', { key: queryKey, page: nextPage });
    };

    return { ...query, data, page, nextPage, hasNextPage, fetchNextPage, count, isFetching, isError, error };
};

export const updateInfiniteCache = (command, key, item) => {
    const queryClient = getQueryClient();
    const { sortModel } = key[key.length - 1];
    if (!sortModel) {
        console.error(`updateInfiniteCache(${key}): missing sortModel`);
        return;
    }
    const sort = sortModel.reduce((param, column) => {
        param[column.field] = column.sort === 'asc' ? 1 : -1;
        return param;
    }, {});

    if (command === 'init') {
        queryClient.setQueryData(key, { ...item, isFetching: false });
    }
    else {
        const cache = queryClient.getQueryData(key);
        if (!cache) {
            return;
        }
        const items = cache.data;

        if (command === 'append') {
            queryClient.setQueryData(key, { ...cache, ...item, data: [...cache.data, ...item.data], isFetching: false });
        }
        else if (command === 'update') {
            const index = items.findIndex(element => element.id === item.id);
            if (index !== -1) {
                queryClient.setQueryData(key, { ...cache, data: items.toSpliced(index, 1, item) });
            }
        }
        else if (command === 'insert') {
            // const index = items.findIndex(element => element.id === item.id);
            const { index, hit } = findInArray(items, item, sort);
            if (!hit) {
                queryClient.setQueryData(key, { ...cache, data: items.toSpliced(index, 0, item), isFetching: false });
            }
            else {
                queryClient.setQueryData(key, { ...cache, data: items.toSpliced(index, 1, item), isFetching: false });
            }
        }
        else if (command === 'delete') {
            const index = items.findIndex(element => element.id === item.id);
            if (index >= 0) {
                queryClient.setQueryData(key, { ...cache, data: items.filter((_, i) => i !== index) });
            }
        }
        else if (command === 'error') {
            console.error(`error: updateInfiniteCache(${key}):`, JSON.stringify(item));
            queryClient.setQueryData(key, { ...cache, data: [], isFetching: false, isError: true, error: item?.error ?? 'unknown error' });
        }
    }
};

export const useArrayItem = (topic, id, ...params) => {
    const { data: items } = useSubscribeArray(topic, ...params);
    const item = items.find(i => i.id === id);
    return { data: item };
};
