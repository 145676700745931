import { Switch, Route } from 'react-router-dom';

import Login from './Login';
import Logout from './Logout';
import Validating from './Validating';
import Validated from './Validated';
import ValidationError from './ValidationError';
import Signup from './Signup';
import Forgot from './Forgot';
import Error from './Error';
import Invalid from './Invalid';
import Requested from './Requested';
import ResetPassword from './ResetPassword';
import Resetted from './Resetted';
import Accepted from './Accepted';
import UnknownUser from './UnknownUser';

const UserDialogs = () => {
    return (
        <Switch>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/logout">
                <Logout />
            </Route>
            <Route path="/signup">
                <Signup />
            </Route>
            <Route path="/forgot">
                <Forgot />
            </Route>
            <Route path="/error/:code/:message">
                <Error />
            </Route>
            <Route path="/invalid/:username">
                <Invalid />
            </Route>
            <Route path="/requested">
                <Requested />
            </Route>
            <Route path="/reset/:username/:token">
                <ResetPassword />
            </Route>
            <Route path="/resetted">
                <Resetted />
            </Route>
            <Route path="/validating">
                <Validating />
            </Route>
            <Route path="/validated/:email">
                <Validated />
            </Route>
            <Route path="/unvalidated/:email">
                <ValidationError />
            </Route>
            <Route path="/accepted/:email/:organization">
                <Accepted />
            </Route>
            <Route path="/unknown/:email">
                <UnknownUser />
            </Route>
        </Switch>
    );
};

export default UserDialogs;
