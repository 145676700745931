import { useRouteMatch, useLocation, Switch, Route, Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@material-ui/core';
import { Horizontal, Header, Body } from './Layout';

const TabNav = ({ tabs }) => {
    const { url } = useRouteMatch();
    const location = useLocation();

    const path = location.pathname.replace(`${url}`, '').split('/');
    const page = path[0].length > 0 ? path[0] : (path.length > 1 ? path[1] : tabs[0].route);
    const tabLocation = `${url}/${page}`;

    return (
        <Horizontal>
            <Header>
                <Box p={2} display="flex">
                    <Box flexGrow={0}>
                        <Tabs value={tabLocation} variant="fullWidth" orientation="vertical">
                            {tabs.map((tab, i) => (
                                <Tab key={i} label={tabs[i].label} value={`${url}/${tabs[i].route}`} component={Link} to={`${url}/${tabs[i].route}`} />
                            ))}
                        </Tabs>
                    </Box>
                </Box>
            </Header>
            <Body>
                <Switch>
                    {tabs.map((tab, i) => (
                        <Route key={i} path={`${url}/${tabs[i].route}`}>
                            {tabs[i].component}
                        </Route>
                    ))}
                    <Route>
                        <Redirect to={`${url}/${tabs[0].route}`} />
                    </Route>
                </Switch>
            </Body>
        </Horizontal >
    );
};

TabNav.propTypes = {
    tabs: PropTypes.array
};

export default TabNav;
