import PropTypes from 'prop-types';
import { useState, useEffect, useRef, useMemo } from 'react';
import { usePowerMeter } from '@domatic/query';
import { useParams } from 'react-router-dom';
import { Vertical, Header, Body } from '../../components/Layout';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import { IconButton, Button } from '@material-ui/core';
import { StackedAreaChart, StackedAreaSeries, ChartZoomPan, Line, DiscreteLegend, DiscreteLegendEntry } from 'reaviz';
import { useTheme } from '@material-ui/core/styles';

// const measurements = ['voltage', 'current', 'power', 'energy', 'frequency', 'powerFactor'];
const keys = [0, 1];
const phases = ['phase0', 'phase1'];

const initialData = phases.map(key => ({ key, data: [{ key: 0, data: 0 }] }));

const startTimestamp = (scale, end) => {
    if (scale === 'thisyear') return new Date(end.getFullYear(), 0, 1);
    if (scale === 'thismonth') return new Date(end.getFullYear(), end.getMonth(), 1);
    if (scale === 'thisweek') return new Date(end.getFullYear(), end.getMonth(), end.getDate() - end.getDay() + 1);
    if (scale === 'today') return new Date(end.getFullYear(), end.getMonth(), end.getDate());
    if (scale === 'threehours') return new Date(end.getTime() - 3 * 3600 * 1000);
    return new Date(end.getTime() - 3600 * 1000);
};

const sample = (data, ts, key) => {
    const rightIndex = (() => {
        const i = data.findIndex(d => d.ts > ts);
        return i >= 0 ? i : data.length - 1;
    })();
    const leftIndex = rightIndex > 0 ? rightIndex - 1 : 0;
    const right = data[rightIndex];
    const left = data[leftIndex];
    const delta = right.ts - left.ts;
    const value = delta > 0
        ? (left[key] + (right[key] - left[key]) * (ts - left.ts) / delta)
        : left[key];
    return { key: new Date(ts), data: value };
};

const allTimestamps = data => {
    const timestamps = new Set();
    for (const key in data) {
        data[key].forEach(sample => timestamps.add(sample.ts));
    }
    return Array.from(timestamps).sort();
};

const prepareData = meterData => {
    const timestamps = allTimestamps(meterData);
    // const keys = Object.keys(meterData);

    const result = keys.map(key => {
        const seriesData = meterData[key];
        return { key: `phase${key}`, data: timestamps.map(ts => sample(seriesData, ts, 'power')) };
    });
    return result;
};

const PowerMeterDetail = ({ onClose }) => {

    const theme = useTheme();
    const colorScheme = useMemo(() => [theme.palette.phase0, theme.palette.phase1], [theme.palette]);

    const timer = useRef(null);
    useEffect(() => () => clearTimeout(timer.current), []);

    const now = useRef(new Date());

    const { hubId } = useParams();

    const end = now.current;
    const [start, setStart] = useState(startTimestamp('onehour', end));

    const [queryDomain, setQueryDomain] = useState([start.getTime(), end.getTime()]);
    const [domain, setDomain] = useState([start, end]);

    const { data: meterData } = usePowerMeter(hubId, queryDomain[0], queryDomain[1]);

    const [data, setData] = useState(initialData);

    useEffect(() => {
        if (!meterData || Object.keys(meterData).length === 0) return;
        setData(prepareData(meterData));
    }, [start, meterData, end]);

    const onResetDomain = (scale) => {
        const newStart = startTimestamp(scale, end);
        setStart(newStart);
        setDomain([newStart, end]);
        setQueryDomain([newStart.getTime(), end.getTime()]);
    };

    return (
        <Vertical p={1}>
            <Header>
                <IconButton size="medium" aria-label="close" onClick={onClose}><BackIcon /></IconButton>
                <Button onClick={() => onResetDomain('onehour')}>1 Hour</Button>&nbsp;
                <Button onClick={() => onResetDomain('threehours')}>3 Hours</Button>&nbsp;
                <Button onClick={() => onResetDomain('today')}>Today</Button>&nbsp;
                <Button onClick={() => onResetDomain('thisweek')}>Week</Button>&nbsp;
                <Button onClick={() => onResetDomain('thismonth')}>Month</Button>&nbsp;
                <Button onClick={() => onResetDomain('thisyear')}>Year</Button>
            </Header>
            <Body p={4}>
                <StackedAreaChart
                    series={<StackedAreaSeries
                        colorScheme={colorScheme}
                        animated={false}
                        line={<Line strokeWidth={0} />} />}
                    zoomPan={< ChartZoomPan domain={domain} onZoomPan={({ domain }) => {
                        setDomain(domain);
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                            setQueryDomain([domain[0].getTime(), domain[1].getTime()]);
                            console.log('domains:', domain, queryDomain);
                        }, 1000);
                    }} />}
                    data={data} />
            </Body>
            <Header px={2}>
                <DiscreteLegend
                    orientation="horizontal"
                    entries={phases.map(phase => (
                        <DiscreteLegendEntry
                            key={phase}
                            label={phase.charAt(0).toUpperCase() + phase.slice(1)}
                            color={colorScheme} />
                    ))}
                />
            </Header>
        </Vertical >
    );
};

PowerMeterDetail.propTypes = {
    buildingId: PropTypes.string,
    onClose: PropTypes.func
};

PowerMeterDetail.defaultProps = {};

export default PowerMeterDetail;
