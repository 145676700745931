// eslint-disable-next-line no-unused-vars
import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Typography, Box } from '@material-ui/core';
import Loading from '../components/Loading';

import { Field } from '../components';
import { useInput } from '@domatic/hooks';

import AddIcon from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';

import { useCurrentOrganization, useCreateBuilding } from '@domatic/query';

const AddBuilding = ({ onClose }) => {

    const organization = useCurrentOrganization();

    const name = useInput('', 'notEmpty', 'Name');
    const address = useInput('', 'notEmpty', 'Address');
    const { mutate: addBuilding, isLoading: adding } = useCreateBuilding();

    const doAdd = () => addBuilding({
        name: name.value,
        address: address.value,
        owner: organization.id
    }, {
        onSuccess: onClose
    });

    const formValid = name.valid && address.valid;

    return (
        <Loading active={adding} spinner text="Loading...">
            <Box p={2}>
                <Typography>Add a New Building to &quot;{organization.name}&quot;</Typography><br />
                <Field field={name} required fullWidth />
                <Field field={address} required fullWidth />
                {/* <Typography>{errorMessage}&nbsp;</Typography> */}
                <Button color="primary" onClick={doAdd} disabled={!formValid} startIcon={<AddIcon />}>Add</Button>
                &nbsp;
                &nbsp;
                <Button color="secondary" onClick={onClose} startIcon={<UndoIcon />}>Cancel</Button>
            </Box>
        </Loading>
    );
};

AddBuilding.propTypes = {
    onClose: PropTypes.func
};

AddBuilding.defaultProps = {};

export default AddBuilding;
