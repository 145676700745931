import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    card: {
        maxWidth: 345,
        minWidth: 345
    },
    title: {
        fontSize: 14,
    },
    errorMessage: {
        color: 'red'
    }
}));

