import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

// import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import MenuClosedIcon from '@material-ui/icons/ChevronRight';
import MenuOpenIcon from '@material-ui/icons/ChevronLeft';
import { useMenuDrawerOpen, useSetMenuDrawerOpen } from '@domatic/query';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        position: 'relative',
        height: '100%'
    },
    drawer: {
        width: drawerWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerContainer: {
        overflow: 'auto',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(6) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    position: 'relative'
}));

const MenuDrawer = ({ items }) => {
    const classes = useStyles();
    const location = useLocation();

    const { data: drawerOpen } = useMenuDrawerOpen();
    const { mutate: setDrawerOpen } = useSetMenuDrawerOpen();

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
                paper: clsx(classes.paper, {
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen
                }),
            }}
        >
            <div className={classes.drawerContainer}>
                <List
                    disablePadding={true}
                >
                    <ListItem key={-1} button onClick={() => setDrawerOpen(!drawerOpen)}>
                        {drawerOpen ?
                            <ListItemIcon>
                                <MenuOpenIcon />
                            </ListItemIcon>
                            :
                            <ListItemIcon>
                                <MenuClosedIcon />
                            </ListItemIcon>
                        }
                    </ListItem>

                    {items.map(({ primaryText, icon, path, divider, button, onClick }, i) => (
                        divider ? (
                            <Divider style={{ margin: '12px 0' }} key={i} />
                        )
                            : button ? (
                                <ListItem
                                    key={i}
                                    button
                                    onClick={onClick}
                                >
                                    <Icon>{icon}</Icon>
                                </ListItem>
                            )
                                : (
                                    <ListItem
                                        key={i}
                                        selected={location.pathname.startsWith(path)}
                                        button
                                        component={Link}
                                        to={path}
                                    >
                                        <ListItemIcon>
                                            <Icon>{icon}</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={primaryText}
                                            primaryTypographyProps={{ noWrap: true }}
                                        />
                                    </ListItem>
                                )
                    ))}
                </List>
            </div>
        </Drawer>
    );
};

MenuDrawer.propTypes = {
    items: PropTypes.array
};

MenuDrawer.defaultProps = {};

export default MenuDrawer;
