import PropTypes from 'prop-types';

import Chip from '../components/StatusChip';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import BlockIcon from '@material-ui/icons/Block';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const ZeusState = ({ state }) => {

    // enum: ['idle', 'port_detect', 'whitelist_update', 'disable_whitelist', 'enable_whitelist'],

    switch (state) {
        case 'idle':
            return (
                <Chip
                    icon={<CheckIcon />}
                    label="Idle"
                    color="primary"
                />
            );
        case 'port_detect':
            return (
                <Chip
                    icon={<SettingsInputCompositeIcon />}
                    label="Port Detect"
                    color="secondary"
                />
            );
        case 'whitelist_update':
            return (
                <Chip
                    icon={<BlockIcon />}
                    label="Whitelist Update"
                    color="secondary"
                />
            );
        case 'disable_whitelist':
            return (
                <Chip
                    icon={<AddCircleIcon />}
                    label="Disable Whitelist"
                    color="secondary"
                />
            );
        case 'enable_whitelist':
            return (
                <Chip
                    icon={<BlockIcon />}
                    label="Enable Whitelist"
                    color="secondary"
                />
            );
        default:
            return (
                <Chip
                    icon={<ErrorIcon />}
                    label="Loading"
                    color="default"
                />
            );
    }
};

ZeusState.propTypes = {
    state: PropTypes.string
};

export default ZeusState;
