import StrayHubs from './StrayHubs';
import GroupUpdate from './Groups';
import Builds from './Builds';
import BuildingDevices from './BuildingDevices';
import BuildingDeviceSummary from './BuildingDeviceSummary';
import Users from './Users';
import UserActivity from './UserActivity';
import JobManager from './JobManager';
import Info from './Info';
import BuildingSetup from './BuildingSetup';
import TabNav from '../components/TabNav';

const Hubs = () => {
    return (
        <TabNav tabs={[
            { route: 'uncaptured', label: 'Uncaptured', component: <StrayHubs /> },
            { route: 'groups', label: 'Groups', component: <GroupUpdate /> },
            { route: 'builds', label: 'Builds', component: <Builds /> },
            { route: 'devices', label: 'Devices', component: <BuildingDevices /> },
            { route: 'deviceSummary', label: 'Device Summary', component: <BuildingDeviceSummary /> },
            { route: 'users', label: 'Users', component: <Users /> },
            { route: 'userActivity', label: 'User Activity', component: <UserActivity /> },
            { route: 'jobs', label: 'Jobs', component: <JobManager /> },
            { route: 'setup', label: 'Setup', component: <BuildingSetup /> },
            { route: 'info', label: 'Info', component: <Info /> }
        ]} />
    );
};

export default Hubs;
