import PropTypes from 'prop-types';

import { Box, FormControlLabel, Switch } from '@material-ui/core';

import { useHubSettings, useUpdateSetting } from '@domatic/query';
import { useCheckbox } from '@domatic/hooks';

const HubDoor = ({ hub, onError }) => {

    const { data: settings, isLoading: loading } = useHubSettings(hub.id);
    const { mutate: updateSetting, isLoading: saving } = useUpdateSetting(hub.id);

    const enable = useCheckbox(settings?.Door?.enable, 'Door Access Control');

    const saveClick = event => {
        event.stopPropagation();
        updateSetting({
            type: 'Door',
            enable: !!event.target.checked
        }, {
            onError
        });
    };

    return (
        <Box flexShrink={0}>
            <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}

                control={<Switch
                    disabled={saving || loading}
                    checked={enable.checked}
                    onChange={saveClick}
                    onFocus={(event) => event.stopPropagation()}
                />}
                label="Enable Door Access Control"
            />
        </Box>
    );
};

HubDoor.propTypes = {
    hub: PropTypes.object,
    onError: PropTypes.func
};

export default HubDoor;
