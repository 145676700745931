import { useState } from 'react';

import { Redirect, useParams } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, CardActions } from '@material-ui/core';
import Loading from '../components/Loading';
import { useCardStyles } from '../hooks';
import { useInput } from '@domatic/hooks';
import { Center, PasswordField, ErrorList } from '../components';
import { useAuthenticated, useResetPassword } from '@domatic/query';

const ResetPassword = () => {
    const classes = useCardStyles();

    const authenticated = useAuthenticated();
    const password = useInput('', 'password', 'Password');

    const [loginRedirect, goLogin] = useState(false);
    const [resetSuccessRedirect, goResetSuccess] = useState(false);

    const { mutate: resetPassword, isLoading: loading, error: resetError } = useResetPassword();

    const { username, token } = useParams();

    const resetClick = () => resetPassword(
        { username, token, password: password.value },
        { onSuccess: () => goResetSuccess(true) }
    );

    const formValid = password.valid;

    if (authenticated) {
        return <Redirect to='/' />;
    }

    if (resetSuccessRedirect) {
        return <Redirect to='/resetted' />;
    }

    if (loginRedirect) {
        return <Redirect to='/login' />;
    }

    return (
        <Center>
            <Card className={classes.card}>
                <Loading active={loading} spinner text="loading...">
                    <CardHeader title="Reset Password" />
                    <CardContent>
                        <PasswordField field={password} password required fullWidth onEnter={resetClick} />
                        <ErrorList errors={[resetError]} />
                    </CardContent>
                    <CardContent>
                        <Button color="primary" onClick={resetClick} disabled={!formValid}>Reset</Button>
                    </CardContent>
                    <CardActions>
                        <Button variant="text" onClick={() => goLogin(true)}>Log In</Button>
                    </CardActions>
                </Loading>
            </Card>
        </Center>
    );
};

ResetPassword.propTypes = {
};

export default ResetPassword;
