import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoiam1ibGR3biIsImEiOiJjaXozbWJjN3AwNWpzMnFvYWppa2thM2w0In0.S-1aV4O7bgRef2Wz9efk5Q'; // Set your mapbox token here

const HubMap = ({ latitude, longitude }) => {

    const [viewState, setViewState] = useState({
        longitude: longitude ?? 0,
        latitude: latitude ?? 0,
        zoom: 10
    });

    useEffect(() => {
        setViewState({
            longitude: longitude ?? 0,
            latitude: latitude ?? 0,
            zoom: 10
        });
    }, [latitude, longitude]);

    return (
        <Map
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            style={{ width: 400, height: 300 }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={MAPBOX_TOKEN}
        >
            <Marker longitude={longitude ?? 0} latitude={latitude ?? 0} color="red" />
        </Map>
    );
};
HubMap.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number
};

export default HubMap;
