const isValidJSON = json => {
    try {
        JSON.parse(json);
    }
    catch (error) {
        return false;
    }
    return true;
};

export default isValidJSON;
