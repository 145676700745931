import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import Loading from '../components/Loading';
import { Center, ErrorList } from '../components';
import { useCardStyles } from '../hooks';
import { useAuthenticated, useLogout } from '@domatic/query';

const Logout = () => {
    const classes = useCardStyles();
    const authenticated = useAuthenticated();
    const { mutate: logout, isLoading: loggingOut, error: errorMessage } = useLogout();

    useEffect(() => {
        logout();
    }, [logout]);

    if (!authenticated) {
        return <Redirect to='/' />;
    }

    return (
        <Center>
            <Card className={classes.card}>
                <Loading active={loggingOut} spinner text="logging out...">
                    <CardContent>
                        <p>Logging out...</p>
                    </CardContent>
                    <ErrorList errors={[errorMessage]} />
                </Loading>
            </Card>
        </Center>
    );
};

export default Logout;
