import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';
import { Vertical } from '../components/Layout';
import Grid from '../components/Grid';

import AddBuilding from './AddBuilding';

import { useBuildings, useDeleteBuilding, useCurrentBuilding } from '@domatic/query';

import { compareFields } from '@domatic/compare';
const compareNames = compareFields('name', { caseInsensitive: true });

const Buildings = () => {

    const { path, url } = useRouteMatch();
    const history = useHistory();

    const { data: buildings } = useBuildings();
    const { mutate: deleteBuilding } = useDeleteBuilding();
    const currentBuilding = useCurrentBuilding();

    const rows = useMemo(() => buildings?.slice().sort(compareNames), [buildings]);

    const booleanFormatter = params => params.value ? '✓' : '';

    const columns = [
        { field: 'current', headerName: 'Current', width: 130, valueGetter: params => params.id === currentBuilding?.id, valueFormatter: booleanFormatter },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'address', headerName: 'Address', flex: 1 },
        { field: 'whitelisting', headerName: 'Scanner', width: 130, valueFormatter: booleanFormatter },
        { field: 'commissioning', headerName: 'Solver', width: 130, valueFormatter: booleanFormatter }
    ];

    const initialSortModel = [
        { field: 'name', sort: 'asc' }
    ];

    const onClose = () => {
        history.replace(url);
    };

    const onAdd = () => {
        history.replace(`${url}/add`);
    };

    const onDelete = toDelete => {
        const selectedItem = rows.find(item => item.id === toDelete[0]);
        console.log('deleting:', toDelete, selectedItem, rows);
        deleteBuilding(selectedItem.id);
    };

    return (
        <Vertical p={2}>
            <Switch>
                <Route exact path={`${path}`}>
                    <Grid
                        title={'Buildings'}
                        columns={columns}
                        rows={rows}
                        initialSortModel={initialSortModel}
                        onAdd={onAdd}
                        onDelete={onDelete}
                        multiple={false}
                        checkBoxSelection={true}
                    />
                </Route>
                <Route path={`${path}/add`}>
                    <AddBuilding onClose={onClose} />
                </Route>
            </Switch>
        </Vertical>
    );
};

Buildings.propTypes = {
    user: PropTypes.object
};

Buildings.defaultProps = {};

export default Buildings;
