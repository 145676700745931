import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, Avatar, Hidden } from '@material-ui/core';

import BuildingMenu from '../building/BuildingMenu';
import OrganizationMenu from '../organization/OrganizationMenu';

import { useAuthenticated, useAdmin } from '@domatic/query';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        display: 'flex',
        zIndex: theme.zIndex.drawer + 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    }
}));

const FoyerBar = () => {
    const classes = useStyles();
    const authenticated = useAuthenticated();
    const isAdmin = useAdmin();

    return (
        <div className={classes.root}>
            <AppBar position={'static'} >
                <Toolbar>
                    <Avatar alt="Domatic" variant="square" src="/Domatic-Logo-Black-Square.png" className={classes.menuButton} />
                    <Hidden xsDown>
                        <Typography variant="h6" className={classes.title}>
                            Domatic Portal {isAdmin ? '(admin)' : ''}
                        </Typography>
                    </Hidden>
                    {authenticated
                        ? (
                            <>
                                <BuildingMenu />
                                <OrganizationMenu />
                            </>
                        ) : (
                            <>
                                <Button variant="text" component={Link} to="/signup">
                                    Sign Up
                                </Button>
                                <Button variant="text" component={Link} to="/login">
                                    Log In
                                </Button>
                            </>
                        )
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default FoyerBar;
