import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const ErrorList = ({ errors }) => (
    <div>
        {errors.filter(error => !!error).map((error, i) => {
            const message = error.response?.data || error.message || 'Unknown Error';
            return (<Typography key={i}>{message}</Typography>);
        })}
    </div>
);

ErrorList.propTypes = {
    errors: PropTypes.array
};

export default ErrorList;

