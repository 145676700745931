import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '../components/Grid';
import { useCurrentBuilding, useBuildingDevices, useBuildingHubs } from '@domatic/query';

import { Box } from '@material-ui/core';
const { DateTime } = require('luxon');

const BuildingDevices = () => {

    const building = useCurrentBuilding();
    const { data: hubs } = useBuildingHubs(building?.id);
    const { data: devices } = useBuildingDevices(building?.id);

    const booleanFormatter = params => params.value ? '✓' : '';
    const dateFormatter = useCallback(params => DateTime.fromMillis(params?.value ?? 0).toRelative(), []);
    const hwVersionFormatter = useCallback(params => {
        const v = params?.value;
        if (v === undefined || v === null || v === 'unknown') {
            return 'unknown';
        }
        return `${v.class ?? 'c'}.${v.product ?? 'p'}.${v.major ?? 'M'}.${v.minor ?? 'm'}.${v.revision ?? 'r'}`;
    }, []);

    const rows = useMemo(() => devices?.map(d => {
        const hub = hubs?.find(h => h.id === d.hubId);
        return { ...d, hub: hub?.name };
    }), [devices, hubs]);

    const [hidden, setHidden] = useState([]);

    const columns = useMemo(() => ([
        { field: 'hub', headerName: 'Hub', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'activeStatus', headerName: 'Status', flex: 1, valueFormatter: booleanFormatter },
        { field: 'ip', headerName: 'IP', flex: 1 },
        { field: 'mac', headerName: 'MAC', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'fwVersion', headerName: 'Firmware', flex: 1 },
        { field: 'hwVersion', headerName: 'Hardware', flex: 1, valueFormatter: hwVersionFormatter },
        { field: 'created', type: 'date', headerName: 'Created', flex: 1, valueFormatter: dateFormatter },
        { field: 'updated', type: 'date', headerName: 'Modified', flex: 1, valueFormatter: dateFormatter }
    ].map((column, i) => ({ ...column, hide: hidden[i] }))), [dateFormatter, hidden, hwVersionFormatter]);

    const initialSortModel = [
        { field: 'hub', sort: 'asc' }
    ];

    const onHideColumn = useCallback((field, val) => {
        setHidden(columns.map((column, index) => {
            if (column.field === field) {
                return val;
            }
            return hidden[index] ?? false;
        }));
    }, [columns, hidden]);

    return (
        <Box p={2} height="100%">
            <Grid
                title={'BuildingDevices'}
                rows={rows}
                columns={columns}
                initialSortModel={initialSortModel}
                hideColumnControl={true}
                onHideColumn={onHideColumn}
            >
            </Grid>
        </Box>
    );
};

BuildingDevices.propTypes = {
    building: PropTypes.object,
    hub: PropTypes.object
};

BuildingDevices.defaultProps = {};

export default BuildingDevices;
