import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';
import Loading from '../components/Loading';

import { Field, ErrorList } from '../components';
import { useUpdateHub } from '@domatic/query';
import { useInput } from '@domatic/hooks';

const Name = ({ hub, onError }) => {

    const { mutate: updateHub, isLoading: saving, error: updateError } = useUpdateHub();

    const name = useInput(hub?.name, '', 'Name', hub.id);

    const changes = name.changed;
    const formValid = changes && name.valid;

    const saveClick = () => {
        updateHub({
            id: hub.id,
            name: name.value
        }, {
            onError,
            onSuccess: doReset
        });
    };

    const doReset = () => {
        name.reset();
    };

    return (
        <Box flexShrink={0}>
            <Loading active={saving} spinner text="Saving...">
                <Box display="flex" flexDirection="row" alignItems="baseline" >
                    <Field field={name} onEnter={saveClick} required fullWidth />
                    <Box flexGrow={0} display="flex" alignItems="center" justifyContent="flex-end">
                        &nbsp;&nbsp;
                        <Button flexGrow={0} onClick={saveClick} color='primary' disabled={!formValid}>Save</Button>
                        &nbsp;&nbsp;
                        <Button flexGrow={0} onClick={doReset} color='secondary' disabled={!changes}>Cancel</Button>
                    </Box>
                </Box>
                <ErrorList errors={[updateError]} />
            </Loading>
        </Box>
    );
};

Name.propTypes = {
    hub: PropTypes.object,
    onError: PropTypes.func
};

export default Name;
