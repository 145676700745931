import { Typography, Box, Button } from '@material-ui/core';
import { useInfo } from '@domatic/query';

const Info = () => {
    const { data: info } = useInfo();

    // const buildBranch = (info && info.build && info.build.branch) || '<loading>';
    // const buildNumber = (info && info.build && info.build.number) || '<loading>';

    const launchInstaller = () => {
        window.open(`${window.location.origin}/installer`, '_blank');
    };

    return (
        <Box p={2} overflow="auto">
            <pre><Typography>{JSON.stringify(info, null, 2)}</Typography></pre>
            <Button onClick={launchInstaller}>Launch installer app</Button>
        </Box>
    );
};

export default Info;
