import Loading from '../components/Loading';
import { Vertical, Body } from '../components/Layout';

import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

import { useBuildingHubs, useCurrentBuilding } from '@domatic/query';

import Hub from './Hub';
import Summary from './Summary';

const name = hub => hub?.name ?? hub?.hostname ?? 'Unknown';

const Hubs = () => {

    const { url } = useRouteMatch();
    const history = useHistory();
    const building = useCurrentBuilding();

    const { data: buildingHubs, isLoading: loading } = useBuildingHubs(building?.id);

    const hubs = buildingHubs?.sort((a, b) => name(a).localeCompare(name(b)) ?? 0);

    return (
        <Vertical p={2}>
            <Body>
                <Loading active={loading} spinner text="Loading...">
                    <Switch>
                        <Route key={-1} exact path={url}>
                            {hubs?.map((hub, i) => (
                                <Summary hub={hub} key={i} path={`${url}/${hub.id}`} onClick={
                                    () => history.replace(`${url}/${hub.id}`)
                                } />
                            ))}
                        </Route>
                        {hubs?.map((hub, i) => (
                            <Route key={i} path={`${url}/${hub?.id}`}>
                                <Hub hub={hub} path={`${url}/${hub?.id}`} onClose={
                                    () => history.replace(url)
                                } />
                            </Route>
                        ))}
                    </Switch>
                </Loading>
            </Body>
        </Vertical>
    );
};

Hubs.defaultProps = {};

export default Hubs;
